import { Injectable, NgZone } from '@angular/core';
import * as auth from 'firebase/auth';
import { User } from '../shared/user';
import { userItem } from '../shared/user-item';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import { BehaviorSubject, from, Observable, Subject,Operator } from 'rxjs';
import { isNull } from 'util';
import { switchMap,map, take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  isAdmin: boolean;
  userData: any;
  userItem:userItem;
  constructor(
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone
  ) {
    this.ngFireAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    });
  }
  // Login in with email/password
  SignIn(email, password) {    
    return this.ngFireAuth.signInWithEmailAndPassword(email, password);
  }
  // Register user with email/password
  RegisterUser(email, password, phone, company, fullName, title) {
   
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password);  
  }

  createRegEntry(email:any,phone:any,company:any,fullName:any, title:any, approved:boolean,approveBy:string,approvedDate:string, uidPassed:string){
    //const uid = 'LfmqW5SSNkUb9K8fxtKY'; // this.userItem[0].uid;
    //const logRef = this.afStore.doc('user-log/');
    this.afStore.collection("registrations").add({
      email: email, fullName:fullName,phone:phone,company:company,title:title,regDate:Date.now(),approved:approved,approvedBy:approveBy,approvedDate:approvedDate,regId:'', uid:uidPassed
  })
  .then(docRef => {
      //console.log("Document written with ID: ", docRef.id);
     // //console.log("You can now also access this. as expected: ", this.foo)     
     this.afStore.collection('registrations').doc(docRef.id).update({
      regId: docRef.id      
    });
    this.afStore.collection("mail").add({
      to: ['jlewis808@gmail.com'],
      message: {
        subject: 'New Registration For Customer Portal.',
        text: 'Name: ' + fullName + '<br>' + "Email: " + email + '<br>' + "Phone: " + phone + '<br>' +  'Company: ' + company  + '<br>' + 'Title: ' + title + '<br>' + 'UID: ' + uidPassed + '<br>' + '<a href="https://portal-5edd4.web.app">https://portal-5edd4.web.app</a>',
        html: 'Name: ' + fullName + '<br>' + "Email: " + email + '<br>' + "Phone: " + phone + '<br>' +  'Company: ' + company  + '<br>' + 'Title: ' + title + '<br>' + 'UID: ' + uidPassed + '<br>' + '<a href="https://portal-5edd4.web.app">https://portal-5edd4.web.app</a>'
      }
    });
  })
  .catch(error => console.error("Error adding document: ", error))

    //this.userId  = this.userItem[0].uid;
    // set() for destructive updates
    //logRef.set({ logtype: logtype,message:message,datetime:Date.now(),uid:uid});

  }

  // Email verification when new user register
  // SendVerificationMail() {
  //   return this.ngFireAuth.currentUser.sendEmailVerification()
  //   .then(() => {
  //     this.router.navigate(['verify-email']);
  //   })
  // }

  // CreateUserAndSendVerificationMailAdminScreen(email:string,password:string){

  // }

  CreateUserAndSendVerificationMailAdminScreen(email:string,password:string) {   
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password).then((user) => {
      return user.user.sendEmailVerification().then(() => {
        //this.router.navigate(['verify-email']);
        return user;
      });
    });
    //this.ngFireAuth.createUserWithEmailAndPassword(email, password).then((cred) => return cred.sendEmailVerification(cred.user))
    //  this.firebaseUser.email.match(email);
    
  //   return this.firebaseUser.sendEmailVerification().then(() => {
  //       //this.router.navigate(['verify-email']);
  //     });
  
  }

  SendVerificationMail() {
    return this.ngFireAuth.currentUser.then((user) => {
      return user.sendEmailVerification().then(() => {
        this.router.navigate(['verify-email']);
        window.alert('Verification Link Sent!');
      });
    });
  }

  // SendVerificationMailUser(useremail) {
  //   this.ngFireAuth.cre
    
  //   return this.ngFireAuth.currentUser.then((user) => {
  //     return user.sendEmailVerification().then(() => {
  //       this.router.navigate(['verify-email']);
  //     });
  //   });
  // }

  // Recover password
  PasswordRecover(passwordResetEmail) {
    return this.ngFireAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert(
          'Password reset email has been sent, please check your inbox.'
        );
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Returns true when user is logged in
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    if(user !== null && user.emailVerified !== false){
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
    
    return user !== null && user.emailVerified !== false ? true : false;
  }

  // Returns true when user is isAdmin
  get isAdminUser(): boolean {
    const user = JSON.parse(localStorage.getItem('userItem'));
    if (user === null) {return false;}
    if (user.length <= 0) {
      return false;
    } else {
      return ((/true/i).test(user[0].isAdmin));   
    }
     
  }
  
  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.emailVerified !== false ? true : false;
  }

  getPageAccessControl(pageId:any): any {
    var AccessData:any;
    const user = JSON.parse(localStorage.getItem('userItem'));
    // get user access levels        
           this.afStore
      .collection('roles-access', ref => ref
      .where('pageId', '==', pageId)
      //.where('friendlyName', '==', friendlyName)
      .where('roleName', '==', user[0].role)
      )
      .valueChanges()
      //.pipe(take(1))
      .subscribe(accessData => {
        AccessData = accessData;
        return AccessData;
      }
      )      
    }

  //   public async getCommerces(pageId:any) {
  //     const user = JSON.parse(localStorage.getItem('userItem'));
  //     let data: any[] = [];
  //     try {
  //         const colRef = this.afStore
  //         .collection('roles-access', ref => ref
  //         .where('pageId', '==', pageId)
  //         //.where('friendlyName', '==', friendlyName)
  //         .where('roleName', '==', user[0].role)
  //         )
  //         .valueChanges()   
  //         const docs = await this.mainFirestore  .firestore. .getDocs(colRef);
  //         if (docs.size <= 0) throw 'size-error';
  //         docs.forEach(item => commerces.push(item.data() as Commerce));
  //         return commerces;
  //     } catch (error) {
  //         throw error;
  //     }
  // }

  getPageRoleData(pageId:any): Promise<any> {
const user = JSON.parse(localStorage.getItem('userItem'));
return new Promise((resolve, reject) => {
const junctionRef = this.afStore
.collection('roles-access', ref => ref
.where('pageId', '==', pageId)
//.where('friendlyName', '==', friendlyName)
.where('roleName', '==', user[0].role)
)

junctionRef.get().toPromise().then((doc) => {
      const details = doc;
      resolve(details);
    });
  });
}

getItemRoleAccess(pageId:any,friendlyName:any): Promise<any> {
  const user = JSON.parse(localStorage.getItem('userItem'));
  return new Promise((resolve, reject) => {
  const junctionRef = this.afStore
  .collection('roles-access', ref => ref
  .where('friendlyName', '==', friendlyName)
  .where('pageId', '==', pageId)
  .where('roleName', '==', user[0].role)
  )  
  //var acId = 
 // this.accessControl = await this.auth.getPageRoleData('QcTyQQBHWY9OBTi3udpa');
  junctionRef.get().toPromise().then((doc) => {
       
    const details = doc;
    doc.forEach(acDoc =>  {  
      var accControlObj =acDoc['id'];
      var acRef = this.afStore.collection('roles-access').doc(accControlObj).valueChanges().subscribe(roleData => 
        {      
       // this.roleData.push([roleData['friendlyName'], roleData['hasAccess']]);
        resolve([roleData['friendlyName'], roleData['hasAccess']] )
      }
        );
     ;
    }
    
    )

       
        
        
       
      });
    });
  }


  addPortalMessage(to:any,from:any,message:any,uid:any){
    this.afStore.collection("messages").add({
      to: to,from:from, message:message,date:Date.now(),uid:uid,read:false
  })
  .then(docRef => {
    this.afStore.collection('messages').doc(docRef.id).update({
      id: docRef.id      
    });
      //console.log("Document written with ID: ", docRef.id);
     // //console.log("You can now also access this. as expected: ", this.foo)
  })
  .catch(error => console.error("Error adding document: ", error))
  }


  logUserEvent(logtype:any,message:any,uid:any){
    //const uid = 'LfmqW5SSNkUb9K8fxtKY'; // this.userItem[0].uid;
    //const logRef = this.afStore.doc('user-log/');
   
    this.afStore.collection("user-log").add({
      logtype: logtype,message:message,datetime:Date.now(),uid:uid
  })
  .then(docRef => {
      //console.log("Document written with ID: ", docRef.id);
     // //console.log("You can now also access this. as expected: ", this.foo)
  })
  .catch(error => console.error("Error adding document: ", error))

    //this.userId  = this.userItem[0].uid;
      //const uid = 'LfmqW5SSNkUb9K8fxtKY'; // this.userItem[0].uid;
    //const logRef = this.afStore.doc('user-log/');
    // set() for destructive updates
    //logRef.set({ logtype: logtype,message:message,datetime:Date.now(),uid:uid});

  }

  logDownloadEvent(logtype:any,message:any,uid:any,userEmail:any,fileName:any,fileId?:any, version?:any){
    this.afStore.collection("user-log").add({
      logtype: logtype,message:message,datetime:Date.now(),uid:uid,userEmail:userEmail,fileName:fileName,fileId:fileId,version:version
  })
  .then(docRef => {
      //console.log("Document written with ID: ", docRef.id);
     // //console.log("You can now also access this. as expected: ", this.foo)
  })
  .catch(error => console.error("Error adding document: ", error))
  }

  logPublicDownloadEvent(logtype:any,message:any,uid:any,userEmail:any,fileName:any,fileId?:any, version?:any, ipAddress?:any){
    this.afStore.collection("public-downloads-log").add({
      logtype: logtype,message:message,datetime:Date.now(),uid:uid,userEmail:userEmail,fileName:fileName,fileId:fileId,version:version,ipAddress:ipAddress
  })
  .then(docRef => {
      //console.log("Document written with ID: ", docRef.id);
     // //console.log("You can now also access this. as expected: ", this.foo)
  })
  .catch(error => console.error("Error adding document: ", error))
  }

  logEULAEvent(logtype:any,message:any,uid:any,userEmail:any,fileName:any,fileId?:any, version?:any){
    this.afStore.collection("user-log").add({
      logtype: logtype,message:message,datetime:Date.now(),uid:uid,userEmail:userEmail,fileName:fileName,fileId:fileId,version:version
  })
  .then(docRef => {
      //console.log("Document written with ID: ", docRef.id);
     // //console.log("You can now also access this. as expected: ", this.foo)
  })
  .catch(error => console.error("Error adding document: ", error))
  }
  // Sign in with Gmail
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }
  // Auth providers
  AuthLogin(provider) {
    return this.ngFireAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Store user in localStorage
  SetUserData(user) {       
      this.afStore
      .collection('users', ref => ref
      .where('uid', '==', user))
      .valueChanges()
      //.pipe(take(1))
      .subscribe(userItem => {
        if (userItem.length<=0) {

        } else {
          localStorage.setItem('userItem', JSON.stringify(userItem));
          this.afStore
          .collection('companies', ref => ref
          .where('id', '==', userItem['companyId'])
          )
          .valueChanges()
          //.pipe(take(1))
          .subscribe(companyData => {
           var isActive = companyData['isActive'];
            if (isActive == true){                      
              localStorage.setItem('compActive', String(true)); 
            }
             else {
              localStorage.setItem('compActive', String(false)); 
            }
          })          
        }
      });

  }

  get companyActive(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    if(user !== null && user.emailVerified !== false){
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
    
    return user !== null && user.emailVerified !== false ? true : false;
  }

  

  SetUserDataFromLoginPage(user)  {       
    var currUseritem = [];
    this.afStore
    .collection('users', ref => ref
    .where('uid', '==', user))
    .valueChanges()
    .pipe(take(1))
    .subscribe(userItem => {
      currUseritem = userItem;
      if (userItem.length<=0) {
        this.router.navigate(['accounthold']); 
      } else {
        localStorage.setItem('userItem', JSON.stringify(userItem));
        this.logUserEvent('login',userItem[0]['email'] + ' logged in: ' + Date.now(),userItem[0]['uid']);
       
        setTimeout(() => {
          //this.router.navigateByUrl('/dashboard', { replaceUrl:true });       
          this.router.navigateByUrl('/dashboard', { replaceUrl:true });  
}, 500); 
      //     this.afStore
      //     .collection('companies', ref => ref
      //     .where('id', '==', userItem['companyId'])
      //     )
      //     .valueChanges()
      //     //.pipe(take(1))
      //     .subscribe(companyData => {
      //      var isActive = companyData['isActive'];
      //       if (isActive == true){                      
               
      //       }
      //        else {
      //         localStorage.setItem('compActive', String(false)); 
      //         //localStorage.setItem('userItem', JSON.stringify(userItem));
      //         this.logUserEvent('loginfailed',userItem[0]['email'] + ' failed logged, company inactive in: ' + Date.now(),userItem[0]['uid']);
      //         setTimeout(() => {
      //           //this.router.navigateByUrl('/dashboard', { replaceUrl:true });       
      //           this.router.navigateByUrl('/logout', { replaceUrl:true });  
      // }, 500);  
      //       }
      // })
        //this.router.navigate(['dashboard']); 
       
       
       
      }
      //localStorage.setItem('userItem', JSON.stringify(userItem));
      
      //localStorage.setItem('user', JSON.stringify(this.userData));
      //console.log(userItem);
    });
    if (currUseritem.length<=0) {
      
    }
   // //console.log(this.afs.collection.name);    
}
  // SetUserData(user) {
  //   const userRef: AngularFirestoreDocument<any> = this.afStore.doc(
  //     `users/${user.uid}`
  //   );
  //   const userItemData: userItem = {
  //     uid: user.uid,
  //     email: user.email,
  //     displayName: user.displayName,
  //     photoURL: user.photoURL,
  //     emailVerified: user.emailVerified,
  //     companyId:user.companyId,
  //     isAdmin: user.isAdmin
  //   };
  //   localStorage.setItem('userItem', JSON.stringify(userItemData));
  //       JSON.parse(localStorage.getItem('user'));
  //   return userRef.valueChanges(userItemData);
  // }
  // Sign-out
  SignOut() {
    var userItem: any;
    
    userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
    if (userItem == "undefined" || userItem == null) {

    } else {this.logUserEvent('logout',userItem[0].email + ' user logout: ' + Date.now(),userItem[0].uid);}
    
    return this.ngFireAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('userItem');
      localStorage.removeItem('currentAppVersion');
      localStorage.removeItem('isDarkMode');
      setTimeout(() => {
        //this.router.navigate(['login']);
        this.router.navigateByUrl('/logout', { replaceUrl:true });
        setTimeout(() => {          
          window.location.reload();
      }, 500);
    }, 500);
      
    });
  }
}
