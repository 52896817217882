import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { IonTabs,IonSegment, IonTabButton, ToastController, IonInput,ModalController  } from '@ionic/angular'
import { SettingsService } from 'src/app/services/settings.service';
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserFiles } from '../../interfaces/user-files';
import { Observable, Subscription } from 'rxjs';
import { switchMap,map, take } from 'rxjs/operators';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.page.html',
  styleUrls: ['./order-detail.page.scss'],
})
export class OrderDetailPage implements OnInit {
  public selectedOrder:any;
  displayedOrderColumns = ['orderDate', 'productClass', 'trackingNo', 'itemNo','customerPO','orderStatus','shipmentDate'];
  dataSourceOrderDetail: MatTableDataSource<any>;
  dsOrderDetail:any;
  selectedTrackingNo:any;
  currentShippingStatus:any;
    constructor(private afs: AngularFirestore, private afStorage: AngularFireStorage,
      public modalCtrl: ModalController,
      //public routerOutlet: IonRouterOutlet,
      private _liveAnnouncer: LiveAnnouncer,
      private storage: AngularFireStorage,
      public auth: AuthenticationService) { }
  
    ngOnInit() {
      this.currentShippingStatus = "In Transit";
      this.getOrderDetail();
    }
  
    getOrderDetail(): void {
      this.afs.collection('orders', ref => ref.where('so', '==', 
     this.selectedOrder).orderBy('itemNo','asc')).valueChanges()
     //.pipe(take(1))
     .subscribe(orderDetail => {   
      this.dsOrderDetail = orderDetail; 
       this.dataSourceOrderDetail = new MatTableDataSource<any>(orderDetail);
          // this.dataSourceOrders.sort = this.applyFilter('');
        });
    }

    getShippingInfo(trackingNo:any,el:HTMLElement){
      this.selectedTrackingNo = trackingNo;
      
      this.scroll(el);
      //dsOrderDetail
    }
    

    scroll(el: HTMLElement) {
      const shipEl = document.getElementById("shippingInfo");
      setTimeout(() => {
        shipEl.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, 100);
      
  }
  
  applyFilterOrders(filterValue: any) {
    filterValue = filterValue.value.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSourceOrderDetail.filter = filterValue;
  }
  
  
     /** Announce the change in sort state for assistive technology. */
     announceSortChange(sortState: Sort) {
      if (sortState.direction) {
        this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
      } else {
        this._liveAnnouncer.announce('Sorting cleared');
      }
    }
    
    dismiss(data?: any) {
      // using the injected ModalController this page
      // can "dismiss" itself and pass back data
      this.modalCtrl.dismiss(data);  
    }
  }