import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import {IonRouterOutlet} from '@ionic/angular'
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StatusBar } from '@capacitor/status-bar';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Firebase services + environment module
import { AngularFireModule } from '@angular/fire/compat';

import { FunctionsModule } from '@angular/fire/functions';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

// Angular Material
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { GoogleMapsModule } from '@angular/google-maps';
import { LightboxModule } from 'ngx-lightbox';

import { IonicSelectableModule } from 'ionic-selectable';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { NgChartsModule } from 'ng2-charts';
import { CKEditorModule } from 'ng2-ckeditor';
import { DownloadsPageModule } from './pages/downloads/downloads.module';
import { FileSizePipe } from './filesize.pipe';
import { ManageDownloadsPageModule } from './pages/manage-downloads/manage-downloads.module';
import { SharedModule } from './shared/shared.module';
//import { DownloadsPage } from './pages/downloads/downloads.page';
//Import HttpClientModule from @angular/common/http
import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';
import { SAVER, getSaver } from '../app/shared/saver.provider'

import {MatCardModule} from '@angular/material/card'; 
import {DragDropModule} from '@angular/cdk/drag-drop';

import {MatProgressBarModule} from '@angular/material/progress-bar'; 
import { ServiceWorkerModule } from '@angular/service-worker'; 
import { ManageAppPage } from './pages/manage-app/manage-app.page';
import { ManageAppPageModule } from './pages/manage-app/manage-app.module';
import { ManageCompaniesDetailPage } from './pages/manage-companies-detail/manage-companies-detail.page';
import { ManageCompaniesDetailPageModule } from './pages/manage-companies-detail/manage-companies-detail.module';
import { ManageDownloadsDetailPage } from './pages/manage-downloads-detail/manage-downloads-detail.page';
import { ManageDownloadsDetailPageModule } from './pages/manage-downloads-detail/manage-downloads-detail.module';
import { ManageFilePage } from './pages/manage-file/manage-file.page';
import { ManageFilePageModule } from './pages/manage-file/manage-file.module';
import { ManageMessagesPage } from './pages/manage-messages/manage-messages.page';
import { ManageMessagesPageModule } from './pages/manage-messages/manage-messages.module';
import { ManageProductTagPage } from './pages/manage-product-tag/manage-product-tag.page';
import { ManageProductTagPageModule } from './pages/manage-product-tag/manage-product-tag.module';
import { ManageProductsDetailPage } from './pages/manage-products-detail/manage-products-detail.page';
import { ManageProductsDetailPageModule } from './pages/manage-products-detail/manage-products-detail.module';
import { ManageRegDetailPage } from './pages/manage-reg-detail/manage-reg-detail.page';
import { ManageRegDetailPageModule } from './pages/manage-reg-detail/manage-reg-detail.module';
import { ManageUsersDetailPage } from './pages/manage-users-detail/manage-users-detail.page';
import { ManageUsersDetailPageModule } from './pages/manage-users-detail/manage-users-detail.module';
import { AdminOrderDetailPage } from './pages/admin-order-detail/admin-order-detail.page';
import { AdminOrderDetailPageModule } from './pages/admin-order-detail/admin-order-detail.module';
import { ChecklistViewPage } from './pages/checklist-view/checklist-view.page';
import { ChecklistViewPageModule } from './pages/checklist-view/checklist-view.module';
import { OrderDetailPage } from './pages/order-detail/order-detail.page';
import { OrderDetailPageModule } from './pages/order-detail/order-detail.module';
import { ChecklistManagerDetailPage } from './pages/checklist-manager-detail/checklist-manager-detail.page';
import { ChecklistManagerDetailPageModule } from './pages/checklist-manager-detail/checklist-manager-detail.module';
import { ChecklistManagerImghandlerPage } from './pages/checklist-manager-imghandler/checklist-manager-imghandler.page';
import { ChecklistManagerImghandlerPageModule } from './pages/checklist-manager-imghandler/checklist-manager-imghandler.module';
import { UserImgHandlerPage } from './pages/user-img-handler/user-img-handler.page';
import { UserImgHandlerPageModule } from './pages/user-img-handler/user-img-handler.module';
import { CompanyImgHandlerPage } from './pages/company-img-handler/company-img-handler.page';
import { CompanyImgHandlerPageModule } from './pages/company-img-handler/company-img-handler.module';
import { UserInfoControlPage } from './pages/user-info-control/user-info-control.page';
import { UserInfoControlPageModule } from './pages/user-info-control/user-info-control.module';
import { LogoutPage } from './pages/logout/logout.page';
import { LogoutPageModule } from './pages/logout/logout.module';
import { LoginPage } from './pages/login/login.page';
import { LoginPageModule } from './pages/login/login.module';
import { AdminFileManagerPage } from './pages/admin-file-manager/admin-file-manager.page';
import { AdminFileManagerPageModule } from './pages/admin-file-manager/admin-file-manager.module';
import { OrderTrackerPage } from './pages/order-tracker/order-tracker.page';
import { OrderTrackerPageModule } from './pages/order-tracker/order-tracker.module';
import { AccountholdPage } from './pages/accounthold/accounthold.page';
import { AccountholdPageModule } from './pages/accounthold/accounthold.module';
import { FieldServiceRequestPage } from './pages/field-service-request/field-service-request.page';
import { FieldServiceRequestPageModule } from './pages/field-service-request/field-service-request.module';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { DashboardPageModule } from './pages/dashboard/dashboard.module';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
// import { QuillModule } from 'ngx-quill';
@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,FormsModule,CommonModule,SharedModule,
    //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    LogoutPageModule,
    LoginPageModule,
    AccountholdPageModule,
    OrderTrackerPageModule,
    FieldServiceRequestPageModule,
    DashboardPageModule,
    ManageCompaniesDetailPageModule,
    ManageDownloadsDetailPageModule,
    FunctionsModule,
    ManageFilePageModule,
    ManageMessagesPageModule,
    ManageProductTagPageModule,
    ManageProductsDetailPageModule,
    ManageRegDetailPageModule,
    ManageUsersDetailPageModule,
    AdminOrderDetailPageModule,
    ChecklistViewPageModule,
    ChecklistManagerDetailPageModule,
    ChecklistManagerImghandlerPageModule,
    UserImgHandlerPageModule,
    CompanyImgHandlerPageModule,
    UserInfoControlPageModule,
    OrderDetailPageModule,
    ReactiveFormsModule, 
    BrowserModule,
    GuidedTourModule,
    DownloadsPageModule,
    ManageDownloadsPageModule,
    GoogleMapsModule,
    LightboxModule,
    IonicSelectableModule,
    HttpClientModule,
    AngularEditorModule,
    CKEditorModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatTabsModule,
    DragDropModule,
    MatButtonToggleModule,  
    MatFormFieldModule,
    MatIconModule,
    MatRadioModule,
    MatToolbarModule,
    MatDialogModule,
    MatDatepickerModule,  
    MatCardModule,
    BrowserAnimationsModule,
    ManageAppPageModule,
    IonicModule.forRoot(), AppRoutingModule,IonicStorageModule.forRoot(),
    // QuillModule.forRoot({
    //   modules: {
    //     syntax: true
    //   }
    // }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    //AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    //AngularFirestoreModule.enablePersistence(),

    NgChartsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },  
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
     {
    provide: IonRouterOutlet,
    useValue: {
      //add whatever property of IonRouterOutlet you're using in component class
      nativeEl: ""
    }
  }, {provide: SAVER, useFactory: getSaver}, GuidedTourService],
  bootstrap: [AppComponent],
  entryComponents: [LogoutPage,LoginPage,AccountholdPage,DashboardPage,ManageCompaniesDetailPage,ManageDownloadsDetailPage,ManageFilePage,ManageMessagesPage,ManageProductTagPage,ManageProductsDetailPage,ManageRegDetailPage,ManageUsersDetailPage,AdminOrderDetailPage,ChecklistViewPage,ChecklistManagerDetailPage,ChecklistManagerImghandlerPage,UserImgHandlerPage,CompanyImgHandlerPage,UserInfoControlPage,ManageAppPage,OrderTrackerPage,FieldServiceRequestPage, OrderDetailPage]
})
export class AppModule {}
