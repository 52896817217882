import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { IonTabs,IonSegment, IonTabButton, ToastController, IonInput,ModalController, IonRouterOutlet, AlertController } from '@ionic/angular'
import { SettingsService } from 'src/app/services/settings.service';
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserFiles } from '../../interfaces/user-files';
import { PortalFiles } from 'src/app/interfaces/portal-files';
import { Observable, Subscription } from 'rxjs';
import { compileFunction } from 'vm';
import { finalize } from 'rxjs/operators';
import { AngularEditorConfig, UploadResponse, AngularEditorService } from '@kolkov/angular-editor';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest,HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CKEditorComponent } from 'ng2-ckeditor';
import { switchMap,map, take } from 'rxjs/operators';
import { generateSignedUrl } from 'functions/src'
import { getFunctions,Functions, httpsCallable } from "@angular/fire/functions";

export class FileUpload {
  key: string;
  name: string;
  url: string;
  file: File;
  constructor(file: File) {
    this.file = file;
  }
}

@Component({
  selector: 'app-manage-products-detail',
  templateUrl: './manage-products-detail.page.html',
  styleUrls: ['./manage-products-detail.page.scss'],
 

})
export class ManageProductsDetailPage implements OnInit {
public fileId: any;
public companyId: any;
  public uid: any;
  userName: any; 
  userItem: any;
  userId: any; 
  userEmpId: any;
  userCompanyId:any;
  userEmail:any;
  userPhotoUrl:any;
  userdisplayName:any;
  userCompanyName:any;
  userActive:any;
  companyLogoURL:any;
  companyName:any;
  companySysproId:any;
  companySalesRep:any;
  companyServiceRep:any;
  companyIsActive:any;
  dateCreated:any;
  appPermissions:any
  availableTags:Array<any> = [];
  selectedTags:Array<any> = [];
  tagList:any;
  eulaRequired:boolean = true;
  fileIsActive:boolean = true;
  fileVersion:any;
  dataSourceUsersCombo: MatTableDataSource<any>;
  dataSourceUsers: MatTableDataSource<any>;
  dataSourceCompanies: MatTableDataSource<any>;
  dataSourceUserLog: MatTableDataSource<any>;
  dataSourceFiles: MatTableDataSource<UserFiles>;
  dataSourcePortalFiles: MatTableDataSource<PortalFiles>;
  dataSourcePortalFileInfo:any;
  dataSourceDownloadLog: MatTableDataSource<any>;
  dataSourceTags: MatTableDataSource<any>;
  @ViewChild('companySelect', {static: false}) inputEl: IonInput;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('filepaginator') filepaginator: MatPaginator;
  @ViewChild('tagspaginator') tagspaginator: MatPaginator;
  @ViewChild('downloadspaginator') downloadspaginator: MatPaginator;
  @ViewChild('sort') sortMain: MatSort;
  @ViewChild('matSortFiles') matSortFiles: MatSort;
  @ViewChild('matSortTags') matSortTags: MatSort;
  @ViewChild('matSortDLLog') matSortDLLog: MatSort;
  companyList:any;

  name = 'ng2-ckeditor';
  ckeConfig: CKEDITOR.config;
  mycontent: string;
  log: string = '';
  @ViewChild("myckeditor") ckeditor: CKEditorComponent;

  
  roleName:any;
  roleList:any;
  displayedFileColumns = ['fileDate', 'fileName', 'version', 'eulaRequired', 'isActive',  'fileTags', 'fileNotes', 'fileUser', 'fileUrl'];
  displayedUserLogColumns = ['datetime', 'logtype', 'message'];
  displayedTagColumns = ['id', 'tagName', 'isActive', 'dateCreated', 'createdBy'];
  displayedDownloadColumns = ['datetime','userEmail','version','message','logtype'];
  productFileName:any;
  productFileDisplayName:any;
  productFileUrl:any;
  productFileType:any;
  productFileNotes:any;
  productTags:any;
  productFileVersion:any;
  productFileActive:any;
  productFileEULAReq:any;

  percentage = 0;
  currentFileUpload?: FileUpload;
  selectedFiles?: FileList;
  sendEmailWithFile:boolean = true;  
  companyInfo:any;
  newTagName:any = null;
  newTagActive:boolean = true;
  private myToast: any;
  uploadUrlInfo:any = null;
  emailOnUpdate:boolean = false;
  currUploadPercent:any = 0;
  //self = this;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '200px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: this.uploadUrlInfo,
    //upload: (file: File) => { return this.upload(file); },
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
};
baseURL: string = "https://api.github.com/";

	
public isAdmin:boolean = false;
public Orders:boolean = false;
public Products:boolean = false;
public Companies:boolean = false;
public Users:boolean = false;
public Messages:boolean = false;
public Downloads:boolean = false;
public Logs:boolean = false;
public Checklists:boolean = false;
public Roles:boolean = false;
public System:boolean = false;
public Application:boolean = false;
public Main:boolean = false;
public DeleteProduct:boolean = false;

pageId:any = 'VlyIdAVeSOMbBUerhZkL';
roleControls:any[] = ['Main','DeleteProduct'] 

  constructor(private afs: AngularFirestore, private afStorage: AngularFireStorage,
    public modalCtrl: ModalController,  
    private _liveAnnouncer: LiveAnnouncer,
    public alertCtrl: AlertController,
    private routerOutlet: IonRouterOutlet,
    public toastCtrl: ToastController,
    private http: HttpClient,
    private functions: Functions,   
   // private angularEditorSvc: AngularEditorService,
    //@Optional() private readonly routerOutlet?: IonRouterOutlet,
    private storage: AngularFireStorage,
    public afStore: AngularFirestore,
    public auth: AuthenticationService) { }

    //Observable<HttpEvent<UploadResponse>>

 async  portalFileNoteToStorage(fileUpload: File): Promise<Observable<HttpEvent<UploadResponse>>> {
      var downloadURL;
      var basePath = '/portalFilesNotes/' + this.fileId;
      const filePath = `${basePath}/${fileUpload.name}`;
      const storageRef = this.storage.ref(filePath);
      const uploadTask = this.storage.upload(filePath, fileUpload);
   await  uploadTask.snapshotChanges().pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe(downloadURL => {
            downloadURL= 'HTTP/1.1  201 Location: /v1/items/12 Content-Type: application/json{ "imageUrl": ' + downloadURL + '}'
            //downloadURL = {"imageUrl" : downloadURL}
            this.uploadUrlInfo = downloadURL;
            // fileUpload.url = downloadURL;
            // fileUpload.name = fileUpload.file.name;          
          });
        })
      );
      return downloadURL
     
    }

    getUrl(info: any) {
      const url = `${environment.baseUrl}/${info}`;
      // const url = `https://localhost:44302/api/PITIssue/${issueId}`;
      //return this.http.get(url);
      return this.http.get(this.baseURL + 'users/' + 'jlewiscp' + '/repos')
    }
  

       
    fileUploader(file:File): Observable<HttpEvent<UploadResponse>> {
      var basePath = '/portalFilesNotes/' + this.fileId;
      const filePath = `${basePath}/${file.name}`;
      const storageRef = this.storage.ref(filePath);
      // const uploadTask = this.storage.upload(filePath, fileUpload);
      // uploadTask.snapshotChanges().pipe(
      //   finalize(() => {
      //     storageRef.getDownloadURL().subscribe(downloadURL => {
      //       this.uploadUrlInfo = downloadURL;        
            
      //     });
      //   })
      // ).subscribe();
      // return uploadTask.percentageChanges();
      var fileInfo 
      storageRef.put(file).then(function(snapshot) {
        fileInfo = snapshot.metadata;
          //console.log('Uploaded the file!');
      });
      return fileInfo;
  }
     upload(file:File): Observable<number> {//Observable<HttpEvent<UploadResponse>> {
      var downloadURL;    
          
        if (file) {
         // this.currentFileUpload = new FileUpload(file);
         this.pushFileToStorage(file).subscribe(
            percentage => {
              this.currUploadPercent   = Math.round(percentage ? percentage : 0);
            },
            error => {
              //console.log(error);
            }
          );
        }     
      if (this.currUploadPercent = 100) {
        downloadURL = JSON.stringify('HttpHeaders{HTTP/1.1  201 Location: /v1/items/12, Content-Type: application/json}, { "imageUrl": ' +  this.uploadUrlInfo + '}');
      }
      //this.angularEditorSvc.insertImage(this.uploadUrlInfo);
      
      return  this.currUploadPercent
    }

    
  // getData(){

  //   this.http.get(url)
  //  .map((res:Response) => (
  //      res.json() //Convert response to JSON
  //      //OR
  //      res.text() //Convert response to a string
  //  ))
  //  .subscribe(data => {//console.log(data)})

  // }

    pushFileToStorage(fileUpload: File): Observable<number> {
      var basePath = '/portalFilesNotes/' + this.fileId;
      const filePath = `${basePath}/${fileUpload.name}`;
      const storageRef = this.storage.ref(filePath);
      const uploadTask = this.storage.upload(filePath, fileUpload);
      uploadTask.snapshotChanges().pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe(downloadURL => {
            //this.uploadUrlInfo = downloadURL;        
            this.productFileNotes = this.productFileNotes + "<img class='htmlEditorImg'  src='" +  downloadURL + "'>"
          });
        })
      ).subscribe();
      return uploadTask.percentageChanges();
    }

    // async uploadFileNote(fileUpload: File): Observable<HttpEvent<UploadResponse>> {
    //   const response =  await this.pushFileToStorage(fileUpload);

    //   if (response === 100) { 
    //       alert('User is successfully logged in!'); 
    //       await saveUserData(response.data); 
    //       this.router.navigate(['/']); 
    //   } 
    //   else { 
    //       alert(response.message); 
    //       this.router.navigate(['/login']); 
    //   } 
     

    // }

    
// portalFileNoteToStorage(fileUpload: File): Observable<HttpEvent<UploadResponse>> {
//   //portalFilesNotes
//   var downloadURL;
//   var uploadProgress = new Observable<HttpEvent<UploadResponse>>;
//   var uploadProgEvent = HttpEventType.UploadProgress;
//   var basePath = '/portalFilesNotes/' + this.fileId;
//   const filePath = `${basePath}/${fileUpload.name}`;
//   const storageRef = this.storage.ref(filePath);
//   const uploadTask = this.storage.upload(filePath, fileUpload);
  
// //uploadTask.then(uploadProgEvent );
//   uploadTask.snapshotChanges().pipe(
//     finalize(() => {
//       storageRef.getDownloadURL().subscribe(downloadURL => {
//         downloadURL = {"imageUrl" : downloadURL} 
        
//         uploadProgress = downloadURL;
//         // fileUpload.url = downloadURL;
//         // fileUpload.name = fileUpload.file.name;
//         // this.saveFileData(fileUpload);
//         // this.auth.logUserEvent('manageproducts-portalfileupload', fileUpload.file.name + ' uploaded by ' + this.userItem[0].email + '.',this.userItem[0].uid);
//       });
//     })
//   ).subscribe();
//   //return downloadURL; event.type == HttpEventType.UploadProgress
//   return uploadProgress;
// }

  ngOnInit() {
    this.userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      removePlugins: 'exportpdf'
    };

    this.roleControls.forEach(role =>{
      var hasAccess =  this.getItemAccess(role);   
    }) 
    setTimeout(() => {
      this.getProductTags();
      this.getProductInfo();
      this.getDownloadData();
  }, 1500);

  
  }

  async getItemAccess(friendlyName:any):Promise<boolean>{
    var access:boolean;    
    var itemAccess = await this.auth.getItemRoleAccess(this.pageId,friendlyName);
    access = itemAccess[1];
    if (friendlyName == 'Main' && access == true) {this.Main = true};
    if (friendlyName == 'Orders' && access == true) {this.Orders = true};
    if (friendlyName == 'Products' && access == true) {this.Products = true};
    if (friendlyName == 'Companies' && access == true) {this.Companies = true};
    if (friendlyName == 'Users' && access == true) {this.Users = true};
    if (friendlyName == 'Messages' && access == true) {this.Messages = true};
    if (friendlyName == 'Downloads' && access == true) {this.Downloads = true};
    if (friendlyName == 'Logs' && access == true) {this.Logs = true};
    if (friendlyName == 'Checklists' && access == true) {this.Checklists = true};
    if (friendlyName == 'Roles' && access == true) {this.Roles = true};
    if (friendlyName == 'System' && access == true) {this.System = true};
    if (friendlyName == 'Application' && access == true) {this.Application = true};
    if (friendlyName == 'DeleteProduct' && access == true) {this.DeleteProduct = true};
    return access
    }
	



   onChange($event: any): void {
    //console.log("onChange");
    //this.log += new Date() + "<br />";
  }

  onPaste($event: any): void {
    //console.log("onPaste");
    //this.log += new Date() + "<br />";
  }
  private getProductTags(): void {
       var tagItem;
    this.afs
    .collection('portalFileTags', ref => ref
    )
    .valueChanges()
    //.pipe(take(1))
    .subscribe(fileTags => {   
      this.tagList = fileTags;
      this.tagList.forEach(tag => this.availableTags.push({'id': tag.id, 'tagName': tag.tagName}) ) ;{        
       
      };
      this.dataSourceTags = new MatTableDataSource<any>(fileTags);
      this.dataSourceTags.paginator = this.paginator;
      this.dataSourceTags.sort = this.matSortTags;
    });
   // //console.log(this.afs.collection.name);
  }

  private getProductInfo(): void {  
   
 this.afs
 .collection('portalFiles', ref => ref
 .where('fileId', '==', this.fileId))
 .valueChanges()
 .pipe(take(1))
 .subscribe(portalFileData => {
this.dataSourcePortalFileInfo = portalFileData;
this.selectedTags = [];
portalFileData[0]['fileTags'].forEach(tag => this.selectedTags.push({'id': tag.id, 'tagName': tag.tagName}) ) ;{        
       
};
this.productFileName = portalFileData[0]['fileName'];
this.productFileDisplayName = portalFileData[0]['fileDisplayName'];
this.productFileUrl = portalFileData[0]['fileDownloadUrl'];
this.productFileType = portalFileData[0]['fileType'];
this.productFileNotes = portalFileData[0]['fileNotes'];
this.productFileVersion = portalFileData[0]['version'];
this.productFileEULAReq = portalFileData[0]['eulaRequired'];
this.productFileActive = portalFileData[0]['isActive'];
this.mycontent = this.productFileNotes;
//this.selectedTags = portalFileData.fileTags[];
  //this.dataSourcePortalFiles= new MatTableDataSource<any>(portalFileData); 
 });
// //console.log(this.afs.collection.name);
}

getDownloadData(){
  this.afs
  .collection('user-log', ref => ref
  .where('fileId', '==', this.fileId)
  )
  .valueChanges()
  //.pipe(take(1))
  .subscribe(downloadData => { 
    this.dataSourceDownloadLog= new MatTableDataSource<any>(downloadData);
    this.dataSourceDownloadLog.paginator = this.downloadspaginator;
    this.dataSourceDownloadLog.sort = this.matSortDLLog;
  });
  
}


updateCurrentVersion(){
  this.afStore.collection('portalFiles').doc(this.fileId).update({
    fileDate:Date.now(), fileDisplayName: this.productFileDisplayName , uid:this.userItem[0].uid, fileUser: this.userItem[0].email,
    fileTags:this.selectedTags, eulaRequired:this.productFileEULAReq, isActive:this.productFileActive, version:this.productFileVersion, fileNotes: this.productFileNotes
  });   
  this.auth.logUserEvent('manageportalfile-updatecurrentrev',this.userItem[0].email + ' updated file -' + this.productFileDisplayName + ' :' + this.fileId + ' file updated to new rev : ' + this.productFileVersion  + '.',this.userItem[0].uid);
  this.showToast('File Updated','success');
}

createNewVersion(){

  this.afStore.collection('portalFiles').doc(this.fileId).update({
    isActive:false, 
  });   
  this.auth.logUserEvent('manageportalfile-createnewversion-setInactive',this.userItem[0].email + ' updated file -' + this.productFileDisplayName + ' :' + this.fileId + ' file InActive for New Version. Old Version : ' + this.productFileVersion  + '.',this.userItem[0].uid);
  this.showToast('New Version Created','success');

}

async alertdeleteFile(){
  var id:any, name:any
  id = this.fileId;
  name = this.productFileDisplayName;
  const alert = await this.alertCtrl.create({
    header: 'Delete File :' + name + ' ?',
    message: 'Are you sure you want to delete the file? This will also export the log data.',
    buttons: [
      'No',
      {
        text: 'Yes',
        handler: (data: any) => {
          this.deleteFile(id,name);
        }
      }
    ],
  });
  // now present the alert on top of all other content
  await alert.present();
}

deleteFile(id:any,name:any){
  this.auth.logUserEvent('manageproductdetail-filedelete', 'file ' + name + ' deleted by ' + this.userItem[0].email + '.',this.userItem[0].uid);
  this.afStore.collection("portalFiles").doc(id).delete().then(() => {
   
  });
  this.exportAsExcel();
  this.showToast('File Deleted','warning');
  this.dismiss(id);
//dataSourceFiles
}

setSelectedRole(event: string){ 
  var roleSelect = event['value']['roleName'];
       
    }

uploadHtmlEditor(){

}

getDownloadUrl(fileId:any): void{
  var downloadUrl;
  var fileName;
 this.afs
  .collection('portalFiles', ref => ref
  .where('fileId', '==', fileId))
  .valueChanges()
  .pipe(take(1))
  .subscribe(file => {  
    downloadUrl = file[0]['fileDownloadUrl']
    fileName = file[0]['fileName']
   
    
const getSigFile = httpsCallable(this.functions, 'generateSignedUrl');
let response = false;
//expire in 20minutes
const expirationDate = new Date(
  new Date().getTime() + .33 * 60 * 60 * 1000
);
this.showToastDownload('Download Started. Please wait ...','warning');
getSigFile({bucketName:'gs://portal-5edd4.appspot.com/portalFiles', fileName: fileName, expirationDate:expirationDate })
.then(({data: url}) => {

 const e = document.createElement('a');
 e.href = url.toString();
 e.target = "_blank";
 e.download = url.toString().substr(url.toString().lastIndexOf('/') + 1);
 document.body.appendChild(e);
 e.click();
 document.body.removeChild(e);
})
.catch(error => {
  console.log('Error getting url', error); 
  response = false;
});
    
  });
  
}

getFileFromApi(downloadUrl: string): Observable<HttpResponse<Blob>> {
  return this.http.get<Blob>(downloadUrl, { observe: 'response', responseType: 'blob' as 'json'});
}

showToastDownload(message:string, color:string) {
  this.myToast = this.toastCtrl.create({
    message: message,
    color: color,
    duration: 10000
  }).then((toastData) => {
    ////console.log(toastData);
    toastData.present();
  });
}

exportAsExcel() {
  
  const csvData = this.convertToCSV(this.dataSourceDownloadLog.data);
  const a = document.createElement('a');
  a.setAttribute('style', 'display:none;');
  document.body.appendChild(a);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = this.productFileName + '.csv';
  a.click();
}

convertToCSV(data) {
  const array = typeof data !== 'object' ? JSON.parse(data) : data;
  array.sort((a,b) => a.datetime - b.datetime); //.sort((a,b) => a.orderId - b.orderId);   
  let arrayData = data.map(function(x) {
    return {
           datetime:x.datetime,
           logtype:x.logtype,           
           fileName : x.fileName,
           uid : x.uid,
           userEmail : x.userEmail,
           version : x.version,
           fileId : x.fileId,
           message: x.message
          }
     });
  let str = '';
  let row = '';
  // get all the json headers
  for (const header in arrayData[0]) {
    if (header !== '') {
      row += header + ',';
    }
  }

  row = row.slice(0, -1);
  str += row + '\r\n';

  for (let i = 0; i < arrayData.length; i++) {
    let line = '';

    for (const index in arrayData[i]) {
      if (line !== '') {
        line += ',';
        let currString = new String
        currString =  arrayData[i][index];
        if (currString === undefined) {

        }else {

        
        if (currString !== null) {
          currString = currString.toString().replace(/(\r\n\t|\n|\r\t|\r|,)/gm, '');
        }
        line += currString;
      }
      } else {
        let currString = arrayData[i][index];
        if (currString !== null ) {
          currString = currString.toString().replace(/(\r\n\t|\n|\r\t|\r|,)/gm, '');
        }
        line += currString;
      }
    }

    str += line + '\r\n';
  }

  return str;
}

 
applyFilterDownloadLog(filterValue: any) {
  filterValue = filterValue.value.trim(); // Remove whitespace
  filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
  this.dataSourceDownloadLog.filter = filterValue;
}

 /** Announce the change in sort state for assistive technology. */
 announceSortChange(sortState: Sort) {
  if (sortState.direction) {
    this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
  } else {
    this._liveAnnouncer.announce('Sorting cleared');
  }
}


selectedTagsClose(event:any){
  //console.log(event);
  //console.log(this.selectedTags);

}

showToast(message:string, color:string) {
  this.myToast = this.toastCtrl.create({
    message: message,
    color: color,
    duration: 4000
  }).then((toastData) => {
    ////console.log(toastData);
    toastData.present();
  });
}
HideToast() {   
  this.myToast = this.toastCtrl.dismiss();
}


dismiss(data?: any) {
  // using the injected ModalController this page
  // can "dismiss" itself and pass back data
  this.modalCtrl.dismiss(data);
  //this.refreshFromQF();

}

}
