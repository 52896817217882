import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {ModalController  } from '@ionic/angular'
import { SettingsService } from 'src/app/services/settings.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage} from '@angular/fire/compat/storage';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator'; 
import {MatSort, Sort} from '@angular/material/sort';

@Component({
  selector: 'app-admin-order-detail',
  templateUrl: './admin-order-detail.page.html',
  styleUrls: ['./admin-order-detail.page.scss'],
})
export class AdminOrderDetailPage implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator; 
  @ViewChild('sort') sortMain: MatSort;
public selectedOrder:any;
public orderComp:any;
selectedTrackingNo:any;
currentShippingStatus:any;
displayedOrderColumns = ['orderDate', 'productClass', 'trackingNo', 'itemNo','customerPO','orderStatus','shipmentDate'];
dataSourceOrderDetail: MatTableDataSource<any>;
  constructor(private afs: AngularFirestore, private afStorage: AngularFireStorage,
    public modalCtrl: ModalController,
    private _liveAnnouncer: LiveAnnouncer,
    private storage: AngularFireStorage,
    public auth: AuthenticationService) { }

  ngOnInit() {
    this.getOrderDetail();
  }

  getOrderDetail(): void {
    this.afs.collection('orders', ref => ref.where('so', '==', 
   this.selectedOrder).orderBy('itemNo','asc')).valueChanges()
   //.pipe(take(1))
   .subscribe(orderDetail => {    
    this.orderComp = orderDetail[0]['companyName'];
     this.dataSourceOrderDetail = new MatTableDataSource<any>(orderDetail);
     this.dataSourceOrderDetail.paginator = this.paginator;
     this.dataSourceOrderDetail.sort = this.sortMain;
      });
  }

  applyFilterOrders(filterValue: any) {
    filterValue = filterValue.value.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSourceOrderDetail.filter = filterValue;
  }

   /** Announce the change in sort state for assistive technology. */
   announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }  

  getShippingInfoFunc(trackingNo:any,el:HTMLElement){
    this.selectedTrackingNo = trackingNo;
    
    this.scroll(el);
    //dsOrderDetail
  }

  scroll(el: HTMLElement) {
    const shipEl = document.getElementById("shippingInfo");
    setTimeout(() => {
      shipEl.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }, 100);
    
}

  dismiss(data?: any) {
    // using the injected ModalController this page
    // can "dismiss" itself and pass back data
    this.modalCtrl.dismiss(data);  
  }
}
