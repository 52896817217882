import { Component, Directive, ElementRef, OnInit, AfterViewInit, ViewChild  } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RouterOutlet, Router, ActivationStart,NavigationEnd } from '@angular/router';
import { IonInput, ToastController,LoadingController } from '@ionic/angular';
import { UserData } from '../../providers/user-data';
import { UserOptions } from '../../interfaces/user-options';
import { AuthenticationService } from "../../services/authentication.service";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit, AfterViewInit {
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  @ViewChild('username', {static: false}) userNameInput: IonInput; // { focus: () => void; };
  //@ViewChild('username') userNameInput: IonInput;
  //userNameInput: { setFocus: () => void; };
  //@ViewChild('searchbar') searchbar: Searchbar;
  @ViewChild('password', {static: false}) passInput: { setFocus: () => void; };
  login: UserOptions = { username: '', password: '' };
  submitted = false;
  empInfo: any = [];
  employeeImgURL: any;
  employeeFullName: any;
  checkNameText: any;
  private myToast: any;
  returnUrl :any;
  @ViewChild('email', {static: false}) emailInput: { setFocus: () => void; };
  constructor(
    public userData: UserData,
    public authService: AuthenticationService,
    public afStore: AngularFirestore,
    public router: Router,
    private el: ElementRef,
    public toastCtrl: ToastController,
    private elementRef: ElementRef,
    public loadingCtrl: LoadingController,
    public currRoute: ActivatedRoute,
  ) {router.canceledNavigationResolution = 'computed'; }

  ngOnInit() {
    this.returnUrl = this.currRoute.snapshot.queryParams['returnURL'] || '/'; 
    //http://localhost:8100/login?returnURL=products
  }

  ionViewDidEnter(): void {
    //this._setFocusDefaultInput(this.username);
   // this.userNameInput.setFocus();
   //document.addEventListener('mousemove', this.getCursorPosition);

 
  }

  //  getCursorPosition(event) {
  //   const x = event.clientX - window.innerWidth / 2;
  //   const y = event.clientY - window.innerHeight / 2;
  //   const angle = Math.atan2(y, x);
  //   const hue = (angle + Math.PI) / (2 * Math.PI) * 360;
  //   //document.documentElement.style.setProperty('--user-hue', hue);
  // }

  getUser(){

  }

  private _setFocusDefaultInput(input: ElementRef): void {
    const ionInput = input['username']; // get the DOM element from Ionic
    // See what you get for the ionInput
    //console.log(ionInput);
    // If ionInput is undefined, put a question mark between the . and setFocus
    // This way, it won't run if ionInput is undefined or null
    // You can of course put an if check as well.
    ionInput?.setFocus();
}

  ngAfterViewInit(){
    // const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
  //   setTimeout(() => {
  //     this.userNameInput.fireFocusEvents = true;
  //     this.userNameInput.setFocus();
      
  // }, 90);
  setTimeout(() => {
    this.emailInput.setFocus();
}, 900);
  }

  async logIn(email, password) {
    const loading = await this.loadingCtrl.create({
      message: 'Loading...',
      duration: 3000,
      spinner: 'dots',
    });
    this.authService.SignIn(email.value, password.value)
      .then((res) => {
        if(res.user.emailVerified) {      
          try {
            this.authService.SetUserDataFromLoginPage(res.user.uid);
            setTimeout(() => {
               var currUserUtem:any;
              currUserUtem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
              
              // if (currUserUtem=== null) {
              //   this.router.navigateByUrl('/accounthold', { replaceUrl:true });  
              // } else if (currUserUtem<= 0) {   
              //   this.router.navigateByUrl('/accounthold', { replaceUrl:true });  
              // }
              //window.location.reload();            
              loading.dismiss();
              if (this.returnUrl === 'products') {
                this.router.navigateByUrl('/products', { replaceUrl:true });  
              } 
              else if (this.returnUrl === 'dashboard') {
                this.router.navigateByUrl('/dashboard', { replaceUrl:true });  
              }
              // else if (res.user.uid != null && this.returnUrl  != 'dashboard' ) {
              //   this.router.navigateByUrl('/accounthold', { replaceUrl:true });  
              // }
              else {
                var currUserUtem:any;
                currUserUtem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
                
                if (currUserUtem=== null) {
                  this.router.navigateByUrl('/accounthold', { replaceUrl:true });  
                } else if (currUserUtem<= 0) {   
                  this.router.navigateByUrl('/accounthold', { replaceUrl:true });  
                } else {
                  this.router.navigateByUrl('/dashboard', { replaceUrl:true });  
                }
                
                //
               //this.router.navigateByUrl('/accounthold', { replaceUrl:true });                
              // if (this.router.url === '/login') {
              //   console.log('Currently on Login Page');
              // } else if  (this.router.url === '/accounthold') {
              //   console.log('Currently on Account Hold Page');
              // } else {
              //   this.router.navigateByUrl('/dashboard', { replaceUrl:true });  
              // }
              
            }
            }, 2500);  
            // const compActive = JSON.parse(localStorage.getItem('compActive'));
          
            //   if (compActive == true){
               
            //   } else {
            //     window.alert('Your Company Is Not Active. Please Contact Support.')
            //     loading.dismiss();
            //     return false;
            //   }
            //this.router.navigate(['dashboard']);
           // loading.dismiss();
          } catch (error) {
            this.router.navigateByUrl('/dashboard', { replaceUrl:true });  
          }    
      
            
        } else {
          window.alert('Email is not verified. Please check your email for a verification link or resend the link.')
          loading.dismiss();
          this.router.navigateByUrl('/verify-email', { replaceUrl:true });  
          return false;
        }
      }).catch((error) => {
        window.alert(error.message)
        loading.dismiss();
      })
      return await loading.present();
  }

  // onLogin(form: NgForm) {
    
  //   this.submitted = true;
  //   if (form.valid) {
  //     //this.userData.login(this.login.username);
  //    // this.auth.login(this.login.username, this.login.password) ; 
  //     this.submitted = true;
  //     setTimeout(() => {
  //       //if (this.auth.authenticated){
  //         this.router.navigate(['/app/tabs/schedule'])
  //       // } else {
  //       //   this.showToast('Bad Username/Password!',"danger");
  //       // }
  //   }, 500);
      
  //     //this.router.navigateByUrl('/jobstatus');
      
  //   }
  // }
}
