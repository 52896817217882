import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterOutlet, Router, ActivationStart } from '@angular/router';
import { IonList, IonRouterOutlet, LoadingController } from '@ionic/angular';
import { Config, ModalController, NavParams, NavController, IonDatetime } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireList,AngularFireObject } from '@angular/fire/compat/database';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'; 
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GoogleMap,MapMarker,MapInfoWindow } from '@angular/google-maps';
import { Lightbox,LightboxConfig } from 'ngx-lightbox';
import * as JSZip from 'jszip';  
import * as FileSaver from 'file-saver';  
import { checklist } from 'src/app/interfaces/checklist';


@Component({
  selector: 'app-checklist-view',
  templateUrl: './checklist-view.page.html',
  styleUrls: ['./checklist-view.page.scss'],
})
export class ChecklistViewPage implements OnInit {
  
public selectedCheckList:any;
checklistRef: AngularFireObject<any>; 
feopcosRef: AngularFireObject<any>; 
voltageRef: AngularFireObject<any>; 
//selectedCheclistObj: checklist[];  
selectedCheclistObj: checklist[] = [];
//selectedCheclistObj:any[];
checklistuser:any[];
checklistuserimg:any;
checklistusername:any;
filesArray: []; 
markerIcon: 'assets/icon/switchIcon.png';
defaultImage:any;
apiLoaded: Observable<boolean>;
@ViewChild('checklistmap', { static: false })
checklistmap!: GoogleMap;
westpa = { lat: 40.340712, lng: -79.645392 };
options: google.maps.MapOptions = {
  center: this.westpa,
  zoom: 20,    
  mapTypeId: 'satellite',
};
markers = []  as  any;
private _album:Array<any> = [];
  constructor(public modalCtrl: ModalController,private afs: AngularFirestore, public afdb: AngularFireDatabase,  private afStorage: AngularFireStorage,
    private httpClient: HttpClient, private _lightbox: Lightbox,private _lightboxConfig: LightboxConfig) {
      _lightboxConfig.fadeDuration = 1;
      _lightboxConfig.showRotate = true;
      _lightboxConfig.showZoom = true;
      _lightboxConfig.showDownloadButton = true;
      // this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyBpYUb-BiS8cYBdLZN2oXIPMdWmd3t5m4Y&libraries=visualization&callback=loadMapData', 'callback')
      // .pipe(
      //   map(() => true),
      //   catchError(() => of(false)),
      // );
      //this.apiLoaded = true;
     }

  ngOnInit() {
    this.defaultImage = "https://firebasestorage.googleapis.com/v0/b/portal-5edd4.appspot.com/o/portalAssets%2Fimage_placeholder.jpg?alt=media&token=2bc97706-97b1-4d10-81dc-55dc7977e103"
    //console.log('passedlistkey:' + this.selectedCheckList)

    let s = this.GetSingleCheckList(this.selectedCheckList); 
    s.valueChanges()
    //.pipe(take(1))
    .subscribe(data => { // Using snapshotChanges() method to retrieve list of data along with metadata($key)
      this.selectedCheclistObj = data;
      //console.log(this.selectedCheclistObj);
      var lat = this.selectedCheclistObj['gpscoords'].split(',')[0];
      var lng = this.selectedCheclistObj['gpscoords'].split(',')[1];
      this.westpa.lat = parseFloat(lat);
      this.westpa.lng = parseFloat(lng);
      this.getChecklistUserProfile(data);      

      
      if(this.selectedCheclistObj['comcheckpic1a'] === ""){this.selectedCheclistObj['comcheckpic1a'] = this.defaultImage}
      const com1aimg = {src: this.selectedCheclistObj['comcheckpic1a'], FileRef: this.selectedCheclistObj['comcheckpic1a'],caption: '1. Switch idendtification placard at bottom of pole (A)',thumb: this.selectedCheclistObj['comcheckpic1a']};
      this._album.push(com1aimg);
      if(this.selectedCheclistObj['comcheckpic1b'] === ""){this.selectedCheclistObj['comcheckpic1b'] = this.defaultImage}
      const com1bimg = {src: this.selectedCheclistObj['comcheckpic1b'], FileRef: this.selectedCheclistObj['comcheckpic1b'],caption: '1. Switch idendtification placard at bottom of pole (B)',thumb: this.selectedCheclistObj['comcheckpic1b']};
      this._album.push(com1bimg);
      if(this.selectedCheclistObj['comcheckpic1c'] === ""){this.selectedCheclistObj['comcheckpic1c'] = this.defaultImage}
      const com1cimg = {src: this.selectedCheclistObj['comcheckpic1c'], FileRef: this.selectedCheclistObj['comcheckpic1c'],caption: '1. Switch idendtification placard at bottom of pole (C)',thumb: this.selectedCheclistObj['comcheckpic1c']};
      this._album.push(com1cimg);
      if(this.selectedCheclistObj['comcheckpic2a'] === ""){this.selectedCheclistObj['comcheckpic2a'] = this.defaultImage}
      const com2aimg = {src: this.selectedCheclistObj['comcheckpic2a'], FileRef: this.selectedCheclistObj['comcheckpic2a'],caption: '2. Overview of the switch structure, showing the entire switch (A)',thumb: this.selectedCheclistObj['comcheckpic2a']};
      this._album.push(com2aimg);
      if(this.selectedCheclistObj['comcheckpic2b'] === ""){this.selectedCheclistObj['comcheckpic2b'] = this.defaultImage}
      const com2bimg = {src: this.selectedCheclistObj['comcheckpic2b'], FileRef: this.selectedCheclistObj['comcheckpic2b'],caption: '2. Overview of the switch structure, showing the entire switch (B)',thumb: this.selectedCheclistObj['comcheckpic2b']};
      this._album.push(com2bimg);
      if(this.selectedCheclistObj['comcheckpic2c'] === ""){this.selectedCheclistObj['comcheckpic2c'] = this.defaultImage}
      const com2cimg = {src: this.selectedCheclistObj['comcheckpic2c'], FileRef: this.selectedCheclistObj['comcheckpic2c'],caption: '2. Overview of the switch structure, showing the entire switch (C)',thumb: this.selectedCheclistObj['comcheckpic2c']};
      this._album.push(com2cimg);
      if(this.selectedCheclistObj['comcheckpic3a'] === ""){this.selectedCheclistObj['comcheckpic3a'] = this.defaultImage}
      const com3aimg = {src: this.selectedCheclistObj['comcheckpic3a'], FileRef: this.selectedCheclistObj['comcheckpic3a'],caption: '3. Overview of all (3) phases of switch including counter balance springs (A)',thumb: this.selectedCheclistObj['comcheckpic3a']};
      this._album.push(com3aimg);
      if(this.selectedCheclistObj['comcheckpic3b'] === ""){this.selectedCheclistObj['comcheckpic3b'] = this.defaultImage}
      const com3bimg = {src: this.selectedCheclistObj['comcheckpic3b'], FileRef: this.selectedCheclistObj['comcheckpic3b'],caption: '3. Overview of all (3) phases of switch including counter balance springs (B)',thumb: this.selectedCheclistObj['comcheckpic3b']};
      this._album.push(com3bimg);
      if(this.selectedCheclistObj['comcheckpic3c'] === ""){this.selectedCheclistObj['comcheckpic3c'] = this.defaultImage}
      const com3cimg = {src: this.selectedCheclistObj['comcheckpic3c'], FileRef: this.selectedCheclistObj['comcheckpic3c'],caption: '3. Overview of all (3) phases of switch including counter balance springs (C)',thumb: this.selectedCheclistObj['comcheckpic3c']};
      this._album.push(com3cimg);
      if(this.selectedCheclistObj['comcheckpic4a'] === ""){this.selectedCheclistObj['comcheckpic4a'] = this.defaultImage}
      const com4aimg = {src: this.selectedCheclistObj['comcheckpic4a'], FileRef: this.selectedCheclistObj['comcheckpic4a'],caption: '4. Bottom phase of switch with open & closed toggle positions (A)',thumb: this.selectedCheclistObj['comcheckpic4a']};
      this._album.push(com4aimg);
      if(this.selectedCheclistObj['comcheckpic4b'] === ""){this.selectedCheclistObj['comcheckpic4b'] = this.defaultImage}
      const com4bimg = {src: this.selectedCheclistObj['comcheckpic4b'], FileRef: this.selectedCheclistObj['comcheckpic4b'],caption: '4. Bottom phase of switch with open & closed toggle positions (B)',thumb: this.selectedCheclistObj['comcheckpic4b']};
      this._album.push(com4bimg);
      if(this.selectedCheclistObj['comcheckpic4c'] === ""){this.selectedCheclistObj['comcheckpic4c'] = this.defaultImage}
      const com4cimg = {src: this.selectedCheclistObj['comcheckpic4c'], FileRef: this.selectedCheclistObj['comcheckpic4c'],caption: '4. Bottom phase of switch with open & closed toggle positions (C)',thumb: this.selectedCheclistObj['comcheckpic4c']};
      this._album.push(com4cimg);
      if(this.selectedCheclistObj['comcheckpic5a'] === ""){this.selectedCheclistObj['comcheckpic5a'] = this.defaultImage}
      const com5aimg = {src: this.selectedCheclistObj['comcheckpic5a'], FileRef: this.selectedCheclistObj['comcheckpic5a'],caption: '5. Interrupting device (close up) (A)',thumb: this.selectedCheclistObj['comcheckpic5a']};
      this._album.push(com5aimg);
      if(this.selectedCheclistObj['comcheckpic5b'] === ""){this.selectedCheclistObj['comcheckpic5b'] = this.defaultImage}
      const com5bimg = {src: this.selectedCheclistObj['comcheckpic5b'], FileRef: this.selectedCheclistObj['comcheckpic5b'],caption: '5. Interrupting device (close up) (B)',thumb: this.selectedCheclistObj['comcheckpic5b']};
      this._album.push(com5bimg);
      if(this.selectedCheclistObj['comcheckpic5c'] === ""){this.selectedCheclistObj['comcheckpic5c'] = this.defaultImage}
      const com5cimg = {src: this.selectedCheclistObj['comcheckpic5c'], FileRef: this.selectedCheclistObj['comcheckpic5c'],caption: '5. Interrupting device (close up) (C)',thumb: this.selectedCheclistObj['comcheckpic5c']};
      this._album.push(com5cimg);
      if(this.selectedCheclistObj['comcheckpic6a'] === ""){this.selectedCheclistObj['comcheckpic6a'] = this.defaultImage}
      const com6aimg = {src: this.selectedCheclistObj['comcheckpic6a'], FileRef: this.selectedCheclistObj['comcheckpic6a'],caption: '6. Switch Nameplate (A)',thumb: this.selectedCheclistObj['comcheckpic6a']};
      this._album.push(com6aimg);
      if(this.selectedCheclistObj['comcheckpic6b'] === ""){this.selectedCheclistObj['comcheckpic6b'] = this.defaultImage}
      const com6bimg = {src: this.selectedCheclistObj['comcheckpic6b'], FileRef: this.selectedCheclistObj['comcheckpic6b'],caption: '6. Switch Nameplate (B)',thumb: this.selectedCheclistObj['comcheckpic6b']};
      this._album.push(com6bimg);
      if(this.selectedCheclistObj['comcheckpic6c'] === ""){this.selectedCheclistObj['comcheckpic6c'] = this.defaultImage}
      const com6cimg = {src: this.selectedCheclistObj['comcheckpic6c'], FileRef: this.selectedCheclistObj['comcheckpic6c'],caption: '6. Switch Nameplate (C)',thumb: this.selectedCheclistObj['comcheckpic6c']};
      this._album.push(com6cimg);
      if(this.selectedCheclistObj['comcheckpic7a'] === ""){this.selectedCheclistObj['comcheckpic7a'] = this.defaultImage}
      const com7aimg = {src: this.selectedCheclistObj['comcheckpic7a'], FileRef: this.selectedCheclistObj['comcheckpic7a'],caption: '7. Motor operator nameplate (if applicable) (A)',thumb: this.selectedCheclistObj['comcheckpic7a']};
      this._album.push(com7aimg);
      if(this.selectedCheclistObj['comcheckpic7b'] === ""){this.selectedCheclistObj['comcheckpic7b'] = this.defaultImage}
      const com7bimg = {src: this.selectedCheclistObj['comcheckpic7b'], FileRef: this.selectedCheclistObj['comcheckpic7b'],caption: '7. Motor operator nameplate (if applicable) (B)',thumb: this.selectedCheclistObj['comcheckpic7b']};
      this._album.push(com7bimg);
      if(this.selectedCheclistObj['comcheckpic7c'] === ""){this.selectedCheclistObj['comcheckpic7c'] = this.defaultImage}
      const com7cimg = {src: this.selectedCheclistObj['comcheckpic7c'], FileRef: this.selectedCheclistObj['comcheckpic7c'],caption: '7. Motor operator nameplate (if applicable) (C)',thumb: this.selectedCheclistObj['comcheckpic7c']};
      this._album.push(com7cimg);
      if(this.selectedCheclistObj['comcheckpic8a'] === ""){this.selectedCheclistObj['comcheckpic8a'] = this.defaultImage}
      const com8aimg = {src: this.selectedCheclistObj['comcheckpic8a'], FileRef: this.selectedCheclistObj['comcheckpic8a'],caption: '8. Control rod insulator and PVC bushing/clamp (A)',thumb: this.selectedCheclistObj['comcheckpic8a']};
      this._album.push(com8aimg);
      if(this.selectedCheclistObj['comcheckpic8b'] === ""){this.selectedCheclistObj['comcheckpic8b'] = this.defaultImage}
      const com8bimg = {src: this.selectedCheclistObj['comcheckpic8b'], FileRef: this.selectedCheclistObj['comcheckpic8b'],caption: '8. Control rod insulator and PVC bushing/clamp (B)',thumb: this.selectedCheclistObj['comcheckpic8b']};
      this._album.push(com8bimg);
      if(this.selectedCheclistObj['comcheckpic8c'] === ""){this.selectedCheclistObj['comcheckpic8c'] = this.defaultImage}
      const com8cimg = {src: this.selectedCheclistObj['comcheckpic8c'], FileRef: this.selectedCheclistObj['comcheckpic8c'],caption: '8. Control rod insulator and PVC bushing/clamp (C)',thumb: this.selectedCheclistObj['comcheckpic8c']};
      this._album.push(com8cimg);
      if(this.selectedCheclistObj['comcheckpic9a'] === ""){this.selectedCheclistObj['comcheckpic9a'] = this.defaultImage}
      const com9aimg = {src: this.selectedCheclistObj['comcheckpic9a'], FileRef: this.selectedCheclistObj['comcheckpic9a'],caption: '9. Motor operator travel stop points (180-200 degree positions) (A)',thumb: this.selectedCheclistObj['comcheckpic9a']};
      this._album.push(com9aimg);
      if(this.selectedCheclistObj['comcheckpic9b'] === ""){this.selectedCheclistObj['comcheckpic9b'] = this.defaultImage}
      const com9bimg = {src: this.selectedCheclistObj['comcheckpic9b'], FileRef: this.selectedCheclistObj['comcheckpic9b'],caption: '9. Motor operator travel stop points (180-200 degree positions) (B)',thumb: this.selectedCheclistObj['comcheckpic9b']};
      this._album.push(com9bimg);
      if(this.selectedCheclistObj['comcheckpic9c'] === ""){this.selectedCheclistObj['comcheckpic9c'] = this.defaultImage}
      const com9cimg = {src: this.selectedCheclistObj['comcheckpic9c'], FileRef: this.selectedCheclistObj['comcheckpic9c'],caption: '9. Motor operator travel stop points (180-200 degree positions) (C)',thumb: this.selectedCheclistObj['comcheckpic9c']};
      this._album.push(com9cimg);
      if(this.selectedCheclistObj['comcheckpic9aa'] === ""){this.selectedCheclistObj['comcheckpic9aa'] = this.defaultImage}
      const com9aaimg = {src: this.selectedCheclistObj['comcheckpic9aa'], FileRef: this.selectedCheclistObj['comcheckpic9aa'],caption: '9a. Verifying enough clearance for decoupler cover (A)',thumb: this.selectedCheclistObj['comcheckpic9aa']};
      this._album.push(com9aaimg);
      if(this.selectedCheclistObj['comcheckpic9ab'] === ""){this.selectedCheclistObj['comcheckpic9ab'] = this.defaultImage}
      const com9abimg = {src: this.selectedCheclistObj['comcheckpic9ab'], FileRef: this.selectedCheclistObj['comcheckpic9ab'],caption: '9a. Verifying enough clearance for decoupler cover (B)',thumb: this.selectedCheclistObj['comcheckpic9ab']};
      this._album.push(com9abimg);
      if(this.selectedCheclistObj['comcheckpic9ac'] === ""){this.selectedCheclistObj['comcheckpic9ac'] = this.defaultImage}
      const com9acimg = {src: this.selectedCheclistObj['comcheckpic9ac'], FileRef: this.selectedCheclistObj['comcheckpic9ac'],caption: '9a. Verifying enough clearance for decoupler cover (C)',thumb: this.selectedCheclistObj['comcheckpic9ac']};
      this._album.push(com9acimg);
      if(this.selectedCheclistObj['comcheckpic10a'] === ""){this.selectedCheclistObj['comcheckpic10a'] = this.defaultImage}
      const com10aimg = {src: this.selectedCheclistObj['comcheckpic10a'], FileRef: this.selectedCheclistObj['comcheckpic10a'],caption: '10. Operating device and grounding with mat (A)',thumb: this.selectedCheclistObj['comcheckpic10a']};
      this._album.push(com10aimg);
      if(this.selectedCheclistObj['comcheckpic10b'] === ""){this.selectedCheclistObj['comcheckpic10b'] = this.defaultImage}
      const com10bimg = {src: this.selectedCheclistObj['comcheckpic10b'], FileRef: this.selectedCheclistObj['comcheckpic10b'],caption: '10. Operating device and grounding with mat (B)',thumb: this.selectedCheclistObj['comcheckpic10b']};
      this._album.push(com10bimg);
      if(this.selectedCheclistObj['comcheckpic10c'] === ""){this.selectedCheclistObj['comcheckpic10c'] = this.defaultImage}
      const com10cimg = {src: this.selectedCheclistObj['comcheckpic10c'], FileRef: this.selectedCheclistObj['comcheckpic10c'],caption: '10. Operating device and grounding with mat (C)',thumb: this.selectedCheclistObj['comcheckpic10c']};
      this._album.push(com10cimg);

      this.markers.push({
        position: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },          
        label: {
          // margin:"3px",
          // color: "blue",
          // fontSize: "16px",
          // fontWeight: "bold",               
          text: this.selectedCheclistObj['polenum'],  //a["switchno"] polenum
          labelClass : "labelClass",
        },
        title: this.selectedCheclistObj['switchno'],                              
        info:  ' ', //this.selectedCheclistObj['linename'] + ' - ' + this.selectedCheclistObj['0']['switchno'],       
        icon: this.markerIcon,   
        options: {
          animation: google.maps.Animation.DROP,            
          icon: 'assets/icon/markerIconMainGreen.png',
          center: this.westpa,
               
        },
      })

    });

    this.onMapReady(this.checklistmap);

  }

  private getChecklistUserProfile(data:any): void {
       
    this.afs
    .collection('fieldServiceUsers', ref => ref
    .where('userName', '==', data.username))
    .valueChanges()
    //.pipe(take(1))
    .subscribe(checklistuser => {
      this.checklistuser = checklistuser;      
      this.checklistuserimg = checklistuser['0']['profileImgUrl'];
      this.checklistusername = checklistuser['0']['userFullName'];
      //console.log(this.checklistuser)
    });
   // //console.log(this.afs.collection.name);
  }

  dismiss(data?: any) {
    // using the injected ModalController this page
    // can "dismiss" itself and pass back data
    this.modalCtrl.dismiss(this.selectedCheckList);
    //this.refreshFromQF();
  
  }

  downloadAllChecklistImages() {
    this.createZip(this._album.map(c => c.FileRef), this.selectedCheckList);  
  }

  async createZip(files: any[], zipName: string) {  
    const zip = new JSZip();  
    const name = zipName + '.zip';  
    // tslint:disable-next-line:prefer-for-of  
    for (let counter = 0; counter < files.length; counter++) {  
      const element = files[counter];  
      const fileData: any = await this.getFile(element);  
      const b: any = new Blob([fileData], { type: '' + fileData.type + '' });  
      zip.file(element.substring(element.lastIndexOf('/') + 1), b);  
    }  
    zip.generateAsync({ type: 'blob' }).then((content) => {  
      if (content) {  
        FileSaver.saveAs(content, name);  
      }  
    });  
  }  

  async getFile(url: string) {  
    const httpOptions = {  
      responseType: 'blob' as 'json'  
    };  
    const res = await this.httpClient.get(url, httpOptions).toPromise().catch((err: HttpErrorResponse) => {  
      const error = err.error;  
      return error;  
    });  
    return res;  
  }  

  GetSingleCheckList(id:string) {
    this.checklistRef = this.afdb.object('checklists/' + id);
    return this.checklistRef;
  }  

  GetFEOpCos(id:string) {
    this.feopcosRef = this.afdb.object('FE_Op_Cos/' + id);
    return this.checklistRef;
  } 

  GetVoltage(id:string) {
    this.voltageRef = this.afdb.object('Voltages/' + id);
    return this.checklistRef;
  }  
  

  onMapReady(map: any) {
    var lat = this.selectedCheclistObj['gpscoords'].split(',')[0];
    var lng = this.selectedCheclistObj['gpscoords'].split(',')[1];
    this.westpa.lat = parseFloat(lat);
    this.westpa.lng = parseFloat(lng);
  }

  openImg(index:any){
    
    this._lightbox.open(this._album, index);
  }

  closeImg(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  print(): void {
    const printContent = document.getElementById("checklistMain");
const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
WindowPrt.document.write(printContent.innerHTML);
WindowPrt.document.close();
WindowPrt.focus();
WindowPrt.print();
//WindowPrt.close();
  }


}
