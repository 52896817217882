import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { IonTabs,IonSegment, IonTabButton, ToastController, IonInput,ModalController, IonRouterOutlet  } from '@ionic/angular'
import { SettingsService } from 'src/app/services/settings.service';
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserFiles } from '../../interfaces/user-files';
import { Observable, Subscription } from 'rxjs';
import { switchMap,map, take } from 'rxjs/operators';

@Component({
  selector: 'app-manage-app',
  templateUrl: './manage-app.page.html',
  styleUrls: ['./manage-app.page.scss'],
})
export class ManageAppPage implements OnInit {
  userItem: any;
  pageControl: any;  
  private myToast: any;

  constructor(public afs: AngularFirestore, private afStorage: AngularFireStorage,
    public modalCtrl: ModalController,
    public routerOutlet: IonRouterOutlet,
    public toastCtrl: ToastController,
    private _liveAnnouncer: LiveAnnouncer,
    private storage: AngularFireStorage,
    public afStore: AngularFirestore,
    public auth: AuthenticationService) { }

  ngOnInit() {
    this.userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
    this.getPageControl();
  }


  getPageControl(): void{
    this.afs
    .collection('pageControl').valueChanges()
    //.pipe(take(1))
    .subscribe(pageControlData => {
      this.pageControl = [];
      this.pageControl = pageControlData;    
    });

    }

    
  updateToggle(e,docId:any,pageTitle:any,field:any){
    const ischecked: boolean =  e.currentTarget.checked;
if (field === 'isActive') {
  this.afs.collection('pageControl').doc(docId).update({
    isActive:ischecked     
  });
} else if (field === 'reqlogin') {
  this.afs.collection('pageControl').doc(docId).update({
    reqlogin:ischecked     
  });
} else if (field === 'reqCpAdmin') {
  this.afs.collection('pageControl').doc(docId).update({
    reqCpAdmin:ischecked     
  });
}

this.afStore.collection("mail").add({
  to: ['jlewis808@gmail.com'],
  message: {
    subject: pageTitle + ' ' + field + ' Modified',
    text: "pageIsActive-" + field + '-' + pageTitle + '-' + ischecked + ' id: ' + docId,
    html: "pageIsActive-" + field + '-' + pageTitle + '-' + ischecked + ' id: ' + docId
  }
})
.then(docRef => {
  //console.log("Document written with ID: ", docRef.id);
 // //console.log("You can now also access this. as expected: ", this.foo)     
 this.afStore.collection('pageControl').doc(docRef.id).update({
  id: docRef.id      
});
})
.catch(error => console.error("Error adding document: ", error))

    this.auth.logUserEvent("pageIsActive-" + field + '-' + pageTitle + '-' + ischecked,docId,this.userItem[0].uid);
    this.showToast(pageTitle + '-' + field + ' Marked: ' + ischecked,'success') ;
  }

  saveRoute(){

  }

  saveTitle(){

  }

  saveOrdering(){

  }

  showToast(message:string, color:string) {
    this.myToast = this.toastCtrl.create({
      message: message,
      color: color,
      duration: 4000
    }).then((toastData) => {
      ////console.log(toastData);
      toastData.present();
    });
  }
  HideToast() {
    this.myToast = this.toastCtrl.dismiss();
  }
}
