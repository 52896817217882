import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatInputModule} from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ManageMessagesPageRoutingModule } from './manage-messages-routing.module';
import { IonicSelectableModule } from 'ionic-selectable';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ManageMessagesPage } from './manage-messages.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule.forRoot(),
    MatTableModule,
    MatTabsModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatButtonModule,
    IonicSelectableModule,
    AngularEditorModule,
    ManageMessagesPageRoutingModule
  ],
  declarations: [ManageMessagesPage]
})
export class ManageMessagesPageModule {}
