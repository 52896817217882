import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatInputModule} from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { IonicSelectableModule } from 'ionic-selectable';
import { ManageProductsDetailPageRoutingModule } from './manage-products-detail-routing.module';
import { ManageProductsDetailPage } from './manage-products-detail.page';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { CKEditorModule } from 'ng2-ckeditor';
// import { QuillModule } from 'ngx-quill';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule.forRoot(),
    // QuillModule.forRoot({
    //   modules: {
    //     syntax: true
    //   }
    // }),
    MatTableModule,
    MatTabsModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatButtonModule,
    AngularFireDatabaseModule,
    IonicSelectableModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularEditorModule,
    CKEditorModule,
    ManageProductsDetailPageRoutingModule
  ],
  declarations: [ManageProductsDetailPage]
})
export class ManageProductsDetailPageModule {}
