import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { IonTabs,IonSegment, IonTabButton, ToastController, IonInput,ModalController, IonRouterOutlet, AlertController,LoadingController  } from '@ionic/angular'
import { SettingsService } from 'src/app/services/settings.service';
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserFiles } from '../../interfaces/user-files';
import { Observable, Subscription } from 'rxjs';
import { ManageUsersDetailPage } from '../manage-users-detail/manage-users-detail.page';
import { switchMap,map, take } from 'rxjs/operators';

@Component({
  selector: 'app-manage-reg-detail',
  templateUrl: './manage-reg-detail.page.html',
  styleUrls: ['./manage-reg-detail.page.scss'],
})
export class ManageRegDetailPage implements OnInit {
  regid:any;
  appPermissions:any
  dataSourceRegInfo: MatTableDataSource<any>;

userRegName: any; 
userItem: any;
userRegCompanyId:any;
userRegEmail:any;
userRegPhotoUrl:any;
userRegFullName:any;
userRegCompanyName:any;
userRegPhone:any;
userRegTitle:any;
userRegDate:any;
userRegApproved:any;
userRegApprovedDate:any;
userRegApprovedBy:any;
userRegUID:any;
companyLogoURL:any;
dateCreated:any;
private myToast: any;
  constructor(private afs: AngularFirestore, private afStorage: AngularFireStorage, public toastCtrl: ToastController,
    public modalCtrl: ModalController,  
    public alertCtrl: AlertController,    
    public loadingCtrl: LoadingController,     
    public routerOutlet: IonRouterOutlet,
    private _liveAnnouncer: LiveAnnouncer,
    private storage: AngularFireStorage,
    public afStore: AngularFirestore,
    public auth: AuthenticationService) { }

  ngOnInit() {
    this.userItem = JSON.parse(localStorage.getItem('userItem'));
    this.getRegistration();
  }

  getRegistration(): void{
   
               
      this.afs
      .collection('registrations', ref => ref.where('regId', '==', 
       this.regid).orderBy('regDate', 'desc')).valueChanges()
       //.pipe(take(1))
       .subscribe(reginfo => {
       // this.dataSourceRegInfo = new MatTableDataSource<any>(reginfo);       
//        //console.log(reginfo);
        this.userRegEmail = reginfo[0]['email'];
        this.userRegCompanyName = reginfo[0]['company'];
        this.userRegFullName = reginfo[0]['fullName'];
        this.userRegPhone = reginfo[0]['phone'];
        this.userRegTitle = reginfo[0]['title'];
        this.userRegDate = reginfo[0]['regDate'];
        this.userRegApproved = reginfo[0]['approved'];
        this.userRegApprovedDate = reginfo[0]['approvedDate'];
        this.userRegApprovedBy = reginfo[0]['approvedBy'];
        this.userRegUID =  reginfo[0]['uid'];
        //this.userRegEmail = reginfo[0].email;
        // this.userName = reginfo[0].email;
        // this.userdisplayName = reginfo[0].displayName;
        // this.userCompanyId = reginfo[0].companyId;
        // this.userPhotoUrl = reginfo[0].photoURL;
        // this.userCompanyName = reginfo[0].companyName;
        // this.companyLogoURL = reginfo[0].logoURL;
        // this.dateCreated = reginfo[0].dateCreated.toDate() === undefined ? "" : reginfo[0].dateCreated.toDate();       
    });
  }
  
  async approveUser(){
   // this.auth.SendVerificationMailAdminScreen(this.userRegEmail);
   // const loading = await this.loadingCtrl.create();
    
    const userFullName = this.userRegFullName;
    const userEmail = this.userRegEmail;
    const userCompany = ''
    const userRole = ''  
    const userIsAdmin = false; 
    const regId = this.regid;
    const regUID = this.userRegUID;
        //this.userId  = this.userItem[0].uid;
      //const uid = 'LfmqW5SSNkUb9K8fxtKY'; // this.userItem[0].uid;
    const newUserRef = this.afStore.doc('users/' + regUID);
    //set() for destructive updates
    //assignedRep: B6Qf2T1230t5kxn9sQhG is Customer Portal Admin
    newUserRef.set({  displayName:userFullName,email:userEmail, companyId:'aRhrknfdhT5GKcPzjH3f', role:'Customer',isAdmin:userIsAdmin,emailVerified:true, assignedRep:'B6Qf2T1230t5kxn9sQhG', receiveEmails: true,
    isDarkMode:true, defaultPage:'dashboard',
      dateCreated:Date.now(), isActive:true, uid:regUID, photoURL:'https://firebasestorage.googleapis.com/v0/b/portal-5edd4.appspot.com/o/portalAssets%2Fusericon.png?alt=media&token=89b10027-7d1b-41b6-bc60-21ba138eaaf2' });
      //loading.dismiss();
      this.afs.collection('registrations').doc(regId).update({
      approved: true,approvedBy:this.userItem[0].email,approvedDate:Date.now()
      
    });
    this.auth.logUserEvent("manageregistrations-approvereg",this.regid + '-' + this.userRegEmail,this.userItem[0].uid);
    this.afStore.collection("mail").add({
      to: [userEmail],
      bcc: ['jlewis808@gmail.com'],
      message: {
        subject: 'Registration Complete For Customer Portal.',
        text: 'Name: ' + userFullName + '<br>' + "Email: " + userEmail + '<br>' + "Approved By: " + this.userItem[0].email + '<br>' + '<a href="https://portal-5edd4.web.app">https://portal-5edd4.web.app</a>',
        html: 'Name: ' + userFullName + '<br>' + "Email: " + userEmail + '<br>' + "Approved By: " + this.userItem[0].email + '<br>' + '<a href="https://portal-5edd4.web.app">https://portal-5edd4.web.app</a>'
      }
    });
    this.showToast(userEmail + ' Registered!', 'success');
      this.dismiss();
      this.showUserDetail(regUID)

  //   this.afs.collection("users/" + regId).add({
  //     displayName:userFullName,email:userEmail, companyId:userCompany, role:userCompany,isAdmin:userIsAdmin,emailVerified:true,      
  //     dateCreated:Date.now(),photoURL:'', isActive:true, uid:regId
  // })
  // .then(docRef => {
    // this.afs.collection('users').doc(docRef.id).update({
    //   uid: docRef.id      
      
    // });
    // loading.dismiss();
    // this.dismiss();
    // this.showUserDetail(docRef.id)
      ////console.log("Document written with ID: ", docRef.id);
      //this.getUsers();
  //})
 // .catch(error => console.error("Error adding document: ", error)) 
// loading.dismiss();
    //return await loading.present();
  }

  async showUserDetail(uid:any) {
    //this.auth.logUserEvent("manageuserdetail",uid,this.userItem[0].uid);
    //console.log(uid);
    const modal = await this.modalCtrl.create({
      component: ManageUsersDetailPage,
      id: 'manageuserdetail',
      canDismiss : true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: { uid: uid},
      cssClass: 'user-detail-modal',
    });
    await modal.present();
    
    const { data } = await modal.onWillDismiss();
    if (data) {
      //this.refreshData(this.defaultWC);
      if ( data[0]['isSearch'] === 1 ){  //this means the fromjobsearch was passed
        //this.adminSearch();
      } else {
    
              }  
          }  
      }

  async alertDeleteReg(){
    const alert = await this.alertCtrl.create({
      header: 'Delete Registration Record for: ' + this.userRegFullName + ' ?',
      message: 'Are you sure you want to delete the record?',
      buttons: [
        'No',
        {
          text: 'Yes',
          handler: (data: any) => {
            this.deleteRegistration();
          }
        }
      ],
    });
    // now present the alert on top of all other content
    await alert.present();
  }

  deleteRegistration(){
    this.afStore.collection("registrations").doc(this.regid).delete().then(() => {
      this.auth.logUserEvent("manageregistrations-deletereg",this.userRegEmail + '-' + this.regid,this.userItem[0].uid);
      //console.log("Document successfully deleted!");
      this.dismiss('regdeleted');
  }).catch((error) => {
      console.error("Error removing document: ", error);
      this.dismiss('regdelete error');
  });

  }

  createRegEntry(email:any,phone:any,company:any,fullName:any, title:any){
    //const uid = 'LfmqW5SSNkUb9K8fxtKY'; // this.userItem[0].uid;
    //const logRef = this.afStore.doc('user-log/');
    this.afStore.collection("registrations").add({
      email: email, fullName:fullName,phone:phone,company:company,title:title,regDate:Date.now(),approved:false,approvedBy:'',approvedDate:'',regId:''
  })
  .then(docRef => {
      //console.log("Document written with ID: ", docRef.id);
      this.auth.logUserEvent("manageregistrations-createreg",this.userRegEmail + '-' + this.regid + '-' + docRef.id,this.userItem[0].uid);      
     // //console.log("You can now also access this. as expected: ", this.foo)     
     this.afStore.collection('regId').doc(docRef.id).update({
      id: docRef.id
    });
  })
  .catch(error => console.error("Error adding document: ", error))

    //this.userId  = this.userItem[0].uid;
    // set() for destructive updates
    //logRef.set({ logtype: logtype,message:message,datetime:Date.now(),uid:uid});

  }

  showToast(message:string, color:string) {
    this.myToast = this.toastCtrl.create({
      message: message,
      color: color,
      duration: 4000
    }).then((toastData) => {
      ////console.log(toastData);
      toastData.present();
    });
  }
  HideToast() {   
    this.myToast = this.toastCtrl.dismiss();
  }

  dismiss(data?: any) {
    // using the injected ModalController this page
    // can "dismiss" itself and pass back data
    this.modalCtrl.dismiss(this.regid);
    //this.refreshFromQF();
  
  }

}
