import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroGuard } from './guards/intro.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';
import { DownloadsPage } from './pages/downloads/downloads.page';
import { LoginPage } from './pages/login/login.page';
import { LogoutPage } from './pages/logout/logout.page';
import { AccountholdPage } from './pages/accounthold/accounthold.page';
import { OrderTrackerPage } from './pages/order-tracker/order-tracker.page';
import { FieldServiceRequestPage } from './pages/field-service-request/field-service-request.page';
const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  // { path: '**', component: LoginPage },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
  //   canLoad: [IntroGuard, AutoLoginGuard] // Check if we should show the introduction or forward to inside
  // },
  {
    path: 'login',
    component:LoginPage,
    canLoad: [IntroGuard, AutoLoginGuard] // Check if we should show the introduction or forward to inside
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('./pages/admin-dashboard/admin-dashboard.module').then( m => m.AdminDashboardPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all admin pages
  },
  {
    path: 'checklists',
    loadChildren: () => import('./pages/checklists/checklists.module').then( m => m.ChecklistsPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'files',
    loadChildren: () => import('./pages/files/files.module').then( m => m.FilesPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'devices',
    loadChildren: () => import('./pages/devices/devices.module').then( m => m.DevicesPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./pages/messages/messages.module').then( m => m.MessagesPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'scanner',
    loadChildren: () => import('./pages/scanner/scanner.module').then( m => m.ScannerPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'user-info',
    loadChildren: () => import('./pages/user-info/user-info.module').then( m => m.UserInfoPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./pages/verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  },
  {
    path: 'videos',
    loadChildren: () => import('./pages/videos/videos.module').then( m => m.VideosPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'homepage',
    loadChildren: () => import('./pages/homepage/homepage.module').then( m => m.HomepagePageModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'files',
    loadChildren: () => import('./pages/files/files.module').then( m => m.FilesPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'rma',
    loadChildren: () => import('./pages/rma/rma.module').then( m => m.RmaPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'checklist-view',
    loadChildren: () => import('./pages/checklist-view/checklist-view.module').then( m => m.ChecklistViewPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'public-download-manager',
    loadChildren: () => import('./pages/public-download-manager/public-download-manager.module').then( m => m.PublicDownloadManagerPageModule),
    canLoad: [AuthGuard,AdminGuard, AutoLoginGuard] // Secure all child pages
  },
  {
    path: 'public-downloads',
    loadChildren: () => import('./pages/public-downloads/public-downloads.module').then( m => m.PublicDownloadsPageModule)
  },
  {
    path: 'manage-users',
    loadChildren: () => import('./pages/manage-users/manage-users.module').then( m => m.ManageUsersPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'manage-companies',
    loadChildren: () => import('./pages/manage-companies/manage-companies.module').then( m => m.ManageCompaniesPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  // {
  //   path: 'video-dump',
  //   loadChildren: () => import('./pages/video-dump/video-dump.module').then( m => m.VideoDumpPageModule)
  // },
  {
    path: 'admin-orders',
    loadChildren: () => import('./pages/admin-orders/admin-orders.module').then( m => m.AdminOrdersPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'admin-order-detail',
    loadChildren: () => import('./pages/admin-order-detail/admin-order-detail.module').then( m => m.AdminOrderDetailPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'admin-logs',
    loadChildren: () => import('./pages/admin-logs/admin-logs.module').then( m => m.AdminLogsPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'admin-update',
    loadChildren: () => import('./pages/admin-update/admin-update.module').then( m => m.AdminUpdatePageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./pages/order-detail/order-detail.module').then( m => m.OrderDetailPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'manage-messages',
    loadChildren: () => import('./pages/manage-messages/manage-messages.module').then( m => m.ManageMessagesPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'manage-products',
    loadChildren: () => import('./pages/manage-products/manage-products.module').then( m => m.ManageProductsPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'manage-downloads',
    loadChildren: () => import('./pages/manage-downloads/manage-downloads.module').then( m => m.ManageDownloadsPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'help-request',
    loadChildren: () => import('./pages/help-request/help-request.module').then( m => m.HelpRequestPageModule)
  },
  {
    path: 'manage-users-detail',
    loadChildren: () => import('./pages/manage-users-detail/manage-users-detail.module').then( m => m.ManageUsersDetailPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'manage-products-detail',
    loadChildren: () => import('./pages/manage-products-detail/manage-products-detail.module').then( m => m.ManageProductsDetailPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'manage-companies-detail',
    loadChildren: () => import('./pages/manage-companies-detail/manage-companies-detail.module').then( m => m.ManageCompaniesDetailPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'manage-downloads-detail',
    loadChildren: () => import('./pages/manage-downloads-detail/manage-downloads-detail.module').then( m => m.ManageDownloadsDetailPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'manage-reg-detail',
    loadChildren: () => import('./pages/manage-reg-detail/manage-reg-detail.module').then( m => m.ManageRegDetailPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'field-service-request',
    component:FieldServiceRequestPage,
    //loadChildren: () => import('./pages/field-service-request/field-service-request.module').then( m => m.FieldServiceRequestPageModule),
    //canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'order-tracker',
   component:OrderTrackerPage
    // loadChildren: () => import('./pages/order-tracker/order-tracker.module').then( m => m.OrderTrackerPageModule)
  },
  {
    path: 'product-eula',
    loadChildren: () => import('./pages/product-eula/product-eula.module').then( m => m.ProductEulaPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'manage-product-tag',
    loadChildren: () => import('./pages/manage-product-tag/manage-product-tag.module').then( m => m.ManageProductTagPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'manage-app',
    loadChildren: () => import('./pages/manage-app/manage-app.module').then( m => m.ManageAppPageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  {
    path: 'manage-file',
    loadChildren: () => import('./pages/manage-file/manage-file.module').then( m => m.ManageFilePageModule),
    canLoad: [AdminGuard, AuthGuard] // Secure all child pages
  },
  { path: 'downloads/:fileId', component: DownloadsPage  },
  {
    path: 'accounthold',
    //loadChildren: () => import('./pages/accounthold/accounthold.module').then( m => m.AccountholdPageModule)
    component:AccountholdPage
    // ,    canLoad: [AdminGuard] // Secure all child pages
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'updates',
    loadChildren: () => import('./pages/updates/updates.module').then( m => m.UpdatesPageModule),
    canLoad: [AuthGuard] // Secure all child pages
  },
  {
    path: 'checklist-manager',
    loadChildren: () => import('./pages/checklist-manager/checklist-manager.module').then( m => m.ChecklistManagerPageModule)
  },
  {
    path: 'checklist-manager-detail',
    loadChildren: () => import('./pages/checklist-manager-detail/checklist-manager-detail.module').then( m => m.ChecklistManagerDetailPageModule)
  },
  {
    path: 'manage-roles-access',
    loadChildren: () => import('./pages/manage-roles-access/manage-roles-access.module').then( m => m.ManageRolesAccessPageModule)
  },  
  {
    path: 'checklist-manager-imghandler',
    loadChildren: () => import('./pages/checklist-manager-imghandler/checklist-manager-imghandler.module').then( m => m.ChecklistManagerImghandlerPageModule)
  },
  {
    path: 'user-img-handler',
    loadChildren: () => import('./pages/user-img-handler/user-img-handler.module').then( m => m.UserImgHandlerPageModule)
  },
  {
    path: 'company-img-handler',
    loadChildren: () => import('./pages/company-img-handler/company-img-handler.module').then( m => m.CompanyImgHandlerPageModule)
  },
  {
    path: 'user-info-control',
    loadChildren: () => import('./pages/user-info-control/user-info-control.module').then( m => m.UserInfoControlPageModule)
  },
  // {
  //   path: 'logout',
  //   loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule)
  // },
  {
    path: 'logout',
    component:LogoutPage,
    canLoad: [IntroGuard, AuthGuard] // Check if we should show the introduction or forward to inside
  },
  {
    path: 'admin-file-manager',
    loadChildren: () => import('./pages/admin-file-manager/admin-file-manager.module').then( m => m.AdminFileManagerPageModule)
  },  {
    path: 'admin-devices',
    loadChildren: () => import('./pages/admin-devices/admin-devices.module').then( m => m.AdminDevicesPageModule)
  },
  {
    path: 'admin-devices-view',
    loadChildren: () => import('./pages/admin-devices-view/admin-devices-view.module').then( m => m.AdminDevicesViewPageModule)
  },
  {
    path: 'admin-devices-maps',
    loadChildren: () => import('./pages/admin-devices-maps/admin-devices-maps.module').then( m => m.AdminDevicesMapsPageModule)
  },
  {
    path: 'admin-devices-register',
    loadChildren: () => import('./pages/admin-devices-register/admin-devices-register.module').then( m => m.AdminDevicesRegisterPageModule)
  },
  {
    path: 'admin-devices-logs',
    loadChildren: () => import('./pages/admin-devices-logs/admin-devices-logs.module').then( m => m.AdminDevicesLogsPageModule)
  },
  {
    path: 'admin-devices-manage',
    loadChildren: () => import('./pages/admin-devices-manage/admin-devices-manage.module').then( m => m.AdminDevicesManagePageModule)
  },
  {
    path: 'time-keeper',
    loadChildren: () => import('./pages/time-keeper/time-keeper.module').then( m => m.TimeKeeperPageModule)
  },
  {
    path: 'time-keeper-admin',
    loadChildren: () => import('./pages/time-keeper-admin/time-keeper-admin.module').then( m => m.TimeKeeperAdminPageModule)
  },
  {
    path: 'time-keeper-wtl',
    loadChildren: () => import('./pages/time-keeper-wtl/time-keeper-wtl.module').then( m => m.TimeKeeperWtlPageModule)
  },
  {
    path: 'admin-inventory',
    loadChildren: () => import('./pages/admin-inventory/admin-inventory.module').then( m => m.AdminInventoryPageModule)
  },
  {
    path: 'customer-inventory',
    loadChildren: () => import('./pages/customer-inventory/customer-inventory.module').then( m => m.CustomerInventoryPageModule)
  },
  {
    path: 'customer-fleet',
    loadChildren: () => import('./pages/customer-fleet/customer-fleet.module').then( m => m.CustomerFleetPageModule)
  },
  {
    path: 'admin-fleet-management',
    loadChildren: () => import('./pages/admin-fleet-management/admin-fleet-management.module').then( m => m.AdminFleetManagementPageModule)
  },



  // {
  //   path: 'downloads',
  //   loadChildren: () => import('./pages/downloads/downloads.module').then( m => m.DownloadsPageModule)
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
