import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { IonTabs,IonSegment, IonTabButton, ToastController, IonInput,ModalController,AlertController  } from '@ionic/angular'
import { SettingsService } from 'src/app/services/settings.service';
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { IonicSelectableComponent } from 'ionic-selectable';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-manage-messages',
  templateUrl: './manage-messages.page.html',
  styleUrls: ['./manage-messages.page.scss'],
})
export class ManageMessagesPage implements OnInit {
  
  private myToast: any;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('sort') sortMain: MatSort;
  userItem: any;
  displayedMessagesColumns = ['function','date', 'from', 'to', 'message' ];
  dataSourceMessages: MatTableDataSource<any>;
  selectedUser:any;
  selectedUserID:any;
  selectedUsersUpdate:Array<any> = [];
  selectedUsers:Array<any> = [];
  userList:any;
  sendEmailWithFile:boolean = true;
  testEmailToField:any;
  emailSubject:any;
  emailBody:any;
  uploadUrlInfo:any = null;
editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '200px',
      maxHeight: '400px',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: this.uploadUrlInfo,
    //uploadHtmlEditor: (file: File) => { "" },
    //upload: (file: File) => { return this.uploadEULA(file); },
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
};
  @ViewChild('portComponent') portComponent: IonicSelectableComponent;
  constructor(private afs: AngularFirestore, private afStorage: AngularFireStorage,
    public modalCtrl: ModalController,    
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    //public routerOutlet: IonRouterOutlet,
    private _liveAnnouncer: LiveAnnouncer,
    private storage: AngularFireStorage,
    public auth: AuthenticationService) { }

  ngOnInit() {
    this.userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
    this.getMessages();
    this.getUsers();
  }

  getMessages(){
    // this.afs
    // .collection('messages', messageRef => messageRef
    // .orderBy('date','desc'))
    // .valueChanges()
    // //.pipe(take(1))
    // .subscribe(messageInfo => {
    //   this.dataSourceMessages = new MatTableDataSource<any>(messageInfo);    
    // this.dataSourceMessages.paginator = this.paginator;
    //   this.dataSourceMessages.sort = this.sortMain;
    // });
  
    this.afs
    .collection('users', ref => ref    
    )
    .valueChanges()
    //.pipe(take(1))
    .subscribe(users => {
         
      this.afs
      .collection('messages', messageRef => messageRef
     .orderBy('date','desc'))
     .valueChanges()
       //.pipe(take(1))
       .subscribe(messages => {
        //this.dataSourceUsers = new MatTableDataSource<any>(users); 
    const mergeById = (array1, array2) =>
    array1.map(itm => ({
      ...array2.find((item) => (item.uid === itm.to) && item),
      ...itm
    }));

        let result = mergeById(messages, users)        
        //console.log(result);
      
        //result.sort((a,b)=>a.fileDate > b.fileDate)
            result.sort(function(x, y){
              return  y.date - x.date;
          })
//console.log(result);
        this.dataSourceMessages = new MatTableDataSource<any>(result);
        this.dataSourceMessages.paginator = this.paginator;
        this.dataSourceMessages.sort = this.sortMain;
      });
    });
  }

  getUsers(){
    this.afs
    .collection('users', compRef => compRef .where('isActive', '==', true).orderBy('email'))
    .valueChanges()
    //.pipe(take(1))
    .subscribe(userInfo => {
      //get the companies first
      let uinfo:any;
      uinfo = userInfo;
      this.userList = uinfo
      //console.log(this.companyList);
    });
  
  }

  setSelectedUser(event: any){ 
    var userId = event['value']['uid'];       
    this.selectedUserID = userId;
    //console.log(this.selectedUserID);
  }
  confirm(){
    // this.availableCompanies.forEach(company => this.selectedCompanies.push({'id': company.id, 'companyName': company.companyName}) ) ;{
  
    // };
    this.portComponent.confirm();
    //console.log(this.selectedCompaniesUpdate);
    this.selectedUsersUpdate.forEach(user => this.selectedUsers.push({'id':user.uid,'email':user.email}) ) ;
   //this.updateSelectedCompanies();
    this.portComponent.close();
  }
  
  toggleItems() {
    this.portComponent.toggleItems(this.portComponent.itemsToConfirm.length ? false : true);
   // this.confirm();
  }



  applyFilterMessages(filterValue: any) {
    filterValue = filterValue.value.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSourceMessages.filter = filterValue;
  }
  
  
     /** Announce the change in sort state for assistive technology. */
     announceSortChange(sortState: Sort) {
      if (sortState.direction) {
        this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
      } else {
        this._liveAnnouncer.announce('Sorting cleared');
      }
    }

    async alertDeleteMessage(id:any, to:any){
      
      const alert = await this.alertCtrl.create({
        header: 'Delete Message To :' + to + '?',
        message: 'Are you sure you want to delete this message?',
        buttons: [
          'No',
          {
            text: 'Yes',
            handler: (data: any) => {
              this.deleteMessage(id,to);
            }
          }
        ],
      });
      // now present the alert on top of all other content
      await alert.present();
    }
    deleteMessage(id:any, to:any) {
      this.afs.collection('messages').doc(id).delete().then(ref=>{
        this.getMessages();
        this.showToast('Message To: ' + to + ' Deleted','success');
      }    
      );
    }

    clearMessage(id:any) {
      this.afs.collection('messages').doc(id).update({
        read:true
      }).then(ref=>{
        this.getMessages();
      }    
      );
    }

    unMessage(id:any) {
      this.afs.collection('messages').doc(id).update({
        read:false
      }).then(ref=>{
        this.getMessages();
      }    
      );
    }

    async sendTestMailAlert(){
      const alert = await this.alertCtrl.create({
        header: 'Send Test Email To :' + this.testEmailToField + ' ?',
        message: 'Are you sure you want to send this email?',
        buttons: [
          'No',
          {
            text: 'Yes',
            handler: (data: any) => {
              this.sendTestMail();
            }
          }
        ],
      });
      // now present the alert on top of all other content
      await alert.present();
    }
  
    sendTestMail() {
      this.afs.collection("mail").add({
        to: [this.testEmailToField],
        message: {
          subject: this.emailSubject,
          text: this.emailBody,
          html: this.emailBody
        }
      })
      .then(docRef => {
        //this.auth.addPortalMessage(this.selectedUserID,this.userItem[0].email,fileUpload.file.name + ' has been uploaded to your portal files by:' + this.userItem[0].email ,this.userItem[0].uid);
      
      })
      .catch(error => console.error("Error adding document: ", error))    
      this.showToast(this.emailSubject + '-' + ' EMAIL SENT','success') ;
        
    }

    async sendMailAlert(){   
      var emailList = [];
      this.selectedUsers.forEach(user => {
        emailList.push(user.email);        
              })
              if (this.sendEmailWithFile) {
                const alert = await this.alertCtrl.create({
                  header: 'Do you want to send emails to the selected user(s)' + ' ?',
                  message: 'The following user(s) will get emailed:' +  emailList.join(",") + ' ?',
                  buttons: [
                    'Cancel',
                    {
                      text: 'OK',
                      handler: (data: any) => {
                        this.sendMessage();
                      }
                    },
                  ],
                });
                // now present the alert on top of all other content
                await alert.present();
              } else {
                const alert = await this.alertCtrl.create({
                  header: 'Do you want to send only messages to the selected user(s)' + ' ?',
                  message: 'The following user(s) will get a message, but not an email:' +  emailList.join(",") + ' ?',
                  buttons: [
                    'Cancel',
                    {
                      text: 'OK',
                      handler: (data: any) => {
                        this.sendMessage();
                      }
                    },
                  ],
                });
                // now present the alert on top of all other content
                await alert.present();
              }
     
    }

    sendMessage(){
      this.selectedUsers.forEach(user => {
        //console.log(user);

        if (this.sendEmailWithFile) {
          this.afs.collection("mail").add({
            to: [user.email],
            message: {
              subject: this.emailSubject,
              text: this.emailBody,
              html: this.emailBody
            }
          })
          .then(docRef => {
            this.auth.addPortalMessage(user.id,this.userItem[0].email,this.emailSubject + ' :' + this.emailBody ,this.userItem[0].uid);
          
          })
          .catch(error => console.error("Error adding document: ", error))    
        } else {
          this.auth.addPortalMessage(user.id,this.userItem[0].email,this.emailSubject + ' :' + this.emailBody ,this.userItem[0].uid);
        }
        
      })
    }
  
   
    sendProductNotification() {
     
      this.selectedUsers.forEach(user => {
//console.log(user);
      })

    //   this.sendMailUsersArray = [];
    //   this.selectedCompanies.forEach(company=> {this.sendMailUsersArray.push(company.companyId)})
      
  
    //   this.afs
    //   .collection('users', ref => ref
    //   .where('companyId', 'in', this.sendMailUsersArray) 
    //   .where('isActive', '==', true)
    //   )
    // .valueChanges()
    // .pipe(take(1))
    // .subscribe(usersToGetEmails => {
    //   //console.log(usersToGetEmails);
    //   var userEmailArray:Array<any> = usersToGetEmails
    //   userEmailArray.forEach(userEmail=> {
    //     var currentEmail = userEmail.email;
    //     this.afStore.collection("mail").add({
    //       to: [currentEmail],
    //       message: {
    //         subject: this.emailSubject,
    //         text: this.emailBody,
    //         html: this.emailBody
    //       }
    //     })
    //     .then(docRef => {      
    //       this.auth.addPortalMessage(userEmail.uid,this.userItem[0].email,this.emailSubject + 'updated by:' + this.userItem[0].email + '. Go To the Products Page to download.',this.userItem[0].uid);
    //     })
    //     .catch(error => console.error("Error adding document: ", error));     
          
    //   });
    //   this.showToast('Mail Queued','success') ;
  
    // });  
    
    }
    
    showToast(message:string, color:string) {
      this.myToast = this.toastCtrl.create({
        message: message,
        color: color,
        duration: 4000
      }).then((toastData) => {
        ////console.log(toastData);
        toastData.present();
      });
    }
    HideToast() {
      this.myToast = this.toastCtrl.dismiss();
    }

}
