import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ManageRegDetailPage } from './manage-reg-detail.page';

const routes: Routes = [
  {
    path: '',
    component: ManageRegDetailPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManageRegDetailPageRoutingModule {}
