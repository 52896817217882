import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatInputModule} from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {MatCardModule} from '@angular/material/card'; 

import {MatProgressBarModule} from '@angular/material/progress-bar'; 
import { BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { DownloadsPageRoutingModule } from './downloads-routing.module';
import { DownloadsPage } from './downloads.page';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { GuidedTourModule } from 'ngx-guided-tour';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    MatTableModule,
    MatTabsModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    AngularFireDatabaseModule,
    HttpClientModule,
    DownloadsPageRoutingModule,
    GuidedTourModule
  ],
  declarations: [DownloadsPage],
  entryComponents: [DownloadsPage],
})
export class DownloadsPageModule {}
