import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, OnDestroy,ChangeDetectorRef,HostListener } from '@angular/core';
import { RouterOutlet, Router, ActivationStart,NavigationEnd } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {NavController} from '@ionic/angular';
//import { AppUpdateService } from './services/app-update.service';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { AlertController, IonList, IonRouterOutlet, LoadingController, ModalController, Config,IonToggle } from '@ionic/angular';
import { interval,Subscription,timer } from 'rxjs';
import { switchMap,map, take } from 'rxjs/operators';
// import { UserData } from './providers/user-data';
import { Storage } from '@ionic/storage';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { SettingsService } from './services/settings.service';
import { AuthenticationService } from './services/authentication.service';
import { AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import { UserInfoPage } from './pages/user-info/user-info.page';
import { appConfigData } from './interfaces/appconfigdata';
import { PopoverController } from '@ionic/angular'
import { UserInfoControlPage } from './pages/user-info-control/user-info-control.page';
import { AutologoutService } from './services/autologout.service';
import { appConfigDataIsDarkMode } from './interfaces/appconfigdataIsDarkMode';

import { fromEvent, Subject } from 'rxjs';
import { bufferCount, filter } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  keySequence$ = new Subject<string>();
  keys:any = "";
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    //this.keySequence$.next(event.key);
    if (event.key == "a" || event.key == "b" || event.key == "c" || event.key == "ArrowLeft" || event.key == "ArrowRight" || event.key == "ArrowUp" || event.key == "ArrowDown"  || event.key == "x") {
    this.keys = this.keys + event.key;
    //console.log(this.keys);
      if (this.keys == "abacabb") {
        this.showToastToasty('TOASTY!','transparent');
        this.keys = "";
      }
      if (this.keys == "ArrowUpArrowUpArrowDownArrowDownArrowLeftArrowRightArrowLeftArrowRightba") {
        this.showToastContra('30 Lives!','transparent');
        this.keys = "";
      }
      if (event.key == "x") {
        this.keys = "";
      }
    }
  }

  @ViewChild('mytoggle', {static: true}) mytoggle: IonToggle;
  rootPage:any = DashboardPage;
  selectedTheme: String;
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  appConfigData: {isDarkMode?: boolean, appVersion?:string, defaultUser?: string}[] = [];
  appConfigDataUpdates:{isDarkMode?: boolean, appVersion?:string, defaultUser?: string}[] = [];
  appConfigSub: Subscription;

  appPages = [        
  ];
  
  appPagesAdmin = [
  ];
  pageloadCount:number = 0;
  public appPagesMessages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  private myToast: any;
  subscription: Subscription;
statusText: string;
  loggedIn = false;
  dark:boolean;
  defaultPage:any = 'dashboard';
  appVersion:any;
  updateMessage:any;
  checkInterval:number;
  storedAppVersion:any;
  isAppUpdate:boolean;
  userName: any; 
  userDisplayName: any; 
  companyId:any;
  userItem: any;
  userData:any;
  lastLoginAt:any;
  userId: any; 
  userEmpId: any;
  userCompanyId:any;
  userPhotoUrl:any;
  appPermissions:any
  messages:any;
  showMessages:boolean;
  public isAdmin:boolean = false;
  servname = 'Angular';
  constructor(
    private service: AutologoutService,
    private cdref: ChangeDetectorRef,
    // private route: ActivatedRoute,    
    // private navController: NavController,
    public popoverCtrl: PopoverController,
    //private appUpdateSvc: AppUpdateService,
    public alertCtrl: AlertController,
    private router: Router,
    public loadingCtrl: LoadingController,  
    private toastCtrl: ToastController, 
    //public user: UserData,
    public modalCtrl: ModalController,
    //public routerOutlet: IonRouterOutlet,
    public config: Config,
    private menu: MenuController,
    private platform: Platform,
    private settings: SettingsService,
    public authService: AuthenticationService,
    public afStore: AngularFirestore,
  ) {
    this.servname = service.val; //autologoutservice
    this.initializeApp();
//     if ( this.route.snapshot['_routerState'].url !== '/login') {
//       this.navController.navigateRoot('/login')
//         .then();
//  }
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
       // this.checkLoginStatus();
        //this.getAppPermissions();
        this.isAdmin = this.authService.isAdminUser;
        this.appPagesAdmin = [];
      this.appPages = [];
      this.service.checkCompanyActive();
        this.getAppConfig();
          // Function you want to call here
          this.userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
          this.userData = JSON.parse(localStorage.getItem('user'));
          if (this.userItem === null) {

          } else if (this.userItem.length <= 0) {           

          } else {
            this.createPages();
            setTimeout(() => {
              //this.sortBy('orderId');
             //this.appPages.sort((a,b) => b.orderId - a.orderId);
              //this.appPages.sort((a, b) => a[0]['orderId'] > b[0]['orderId'] ? 1 : a[0]['orderId'] === b[0]['orderId'] ? 0 : -1);
              this.appPagesAdmin.sort((a,b) => a[0].orderId - b[0].orderId); 
              this.appPages.sort((a,b) => a[0].orderId - b[0].orderId); 
              //this.sortRecursive(this.appPages);
      //this.appPagesAdmin.sort((a,b) => a[0].orderId - b[0].orderId); 
          }, 1500);
           // this.appPages.sort((a,b) => b.orderId - a.orderId);   
            //console.log(this.userItem);
            this.userName = this.userItem[0].email;
            //console.log(this.userName);
            this.userDisplayName = this.userItem[0].displayName;
            this.userCompanyId = this.userItem[0].companyId
            //console.log(this.userCompanyId);
            this.userPhotoUrl = this.userItem[0].photoURL
            if (this.userData === null) {

            } else if (this.userData.length <= 0) {           
  
            } else {
              this.lastLoginAt = this.userData.lastLoginAt
              this.getMessages();
            }
            

          };
              
      }
   });
  }

  sortBy(prop: string) {
    this.appPages.sort((a, b) => a['orderId'] > b['orderId'] ? 1 : a['orderId'] === b['orderId'] ? 0 : -1);
  }
  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  openEnd() {
    this.menu.open('end');
  }

  openCustom() {
    this.menu.enable(true, 'custom');
    this.menu.open('custom');
  }

  public sortRecursive(data) {
    if (data[0]) {
      data.forEach( (element) => {
        if (element[0].orderId) {
          element[0].orderId.sort((a, b) =>  a.orderId - b.orderId);
          this.sortRecursive(element[0]);
        }
      });
    }
  }

  ionViewWillEnter() {
    // if (!this.authService.isLoggedIn){
    //   this.menu.enable(false);
    // }
   // this.menu.enable(false);
   }

  ngAfterContentChecked() {
    // if (!this.authService.isLoggedIn){
    //   this.menu.enable(false);
    // }
    //this.cdref.detectChanges();
    
}

@HostListener('window:unload', [ '$event' ])
unloadHandler(event) {
  // ...
  // this.authService.SignOut();
  // localStorage.clear(); //clear app storage
}
@HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event) {
    // ...
    //this.authService.SignOut();
    //localStorage.clear(); //clear app storage
  }

  async ngOnInit() {
   
    this.checkInterval = 3000000;
    this.isAppUpdate = false;
   // this.checkLoginStatus();
    //this.listenForLoginEvents();
    //this.appUpdateSvc.checkForUpdates();    
    this.storedAppVersion = localStorage.getItem('currentAppVersion');
    // if ((/true/i).test(localStorage.getItem('isDarkMode')) === true) {
    //   this.dark = true;
    // } else {this.dark = false;};
    
   // this.userName = localStorage.getItem('username')
   // this.companyId = localStorage.getItem('companyId')
    //const timeInterval$ = interval(10000);    
    //this.getAppConfig();  
    //this.getAppPermissions();
    //this.loadComplete = true;
  //   for(let i=0; i<appPages.products.length; i++){
  //     //console.log(data.products[i].product_desc); //use i instead of 0
  // }

  //this sets all product descriptions to a max length of 10 characters
  this.appPagesAdmin.forEach( (page) => {
  // //console.log("ICON:" + page.icon);
  // this.insertpage(page.icon,true,page.reqCpAdmin,page.reqlogin,page.title,page.url)
    //.product_desc = element.product_desc.substring(0,10);
});
//this.appPages.sort((a,b) => b.orderId - a.orderId);      

  }

    
  ngAfterViewInit(){
      
  }


  createPages(){
    this.appPages = [];
    this.afStore
    .collection('pageControl', ref => ref    
    )
    .valueChanges().pipe(take(1))
    .subscribe(pageData => {
      this.appPagesAdmin = [];
      this.appPages = [];
      pageData.forEach( (page) => {
        const pageItem = [{title: page['title'],url: page['url'],icon: page['icon'],reqlogin: page['reqlogin'],reqCpAdmin: page['reqCpAdmin'],isActive: page['isActive'],id: page['id'], orderId: page['orderId']}]
        //first check to see if the page is even active
        var isActive = page['isActive'];
        var pageTitle = page['title'];
        if (isActive) {         
          //next check to see if it requires admin rights
        if (page['reqCpAdmin'] === true) {
          this.appPagesAdmin.push(pageItem);
        } else {
          //finally push regular pages
          
          this.afStore
          .collection('companyAccess', ref => ref
          .where('companyId', '==', this.userCompanyId)
          )
          .valueChanges().pipe(take(1))
          .subscribe(CompanyAccess => {   
            var updatesPage = CompanyAccess[0]['updates'];
            var checklistsPage = CompanyAccess[0]['checklists'];
            var ordersPage = CompanyAccess[0]['orders'];
            var devicesPage = CompanyAccess[0]['devices'];
            var dashboardPage = CompanyAccess[0]['dashboard'];
            var productsPage = CompanyAccess[0]['products'];
            var messagesPage = CompanyAccess[0]['messages'];
            var filesPage = CompanyAccess[0]['files'];
            var rmasPage = CompanyAccess[0]['rmas'];
            var videosPage = CompanyAccess[0]['videos'];
            var timekeeperPage = CompanyAccess[0]['timeKeeperAccess'];
            var timekeeperWTLPage = CompanyAccess[0]['timeKeeperWTLAccess'];

            if (pageTitle.toLowerCase() == 'updates' && updatesPage == true) {
              this.appPages.push(pageItem);
            }
            if (pageTitle.toLowerCase() == 'checklists' && checklistsPage == true) {
              this.appPages.push(pageItem);
            }
            if (pageTitle.toLowerCase() == 'orders' && ordersPage == true) {
              this.appPages.push(pageItem);
            }
            if (pageTitle.toLowerCase() == 'devices' && devicesPage == true) {
              this.appPages.push(pageItem);
            }
            if (pageTitle.toLowerCase() == 'dashboard' && dashboardPage == true) {
              this.appPages.push(pageItem);
            }
            if (pageTitle.toLowerCase() == 'products' && productsPage == true) {
              this.appPages.push(pageItem);
            }
            if (pageTitle.toLowerCase() == 'messages' && messagesPage == true) {
              this.appPages.push(pageItem);
            }
            if (pageTitle.toLowerCase() == 'files' && filesPage == true) {
              this.appPages.push(pageItem);
            }
            if (pageTitle.toLowerCase() == 'rmas' && rmasPage == true) {
              this.appPages.push(pageItem);
            }
            if (pageTitle.toLowerCase() == 'videos' && videosPage == true) {
              this.appPages.push(pageItem);
            }
            if (pageTitle.toLowerCase() == 'timekeeper' && timekeeperPage == true) {
              this.appPages.push(pageItem);
            }
            if (pageTitle.toLowerCase() == 'timekeeperwtl' && timekeeperWTLPage == true) {
              this.appPages.push(pageItem);
            }

          // if (CompanyAccess[0][pageTitle].toLowerCase() == true) {
          //   this.appPages.push(pageItem);
          // } else {
            
          // }   
          // if (this.getCompanyPageAccess(pageTitle.toLowerCase())){
          //   this.appPages.push(pageItem);
          // }
        }
        
        ////
        );
        }
       // this.appPages.sort((a,b) => b[0].orderId - a[0].orderId);
       
      };
      
      })
      this.appPages.sort((a,b) => b[0].orderId - a[0].orderId);

      //const array = typeof data !== 'object' ? JSON.parse(data) : data;
   
         //this.appPages.sort((a,b) => a.orderId - b.orderId);
     
      this.appPages.sort((a,b) => b[0].orderId - a[0].orderId);
      this.appPagesAdmin.sort((a,b) => a[0].orderId - b[0].orderId);    
    });
   
    // this.appPages.sort((a,b) => a.orderId - b.orderId); //.sort((a,b) => a.orderId - b.orderId);   
    // let arrayData =  this.appPages.map(function(x) {
    //   return {
    //          orderId:x.orderId,
    //          id:x.id,           
    //          title : x.title,
    //          url : x.url,
    //          isActive : x.isActive,
    //          icon : x.icon,
    //          reqCpAdmin : x.reqCpAdmin,
    //          reqlogin: x.reqlogin
    //         }
    //    });
    //    this.appPages = arrayData;
    //    this.appPages.sort((a,b) => a.orderId - b.orderId);
   

    //console.log(this.appPages);
}


getMessages(){
  this.afStore
  .collection('messages', messageRef => messageRef .where('to', '==', this.userItem[0].uid)
  .where('read', '==', false)
  .orderBy('date','desc'))
  .valueChanges()
  //.pipe(take(1))
  .subscribe(messageInfo => {
    if (messageInfo.length>0){
    this.showMessages = true;
    let minfo:any;
    minfo = messageInfo;
    this.messages = minfo;
    } else {
this.showMessages = false;
    }      
    
  });

}

clearMessage(id:any) {
  this.afStore.collection('messages').doc(id).update({
    read:true
  }).then(ref=>{
    this.getMessages();
  }    
  );
}


  getCompanyPageAccess(pageName:any): Boolean{
    var hasAccess:Boolean;

    this.afStore
    .collection('companyAccess', ref => ref
    .where('companyId', '==', this.userCompanyId)
    )
    .valueChanges().pipe(take(1))
    .subscribe(CompanyAccess => {   
    if (CompanyAccess[0][pageName] == true) {
      hasAccess = true;
    } else {
      hasAccess = false;
    }   
    return hasAccess;
 
    });
    return hasAccess;
  }


  insertpage(icon:any,isActive:any,reqCpAdmin:any,reqlogin:any,title:any,url:any){
    //const uid = 'LfmqW5SSNkUb9K8fxtKY'; // this.userItem[0].uid;
    //const logRef = this.afStore.doc('user-log/');
   
    this.afStore.collection("pageControl").add({
      icon: icon,isActive:isActive,reqCpAdmin:reqCpAdmin,reqlogin:reqlogin,title:title,url:url
  })
  .then(docRef => {
      //console.log("Document written with ID: ", docRef.id);
     // //console.log("You can now also access this. as expected: ", this.foo)     
     this.afStore.collection('pageControl').doc(docRef.id).update({
      id: docRef.id      
    });
  })
  .catch(error => console.error("Error adding document: ", error))

    //this.userId  = this.userItem[0].uid;
    // set() for destructive updates
    //logRef.set({ logtype: logtype,message:message,datetime:Date.now(),uid:uid});

  }

  initializeApp() {   
    if ((/true/i).test(localStorage.getItem('isDarkMode')) === true) {
      this.dark = true;
    } 
    // else if ((/true/i).test(localStorage.getItem('isDarkMode')) === false) {
    //   this.dark = false;
    // }
    else {this.dark = true;};
    
    this.settings.getActiveTheme().subscribe(val => this.selectedTheme = val);
    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      //this.splashScreen.hide();
     });
  }

  // setUserStatus(e){   
  //   this.pageloadCountUser = this.pageloadCountUser + 1;
  //   if (this.pageloadCountUser > 1) {
  //     const ischecked: boolean =  e.currentTarget.checked; 
  //   this.afStore.collection('users').doc(this.uid).update({
  //     isActive: ischecked        
  //   });   
  //   this.showToast('Active Status Updated!', 'success');
  //   this.auth.logUserEvent('manageuserdetail-status', this.uid + ' active status changed to: ' + this.userActive + '.',this.uid);
  // }
  // }

  // setAppConfigDM(event){   
  //   //this.pageloadCount = this.pageloadCount + 1;
  //   //if (this.pageloadCount >= 1) {
  //     const ischecked: boolean =  event.currentTarget.checked; 
  //    // var isdark = event.detail.checked;
  //     this.dark = ischecked;
  //     if (localStorage.getItem('isDarkMode') === "undefined" || localStorage.getItem('isDarkMode') === null) { //check to see if the local storage has ever been set
  //       //if it hasn't been set, set darkmode
  //       localStorage.setItem('isDarkMode', String(this.appConfigData[0]['isDarkMode']));
  
  //     } 
  //     else { //check to see if it has changed from local storage
  //       if ((/true/i).test(localStorage.getItem('isDarkMode')) != this.dark) { //check to see if local version is not equal to ngmodel of this.dark
  //         //if not the same, update local and server version
  //         localStorage.setItem('isDarkMode', String(this.dark));
  
  //       } 
  //       else {
  //         //do nothing
  //       }
  //  // }
   
  //   }

  // }

  getDarkMode(){
       if (((localStorage.getItem('isDarkMode') === "undefined" || localStorage.getItem('isDarkMode') === null) && (this.userItem === "undefined" || this.userItem === null) )) { //check to see if the local storage has ever been set
      //if it hasn't been set, set darkmode
      localStorage.setItem('isDarkMode', String(true));
      //then update database
      // this.afStore.collection('users').doc(this.userItem[0].uid).update({
      //   isDarkMode:this.dark,defaultPage:this.defaultPage
      // });
  } 
 else if (((localStorage.getItem('isDarkMode') === "undefined" || localStorage.getItem('isDarkMode') === null) && (this.userItem != "undefined" || this.userItem != null ))) { //check to see if the local storage has ever been set
    //if it hasn't been set, set darkmode
    localStorage.setItem('isDarkMode', String(true));
    //then update database
    this.afStore.collection('users').doc(this.userItem[0].uid).update({
      isDarkMode:this.dark,defaultPage:this.defaultPage
    });
}
  else {
    //it already sits on local storage, so get the config from the db
    if (this.userItem === "undefined" || this.userItem === null) {

    } else {

 
    this.afStore
    .collection('users', ref => ref .where('uid','==',this.userItem[0].uid)
    )
    .valueChanges()
    //.pipe(take(1))
    .subscribe(userData => {
      this.dark = userData[0]['isDarkMode']
      localStorage.setItem('isDarkMode', String(this.dark));
    });
  }
}
  }

  setAppConfigDM(val:boolean){    

   // const toggle = this.mytoggle.checked;
//    toggle = !toggle;
this.afStore.collection('users').doc(this.userItem[0].uid).update({
  isDarkMode:val,defaultPage:this.defaultPage
});
localStorage.setItem('isDarkMode', String(val));
this.getDarkMode();
this.showToast('Theme Set!',"success");
//     this.afStore
//     .collection('users', ref => ref .where('uid','==',this.userItem[0].uid)
//     )
//     .valueChanges().pipe(take(1))
//     .subscribe(userData => {
//       this.dark = userData[0]['isDarkMode']
// if (userData[0]['isDarkMode'] && this.dark)
//       {

//       }
//       else if (userData[0]['isDarkMode'] && !this.dark)
//       {
//       }
//       else {
//         this.afStore.collection('users').doc(this.userItem[0].uid).update({
//           isDarkMode:this.dark,defaultPage:this.defaultPage
//         });
//         localStorage.setItem('isDarkMode', String(this.dark));
//         this.showToast('Theme Set!',"success");
//       }
//     })

  //   if (localStorage.getItem('isDarkMode') === "undefined" || localStorage.getItem('isDarkMode') === null) { //check to see if the local storage has ever been set
  //     //if it hasn't been set, set darkmode
  //     localStorage.setItem('isDarkMode', String(this.appConfigData[0]['isDarkMode']));
  //     //then update database
  //     const appConfigData: appConfigDataIsDarkMode = {
  //       isDarkMode: this.dark,
  //       defaultPage: this.defaultPage,
        
  //     };
    
  
  //   this.afStore.collection('users').doc(this.userItem[0].uid).update({
  //     isDarkMode:this.dark,defaultPage:this.defaultPage
  //   });
 
  //         this.showToast('Theme Set!',"success");
  // }
  // else { //check to see if it has changed from local storage
  //   if ((/true/i).test(localStorage.getItem('isDarkMode')) == this.dark) { //check to see if local version is not equal to ngmodel of this.dark
  //     //if not the same, update local and server version
  //     localStorage.setItem('isDarkMode', String(this.dark));

  //     const appConfigData: appConfigDataIsDarkMode = {
  //       isDarkMode: this.dark,
  //       defaultPage: this.defaultPage,
        
  //     };
  //     this.afStore.collection('users').doc(this.userItem[0].uid).update({
  //       isDarkMode:this.dark,defaultPage:this.defaultPage
  //     });        
  //         this.showToast('Theme Set!',"success");
  //   }  else if ((/true/i).test(localStorage.getItem('isDarkMode')) != this.dark) { //check to see if local version is not equal to ngmodel of this.dark
  //     //if not the same, update local and server version
  //     localStorage.setItem('isDarkMode', String(this.dark));

  //     const appConfigData: appConfigDataIsDarkMode = {
  //       isDarkMode: this.dark,
  //       defaultPage: this.defaultPage,
        
  //     };
  //     this.afStore.collection('users').doc(this.userItem[0].uid).update({
  //       isDarkMode:this.dark,defaultPage:this.defaultPage
  //     });        
  //         this.showToast('Theme Set!',"success");
  //   } else {
  //     //do nothing
  //   }
  // } 
  }

  // getAppConfig(){
    

  //   if (localStorage.getItem('isDarkMode') === "undefined" || localStorage.getItem('isDarkMode') === null) { //check to see if the local storage has ever been set
  //                 //if it hasn't been set, set darkmode
  //                 localStorage.setItem('isDarkMode', String(this.appConfigData[0]['isDarkMode']));
  //             }
  //      else {
  //       this.dark = (/true/i).test(localStorage.getItem('isDarkMode'));
  //      }
  // }

  getAppConfig(){
    this.appConfigSub = 
    this.afStore
    .collection('appConfig', ref => ref    
    )
    .valueChanges().pipe(take(1))
    .subscribe(data => {
            
            this.appConfigData = data;
           //this.dark = this.appConfigData[0]['isDarkMode'];
            this.appVersion = this.appConfigData[0]['appVersion'];
            this.checkInterval = this.appConfigData[0]['checkInterval'];
            this.updateMessage = this.appConfigData[0]['updateMessage'];
            // if (localStorage.getItem('isDarkMode') === "undefined" || localStorage.getItem('isDarkMode') === null) { //check to see if the local storage has ever been set
            //     //if it hasn't been set, set darkmode
            //     localStorage.setItem('isDarkMode', String(this.appConfigData[0]['isDarkMode']));
            // }
            // else { //check to see if it has changed from local to server
            //   if ((/true/i).test(localStorage.getItem('isDarkMode')) === this.appConfigData[0]['isDarkMode']) { //check to see if local version is not equal to server version
            //     //if not the same, update local from server version
            //     localStorage.setItem('isDarkMode', String(this.appConfigData[0]['isDarkMode']));
            //   } else {
            //     //do nothing
            //   }
            // }
            this.getDarkMode();

            if (localStorage.getItem('currentAppVersion') === "undefined" || localStorage.getItem('currentAppVersion') === null) { //check to see if the local storage has ever been set
              localStorage.setItem('currentAppVersion', this.appConfigData[0]['appVersion']);
             
            } else {
             if (localStorage.getItem('currentAppVersion') === this.appConfigData[0]['appVersion']) { //check to see if local version is not equal to server version
              this.isAppUpdate = false; // do nothing
             } else {
              this.isAppUpdate = true; //mark app as needing reloaded / updated
              this.showToastUpdate('Update Available. Please Refresh!','Update to version:' + this.appVersion + '. ' + this.updateMessage,"tertiary");
             }
            }       

            this.afStore
            .collection('appConfig', ref => ref    
            )
            .valueChanges().pipe(take(1))
            .subscribe(data => {
                    this.appConfigData = data;
                    //this.dark = this.appConfigData[0]['isDarkMode'];
                    this.appVersion = this.appConfigData[0]['appVersion'];
                    this.checkInterval = this.appConfigData[0]['checkInterval'];
                    this.updateMessage = this.appConfigData[0]['updateMessage'];
              if (localStorage.getItem('currentAppVersion') === "undefined" || localStorage.getItem('currentAppVersion') === null) {
                localStorage.setItem('currentAppVersion', this.appConfigData[0]['appVersion']);
              } else {
               if (localStorage.getItem('currentAppVersion') === this.appConfigData[0]['appVersion']) { //check to see if local version is not equal to server version
                this.isAppUpdate = false; // do nothing
               } else {
                this.isAppUpdate = true; //mark app as needing reloaded / updated
                this.showToastUpdate('Update Available. Please Refresh!','Update to version:' + this.appVersion + '. ' + this.updateMessage,"tertiary");
               }
              }           
            });
          });
  }

  
  async presentPopover(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: UserInfoControlPage,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
  //  presentPopover(myEvent) {
  //   let popover = this.popoverCtrl.create(PopoverPage);
  //   popover.present({
  //     ev: myEvent
  //   });
  // }

  viewReleaseNotes() {
    this.router.navigateByUrl('/about')
  }

  reloadComponent() {
    if (localStorage.getItem('currentAppVersion') === "undefined" || localStorage.getItem('currentAppVersion') === null) {
      localStorage.setItem('currentAppVersion', this.appConfigData[0]['appVersion']);        
    } else {
      localStorage.setItem('currentAppVersion', this.appConfigData[0]['appVersion']);
    }
    
    window.location.reload();
    }

    showToast(message:string, color:string) {
      this.myToast = this.toastCtrl.create({
        message: message,
        color: color,
        duration: 4000
      }).then((toastData) => {
        ////console.log(toastData);
        toastData.present();
      });
    }

  showToastUpdate(header:string,message:string, color:string) {
    this.myToast = this.toastCtrl.create({
      header: header,
      message: message,
      color: color,
      duration: 8000,
      //cssClass:'appUpdate',
      translucent:true,
      //min-width: 75%,
      //width: 90%,
      buttons: [
        {
          side: 'start',          
          icon: 'reload-circle',
          text: 'Click Here To Update',
          handler: () => {
            //console.log('update clicked');
            this.reloadComponent();
          }
        }, 
        // {
        //   side: 'end',          
        //   icon: 'document-text',
        //   text: 'Notes',
        //   handler: () => {
        //     //console.log('release notes clicked');
        //     this.viewReleaseNotes();
        //   }
        // },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            //console.log('Cancel clicked');
          }
        }
      ]
    }).then((toastData) => {
      ////console.log(toastData);
      toastData.present();
    });
  }
  HideToast() {   
    this.myToast = this.toastCtrl.dismiss();
  }

  ngOnDestroy() {
    //console.log('unsubscribing all subs');
    this.subscription.unsubscribe();
    // this.appPermissionsSub.unsubscribe();
    // this.pcVersionSub.unsubscribe();
    // this.appConfigSub.unsubscribe();
    // this.appDarkModeSub.unsubscribe();
  }

  showToastToasty(message:string, color:string) {
    this.myToast = this.toastCtrl.create({
      message: message,
      color: color,
      duration: 4000,
      cssClass:'toastwhackamole toastwhackamole-bounce ',
    }).then((toastData) => {
      //console.log(toastData);
      toastData.present();
    });
  }

  showToastContra(message:string, color:string) {
    this.myToast = this.toastCtrl.create({
      message: message,
      color: color,
      duration: 4000,
      cssClass:'toastcontrawhackamole toastcontrawhackamole-bounce ',
    }).then((toastData) => {
      //console.log(toastData);
      toastData.present();
    });
  }
  // async showUserDetail(uid:any) {
  //   //this.auth.logUserEvent("manageuserdetail",uid,this.userItem[0].uid);
  //   //console.log(uid);
  //   const modal = await this.modalCtrl.create({
  //     component: UserInfoPage,
  //     id: 'manageuserdetail',
  //     canDismiss : true,
  //     presentingElement: this.routerOutlet.nativeEl,
  //     componentProps: { uid: uid},
  //     cssClass: 'user-detail-modal',
  //   });
  //   await modal.present();
    
  //   const { data } = await modal.onWillDismiss();
  //   if (data) {
  //     //this.refreshData(this.defaultWC);
  //     if ( data[0]['isSearch'] === 1 ){  //this means the fromjobsearch was passed
  //       //this.adminSearch();
  //     } else {
    
  //             }  
  //         }  
  //     }

}
