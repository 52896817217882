import { NgModule } from '@angular/core';
import { FileSizePipe } from '../filesize.pipe';

@NgModule({
  declarations: [
    /* declare it once, here */
    FileSizePipe
  ],
  exports: [
    /* then export it */
    FileSizePipe
  ]
})
export class SharedModule { }