import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ChecklistManagerDetailPageRoutingModule } from './checklist-manager-detail-routing.module';
import { ChecklistManagerDetailPage } from './checklist-manager-detail.page';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatInputModule} from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule,HttpClientJsonpModule } from '@angular/common/http';
import { IonicSelectableModule } from 'ionic-selectable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule.forRoot(),
    ReactiveFormsModule, MatTableModule,
    MatTabsModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatButtonModule,
    AngularFireDatabaseModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    IonicSelectableModule,
    ChecklistManagerDetailPageRoutingModule
  ],
  declarations: [ChecklistManagerDetailPage]
})
export class ChecklistManagerDetailPageModule {}
