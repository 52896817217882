import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { IonTabs,IonSegment, IonTabButton, ToastController, IonInput,ModalController  } from '@ionic/angular'
import { SettingsService } from 'src/app/services/settings.service';
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserFiles } from '../../interfaces/user-files';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-order-tracker',
  templateUrl: './order-tracker.page.html',
  styleUrls: ['./order-tracker.page.scss'],
})
export class OrderTrackerPage implements OnInit, AfterViewInit {
  @ViewChild('EntTrackingNo', {static: false}) trackingNoInput: { setFocus: () => void; };
  @ViewChild('step1', {static: false}) step1;
  @ViewChild('step2', {static: false}) step2;
  @ViewChild('step3', {static: false}) step3;
  @ViewChild('step4', {static: false}) step4;
  @ViewChild('step5', {static: false}) step5;
  @ViewChild('step6', {static: false}) step6;
  @ViewChild('step7', {static: false}) step7;
  @ViewChild('step8', {static: false}) step8;
  @ViewChild('step9', {static: false}) step9;
  public selectedTrackingNo:any;
  public trackingSearchForm: FormGroup;
  isOrder:any;
  companyOrders:any;
  customerPO:any;
  proNumber:any;
  currentShippingStatus:any;
  currentOrderStatus:any;
  currentCustomerName:any;
  shipmentDate:any;
  orderEntryDate:any
  isOrderEntry:boolean;
  scheduledDate:any;
  isScheduled:boolean;
  configDate:any;
  isConfig:boolean;
  ackDate:any;
  isAck:boolean;
  engDate:any;
  endStatus:any;
  isEng:boolean;
  manufDate:any;
  isManuf:boolean;
  assemblyDate:any;
  isAssembly:boolean;
  cratingDate:any;
  isCrating:boolean;
  shipDate:any;
  isShip:boolean;
  deliveredDate:any;
  isDelivered:boolean;
  progbarval:any;
  progbarcolor:any;
  constructor(formBuilder: FormBuilder,private afs: AngularFirestore, private afStorage: AngularFireStorage,) {
    
      this.trackingSearchForm = formBuilder.group({
        EntTrackingNo: [''],
      });
   }

  ngOnInit() {
  this.isOrder = false;

  this.orderEntryDate = "01/18/2023"
  this.isOrderEntry = false;
  this.scheduledDate = "01/18/2023"
  this.isScheduled = false;
  this.configDate = "01/18/2023"
  this.isConfig  = false;
  this.ackDate = "01/18/2023"
  this.isAck  = false;
  this.engDate = "01/18/2023"
  this.endStatus = "";
  this.isEng  = false;
  this.manufDate = "01/18/2023"
  this.isManuf = false;
  this.assemblyDate = "01/18/2023"
  this.isAssembly = false;
  this.cratingDate = "01/18/2023"
  this.isCrating = false;
  this.shipDate = "01/18/2023"
  this.isShip = false;
  this.deliveredDate = "N/A"
  this.isDelivered = false;
this.progbarval = ".90";
this.progbarcolor = "success"

  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.trackingNoInput.setFocus();
  }, 500);
  }

  scroll(elname: any) {
    const shipEl = document.getElementById(elname);
    setTimeout(() => {
      shipEl.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }, 100);
    
}

  searchOrders(trackingNo:any){
    this.currentCustomerName  = '';
    this.selectedTrackingNo = trackingNo;
    this.afs.collection('orders', ref => ref.where('customerPO', '==', 
    this.selectedTrackingNo).orderBy('orderStatusInt','asc')).valueChanges().subscribe(companyOrders => {
    this.companyOrders = companyOrders;
    if (this.companyOrders.length > 0){
      this.isOrder = true;
      this.proNumber = companyOrders[0]['customerPO'];
      this.shipmentDate = companyOrders[0]['shipmentDate'];
      this.currentOrderStatus = companyOrders[0]['orderStatus'];
      this.currentCustomerName = companyOrders[0]['companyName'];
    }
    else {
      this.isOrder = false;
      this.proNumber = null;
      this.shipmentDate =  null;
      this.currentOrderStatus =  null;
      this.currentCustomerName =  null;
      this.selectedTrackingNo = null;
    }  
    }); 

    setTimeout(() => {
      this.scroll("step9");
  }, 300);
    
  }

  clearSearch(){
    this.selectedTrackingNo = null;
    this.trackingSearchForm.value.EntTrackingNo = null;
    this.trackingSearchForm.patchValue({EntTrackingNo: null});
  }

}
