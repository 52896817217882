import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AccountholdPageRoutingModule } from './accounthold-routing.module';
import { AccountholdPage } from './accounthold.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AccountholdPageRoutingModule
  ],
  declarations: [AccountholdPage]
})
export class AccountholdPageModule {}
