// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyBFPDjceblOgVjMZasoieu47sH9lpEdWEI",
    authDomain: "portal-5edd4.firebaseapp.com",
    projectId: "portal-5edd4",
    storageBucket: "portal-5edd4.appspot.com",
    messagingSenderId: "1068975081279",
    appId: "1:1068975081279:web:308b852f2aba51c003f58a",
    measurementId: "G-4WHBG5VXQE"
  },
  production: false,
  dtechurl: "https://portalapi...com/api/get",
  baseUrl: "http://localhost/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
