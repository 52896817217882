import { Component, ElementRef, NgZone, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { RouterOutlet, Router, ActivationStart } from '@angular/router';
import { IonList, IonRouterOutlet, LoadingController } from '@ionic/angular';
import { Config, ModalController, NavParams, NavController, IonDatetime, ToastController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireList,AngularFireObject } from '@angular/fire/compat/database';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AuthenticationService } from "../../services/authentication.service";
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'; 
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GoogleMap,MapMarker,MapInfoWindow } from '@angular/google-maps';
import { Lightbox,LightboxConfig } from 'ngx-lightbox';
import * as JSZip from 'jszip';  
import * as FileSaver from 'file-saver';  
import { checklist } from 'src/app/interfaces/checklist';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { platform } from 'os';
import { finalize } from 'rxjs/operators';

export class FileUpload {
  key: string;
  name: string;
  url: string;
  file: File;
  constructor(file: File) {
    this.file = file;
  }
}

@Component({
  selector: 'app-checklist-manager-imghandler',
  templateUrl: './checklist-manager-imghandler.page.html',
  styleUrls: ['./checklist-manager-imghandler.page.scss'],
})
export class ChecklistManagerImghandlerPage implements OnInit {
  selectedImage:any;
  selectedCheckList:any;

  percentage = 0;
percentageBar = 0;
currentFileUpload?: FileUpload;
selectedFiles?: FileList;
public uid: any;
userName: any; 
userItem: any;
private myToast: any;


  constructor(private afs: AngularFirestore, public afdb: AngularFireDatabase,  private afStorage: AngularFireStorage,
    private storage: AngularFireStorage,
    public modalCtrl: ModalController,
    public toastCtrl: ToastController,
    public auth: AuthenticationService,) { }

  ngOnInit() {
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  upload(comcheckimage:any): void {
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      this.selectedFiles = undefined;

      if (file) {
        this.currentFileUpload = new FileUpload(file);
        this.pushFileToStorage(this.currentFileUpload,comcheckimage).subscribe(
          percentage => {
            this.percentage = Math.round(percentage ? percentage : 0);
            this.percentageBar = (this.percentage * 0.01);
          },
          error => {
            //console.log(error);
          }
        );
      }
    }
  }

pushFileToStorage(fileUpload: FileUpload,comcheckimage:any): Observable<number> {
  var basePath = '/commcheckimages/';
  const filePath = `${basePath}/${this.selectedCheckList + '_' + comcheckimage + '.jpg'}`;
  const storageRef = this.storage.ref(filePath);
  const uploadTask = this.storage.upload(filePath, fileUpload.file);
  uploadTask.snapshotChanges().pipe(
    finalize(() => {
      storageRef.getDownloadURL().subscribe(downloadURL => {
        fileUpload.url = downloadURL;
        fileUpload.name = fileUpload.file.name;
        this.saveFileData(fileUpload,comcheckimage,downloadURL);
        this.auth.logUserEvent('managechecklist-comcheckimgupload', 'checklistid:' + this.selectedCheckList + ' - ' + downloadURL  + ' uploaded by ' + this.userItem[0].email + '.',this.userItem[0].uid);
        this.percentageBar = 0;
        this.percentage = 0;
        // if(this.sendEmailWithFile){this.sendUserEmail(this.userEmail,fileUpload.file.name + ' added to your portal files.',
        // fileUpload.file.name + ' has been uploaded to your portal files by:' + this.userItem[0].email + '<br>' + '<a href="https://portal-5edd4.web.app">Login to the portal to retrieve the file</a>'
        // )}
       
      });
    })
  ).subscribe();
  return uploadTask.percentageChanges();
}

saveFileData(fileUpload:FileUpload,comcheckimage:any, url:any){
  let ref = this.afdb.database.ref('checklists/' + this.selectedCheckList);
  if (comcheckimage =="comcheckpic1a") {ref.update({comcheckpic1a: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic1b") {ref.update({comcheckpic1b: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic1c") {ref.update({comcheckpic1c: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic2a") {ref.update({comcheckpic2a: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic2b") {ref.update({comcheckpic2b: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic2c") {ref.update({comcheckpic2c: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic3a") {ref.update({comcheckpic3a: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic3b") {ref.update({comcheckpic3b: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic3c") {ref.update({comcheckpic3c: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic4a") {ref.update({comcheckpic4a: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic4b") {ref.update({comcheckpic4b: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic4c") {ref.update({comcheckpic4c: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic5a") {ref.update({comcheckpic5a: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic5b") {ref.update({comcheckpic5b: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic5c") {ref.update({comcheckpic5c: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic6a") {ref.update({comcheckpic6a: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic6b") {ref.update({comcheckpic6b: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic6c") {ref.update({comcheckpic6c: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic7a") {ref.update({comcheckpic7a: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic7b") {ref.update({comcheckpic7b: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic7c") {ref.update({comcheckpic7c: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic8a") {ref.update({comcheckpic8a: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic8b") {ref.update({comcheckpic8b: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic8c") {ref.update({comcheckpic8c: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic9a") {ref.update({comcheckpic9a: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic9b") {ref.update({comcheckpic9b: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic9c") {ref.update({comcheckpic9c: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic9aa") {ref.update({comcheckpic9aa: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic9ab") {ref.update({comcheckpic9ab: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic9ac") {ref.update({comcheckpic9ac: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic10a") {ref.update({comcheckpic10a: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic10b") {ref.update({comcheckpic10b: url}).catch(error => console.error("Error adding document: ", error))}
  if (comcheckimage =="comcheckpic10c") {ref.update({comcheckpic10c: url}).catch(error => console.error("Error adding document: ", error))}
  this.percentageBar = 0;
  this.percentage = 0;
  this.showToast(comcheckimage + ' uploaded!', 'success')
  setTimeout(() => {
    this.dismiss();
    // this.guidedTourService.startTour(this.dashboardTour);
 }, 1000);
}

showToast(message:string, color:string) {
  this.myToast = this.toastCtrl.create({
    message: message,
    color: color,
    duration: 4000
  }).then((toastData) => {
    ////console.log(toastData);
    toastData.present();
  });
}
HideToast() {   
  this.myToast = this.toastCtrl.dismiss();
}

dismiss(data?: any) {
  // using the injected ModalController this page
  // can "dismiss" itself and pass back data
  this.modalCtrl.dismiss(this.selectedCheckList);
  //this.refreshFromQF();

}

}
