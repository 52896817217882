import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatInputModule} from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { IonicSelectableModule } from 'ionic-selectable';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { ManageUsersDetailPageRoutingModule } from './manage-users-detail-routing.module';
import { ManageUsersDetailPage } from './manage-users-detail.page';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule.forRoot(),
    IonicSelectableModule,
    MatTableModule,
    MatTabsModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatButtonModule,
    AngularFireDatabaseModule,
    HttpClientModule,
    ManageUsersDetailPageRoutingModule
  ],
  declarations: [ManageUsersDetailPage]
})
export class ManageUsersDetailPageModule {}
