import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { IonTabs,IonSegment, IonTabButton, ToastController, IonInput,ModalController,LoadingController, IonRouterOutlet  } from '@ionic/angular'
import { SettingsService } from 'src/app/services/settings.service';
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserFiles } from '../../interfaces/user-files';
import { Observable, Subscription } from 'rxjs';
import { ManageUsersDetailPage } from '../manage-users-detail/manage-users-detail.page';
import { ManageRegDetailPage } from '../manage-reg-detail/manage-reg-detail.page';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { switchMap,map, take } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

export class FileUpload {
  key: string;
  name: string;
  url: string;
  file: File;
  constructor(file: File) {
    this.file = file;
  }
}

@Component({
  selector: 'app-field-service-request',
  templateUrl: './field-service-request.page.html',
  styleUrls: ['./field-service-request.page.scss'],
})


export class FieldServiceRequestPage implements OnInit, PipeTransform {
  public newFSRequestForm: FormGroup;
@Pipe({ name: 'safe' })
userItem: any;
public selectedUser:any;

companyId:any;
public myToast:any;
public isAdmin:boolean = false;
percentage = 0;
percentageBar = 0;
currentFileUpload?: FileUpload;
selectedFiles?: FileList;
url:any = 'https://portalapp/fs-request-flow/';
onsitevirtual:Array<any> = [];
isOnSite:any;
isOnSiteVal:any;
  constructor(private domSanitizer: DomSanitizer,
    private afs: AngularFirestore, private afStorage: AngularFireStorage,
    formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    public routerOutlet: IonRouterOutlet,
    public loadingCtrl: LoadingController,  
    private toastCtrl: ToastController, 
    private _liveAnnouncer: LiveAnnouncer,
    private storage: AngularFireStorage,    
    private settings: SettingsService,
    public authService: AuthenticationService,
    public auth: AuthenticationService
    ) {
      this.newFSRequestForm = formBuilder.group({
        personSubmitName: ['', Validators.required],      
        personSubmitCompany: ['', Validators.required],
        personSubmitPhone: ['', Validators.required],
        personSubmitEmail: ['', Validators.required],
        personSubmitPO: ['', Validators.required],
        personSubmitOnSiteOrVirtual: ['', Validators.required],
        virtualContactName: ['', Validators.required],
        virtualContactCompany: ['', Validators.required],
        virtualContactPhone: ['', Validators.required],
        virtualContactEmail: ['', Validators.required],
        virtualContactServiceDates: ['', Validators.required],
        virtualContactStartTime: ['', Validators.required],
        virtualContactVideoPlatform: ['', Validators.required],
        virtualContactProjectReferenceName: ['', Validators.required],
        onSiteContactAddress: ['', Validators.required],
        onSiteContactAddressLine2: ['', Validators.required],
        onSiteContactCity: ['', Validators.required],
        onSiteContactState: ['', Validators.required],
        onSiteContactZip: ['', Validators.required],
        onSiteContactCountry: ['', Validators.required],
        onSiteContactGPSCoords: ['', Validators.required],
        onSiteContactName: ['', Validators.required],
        onSiteContactCompany: ['', Validators.required],
        onSiteContactPhone: ['', Validators.required],
        onSiteContactEmail: ['', Validators.required],
        onSiteContactServiceDates: ['', Validators.required],
        onSiteContactStartTime: ['', Validators.required],
        onSiteContactLiftBucketOption: ['', Validators.required],
        onSiteContactPPERequirements: ['', Validators.required],
        onSiteContactTrainingReqs: ['', Validators.required],
        onSiteContactProjectReferenceName: ['', Validators.required],
        equipmentSelectionSwitch: ['', Validators.required],
        equipmentSelectionMotorOperator: ['', Validators.required],
        equipmentSelectionLineScope: ['', Validators.required],
        equipmentSelectionSwitchCPSN: ['', Validators.required],
        equipmentSelectionSwitchCPStyle: ['', Validators.required],
        equipmentSelectionSwitchkVRating: ['', Validators.required],
        equipmentSelectionSwitchAmperage: ['', Validators.required],
        equipmentSelectionSwitchMountingOrientation: ['', Validators.required],
        equipmentSelectionSwitchOperatorType: ['', Validators.required],
        equipmentSelectionSwitchQuantity: ['', Validators.required],
        equipmentSelectionMotorCPSN: ['', Validators.required],
        equipmentSelectionMotorCPStyle: ['', Validators.required],
        equipmentSelectionMotorIsDifferentVendor: ['', Validators.required],
        equipmentSelectionMotorVendorSwitchManufacturer: ['', Validators.required],
        equipmentSelectionMotorVendorSwitchProductType: ['', Validators.required],
        equipmentSelectionMotorVendorSwitchkVRating: ['', Validators.required],
        equipmentSelectionMotorVendorSwitchAmperage: ['', Validators.required],
        equipmentSelectionLineScopeCPOrder: ['', Validators.required],
        equipmentSelectionLineScopeType: ['', Validators.required],
        equipmentSelectionLineScopeLineToLinekVRating: ['', Validators.required],
        equipmentSelectionLineScopeConductorSize: ['', Validators.required],
        equipmentSelectionLineScopeConductorOrientation: ['', Validators.required],
        equipmentSelectionLineScopePhaseVerified: ['', Validators.required],
        equipmentSelectionLineScopeFaultDetection: ['', Validators.required],
        equipmentSelectionLineScopeCommHousingEquipment: ['', Validators.required],
        equipmentSelectionLineScopeMount150Feet: ['', Validators.required],
        equipmentSelectionLineScopeEnergizedOnSite: ['', Validators.required],
        equipmentSelectionLineScopeQuantity: ['', Validators.required],
        reviewedScopeOfWorkPolicy: ['', Validators.required],
        
      });
     }

  ngOnInit() {
    this.onsitevirtual.push({'id': 'onsite', 'name': 'On Site'}) 
    this.onsitevirtual.push({'id': 'virtual', 'name': 'Virtual'}) 
    
  }
  
  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  setOnSiteVirtual(e){
    this.isOnSiteVal = this.isOnSite.id
    console.log(this.isOnSite.id);
  }

  submitFSRequest(){

  }
}
