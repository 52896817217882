import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { IonTabs,IonSegment, IonTabButton, ToastController,LoadingController, IonInput,ModalController, IonRouterOutlet, AlertController } from '@ionic/angular'
import { SettingsService } from 'src/app/services/settings.service';
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { arrayRemove,arrayUnion,increment  } from 'firebase/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserFiles } from '../../interfaces/user-files';
import { Observable, Subscription } from 'rxjs';
import { compileFunction } from 'vm';
import { finalize } from 'rxjs/operators';
import { IonicSelectableComponent } from 'ionic-selectable';
import { switchMap,map, take } from 'rxjs/operators';
import { promise } from 'protractor';

@Component({
  selector: 'app-manage-product-tag',
  templateUrl: './manage-product-tag.page.html',
  styleUrls: ['./manage-product-tag.page.scss'],
})
export class ManageProductTagPage implements OnInit {
  tagId:any;
  tagIdArray:Array<any> = [];
  tagName:any;
  tagIsActive:Boolean;
  defaultAllAccess:Boolean;
  userName: any;
  userItem: any;
  userId: any;
  userEmpId: any;
  userCompanyId:any;
  userEmail:any;
  dataSourcecompaniesWithTag: MatTableDataSource<any>;
  dataSourcecompaniesWithTagReg:Array<any> = [];
  dataSourcecompaniesTagCount:Array<any> = [];
  selectedCompaniesUpdate:Array<any> = [];
   selectedCompanies:Array<any> = [];
   availableCompanies:Array<any> = [];
   allCompanies:Array<any> = [];
   allCompaniesAndAccess:Array<any> = [];
pageloadCount:number = 0;
pageloadCountDefaultAccess:number = 0;
   myToast: any;

   tagListAll:any;
tagListCompany:any;
fileTagsCompanyAccess:any;
companyTags:Array<any> = [];
availableTags:Array<any> = [];
private selectedTags:Array<any> = [];

dataSourceCompanyTags: MatTableDataSource<any>;
isLoading = false;
currCompText:any;
  @ViewChild('paginatorCompanyTags') paginatorCompanyTags: MatPaginator;
@ViewChild('sortCompany') sortCompany: MatSort;

displayedCompanyColumns = ['id', 'companyName'];
  constructor(private afs: AngularFirestore, private afStorage: AngularFireStorage,
    public modalCtrl: ModalController,
    private _liveAnnouncer: LiveAnnouncer,
    public alertCtrl: AlertController,
    private routerOutlet: IonRouterOutlet,
    public toastCtrl: ToastController,
    public loadingCtrl: LoadingController, 
    //@Optional() private readonly routerOutlet?: IonRouterOutlet,
    private storage: AngularFireStorage,
    public afStore: AngularFirestore,
    public auth: AuthenticationService,) { }
    @ViewChild('portComponent') portComponent: IonicSelectableComponent;
  ngOnInit() {
    this.tagIdArray.push({'id': this.tagId, 'tagName': this.tagName})
    this.userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
    //this.getCompaniesList();
    this.availableCompanies = [];
    this.getCompaniesWIthTagList();
    this.getCompanyMaxTags();
  }

  ionViewDidEnter(){
    this.pageloadCount = this.pageloadCount + 1;
    this.pageloadCountDefaultAccess = this.pageloadCountDefaultAccess + 1;
  }

  getTagInfo(){

  }

  getCompaniesList(){
    this.afs
    .collection('companies', ref => ref.where('companyName', '!=',
      '').orderBy('companyName','asc'))
    .valueChanges()
    .pipe(take(1))
    .subscribe(companies => {
      this.allCompanies = companies;
      this.allCompanies.forEach(company => this.availableCompanies.push({'id': company.id, 'companyName': company.companyName}) ) ;{
      //   var max = 0;
        
      //   companies.forEach(
          
      //     comp => this.dataSourcecompaniesTagCount.push({'companyId':comp['companyId'],tagTotal:
      //     //comp['fileTags'].length + 1
          
      //     comp['fileTags']?.map(function(ele){ if(ele>max){ 
      //     max= ele.length + 1} else {max=0} })
      //   //comp.reduce((prev, current) => (+prev.fileTags > +current.fileTags) ? prev : current)
       
      //     // comp['fileTags'].map(function(ele){ if(ele>max){ 
      //     //   //         //   max= ele.length} })

      //     // });
      //     //.filter(t=>t.'status' ==='y').length + 1
      //     // .map(function(ele){ if(ele>max){ 
      //     //   max= ele.length} })
      //     //comp.reduce((prev, current) => (+prev.fileTags > +current.fileTags) ? prev : current)
      //   }) ) ;{
      //   };
      // //console.log(this.dataSourcecompaniesTagCount);
      };
  });
}

getCompaniesListOld1(): void {    
  //this.selectedTags = [];
  
  this.afs
    .collection('companies', ref => ref.where('companyName', 'not-in',
      this.dataSourcecompaniesWithTagReg).orderBy('companyName','asc'))
    .valueChanges()
    .pipe(take(1))
    .subscribe(companies => {
      this.allCompanies = companies;
      this.allCompanies.forEach(company => this.availableCompanies.push({'id': company.id, 'companyName': company.companyName}) ) ;{

        this.afs
        .collection('companyAccess', ref => ref
        .where('companyId', '==', companies['companyId'])
        )
          .valueChanges()
          //.pipe(take(1))
          .subscribe(companysAndAccess => { 

          });
      };
    //this.dataSourcePortalFileEULA = new MatTableDataSource<any>(portalfilesEULA);     
    // this.dataSourceFiles.sort = this.applyFilter('');
         
    
  // const mergeById = (array1, array2) =>
  // array1.map(itm => ({
  //   ...array2.find((item) => (item.companyId === itm.companyId) && item),
  //   ...itm
  // }));

  // //let result = mergeById(portalfilesEULA, portalfiles)
  
  // let result = mergeById(companies, companysAndAccess)
  // //result.sort((a,b)=>a.fileDate > b.fileDate)
  //     this.allCompaniesAndAccess = result ;
  //     this.allCompaniesAndAccess.sort(function(x, y){
  //       return  y.companyName - x.companyName;
  //   })
  //     //mergeById(portalfiles, portalfilesEULA) ;// mergeById; //portalfiles.map((fileId, i) => Object.assign({}, fileId, portalfilesEULA[i]));
  //     ////console.log(this.portalfilesmain);

  //     //this.dataSourcePortalFiles = new MatTableDataSource<any>(this.portalfilesmain);
  //     //this.dataSourcePortalFiles.paginator = this.paginator;
  //   });
  });  
 // //console.log(this.afs.collection.name);
}

// quickUpdate(){
//   this.afs
//   .collection('companyAccess', ref => ref).valueChanges()
//   .subscribe(companies => {
//     this.allCompanies = [];
//     this.allCompanies = companies;

//       this.allCompanies.forEach(companyAccess =>
//         //this.availableTags.push({'id': tag.id, 'tagName': tag.tagName})
//         this.afStore.collection('companyAccess').doc(companyAccess.id).update({
//           companyName: companyAccess.companyName
//       //   this.afStore.collection("companyAccess").add({
//       //     checklists:false, companyId:company.id, companySysproId:company.companySysproId, companyName:company.companyName, dashboard:true,devices:false,
//       //     //fileTags:''
//       //     files:true,messages:true,orders:false,products:true,rmas:false,updates:true,videos:false
//       //     //fileDate:Date.now(), fileName: fileUpload.file.name, fileType: fileUpload.file.type, fileUrl: fileUpload.url, uid:this.userItem[0].uid, fileUser: this.userItem[0].email, companyid: this.companyId
//       //     //logtype: logtype,message:message,datetime:Date.now(),uid:uid
//       // })
//       .then(docRef => {
//         // this.afStore.collection('companyAccess').doc(docRef.id).update({
//         //   id: docRef.id
//         //});
//           ////console.log("Document written with ID: ", docRef.id);
//          // //console.log("You can now also access this. as expected: ", this.foo)
//       })
//       .catch(error => console.error("Error adding document: ", error))



//         ) ;{

//       };
// }

getCompanyMaxTags(){

  this.afs
  .collection('companyAccess', ref => ref.where('fileTags', '!=',
    ''))
  .valueChanges()
  .pipe(take(1))
  .subscribe(companies => {   
      var max = 0;      
      companies.forEach(
        comp => this.dataSourcecompaniesTagCount.push({'companyId':comp['companyId'],tagTotal:
        comp['fileTags'].length + 1
        // .map(function(ele){ if(ele>max){ 
        //   max= ele.length} })
        //comp.reduce((prev, current) => (+prev.fileTags > +current.fileTags) ? prev : current)
      }) ) ;{
      };
    //console.log(this.dataSourcecompaniesTagCount);

});

  
}


getCompaniesWIthTagList(){
//   this.afs
//   .collection('companies', ref => ref.where('companyName', '!=',
//     '').orderBy('companyName','asc'))
//   .valueChanges()
//   .subscribe(companies => {
//     this.allCompanies = companies;
//     this.allCompanies.forEach(company => this.availableCompanies.push({'id': company.id, 'companyName': company.companyName}) ) ;{

//     };
// });
this.afs
.collection('companyAccess', ref => ref
.where('fileTags', 'array-contains-any', this.tagIdArray)
)
.valueChanges()
 
.subscribe(fileTagsCompanyAccess => {
  
  this.dataSourcecompaniesWithTag = new MatTableDataSource<any>(fileTagsCompanyAccess);
  this.dataSourcecompaniesWithTag.paginator = this.paginatorCompanyTags;
  this.dataSourcecompaniesWithTag.sort = this.sortCompany;
  var max = 0;
  this.dataSourcecompaniesWithTag.data.forEach(comp => this.dataSourcecompaniesWithTagReg.push(comp.companyName) ) ;{
  };
  this.getCompaniesList();
  //this.selectedTags = [];
  // if (this.tagListCompany[0]['fileTags']){
  // this.tagListCompany[0]['fileTags'].forEach(tag => this.selectedTags.push({'id': tag.id, 'tagName': tag.tagName}) ) ;{
  // };
});

}

confirm(){
  // this.availableCompanies.forEach(company => this.selectedCompanies.push({'id': company.id, 'companyName': company.companyName}) ) ;{

  // };
  this.portComponent.confirm();
  //console.log(this.selectedCompaniesUpdate);
  this.selectedCompaniesUpdate.forEach(company => this.selectedCompanies.push({'id':company.id,'companyName':company.companyName}) ) ;
 this.updateSelectedCompanies();
  this.portComponent.close();
}

toggleItems() {
  this.portComponent.toggleItems(this.portComponent.itemsToConfirm.length ? false : true);
 // this.confirm();
}


    
applyFilterCompanies(filterValue: any) {
  filterValue = filterValue.value.trim(); // Remove whitespace
  filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
  this.dataSourcecompaniesWithTag.filter = filterValue;
}


 /** Announce the change in sort state for assistive technology. */
 announceSortChange(sortState: Sort) {
  if (sortState.direction) {
    this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
  } else {
    this._liveAnnouncer.announce('Sorting cleared');
  }
}

// updateTagOld(){

//   this.afs.collection('portalFileTags').doc(this.tagId).update({
//     tagName:this.tagName
//   }).then(() => {

//   this.afs
//   .collection('companyAccess', ref => ref
//   .where('fileTags', 'array-contains-any', this.tagIdArray)
//   )
//   .valueChanges().pipe(take(1))
//   .subscribe(fileTagsCompanyAccess => {
//     console.log(fileTagsCompanyAccess)
//     fileTagsCompanyAccess.forEach(filetagaccess => {
//       if (filetagaccess['fileTags'].find(item => item.id.toString() === this.tagId)) {
//         console.log(filetagaccess['id'] + '-' + filetagaccess['companySysproId'])
//         var tagToRemove = filetagaccess['fileTags'].find(item => item.id.toString() === this.tagId);
//         var currcompanyTags = []
//         currcompanyTags = this.removeByTagId(filetagaccess['fileTags'],tagToRemove.id);

//         this.afStore.collection('companyAccess').doc(filetagaccess['id'] ).update({
//          fileTags: arrayRemove(tagToRemove )
      
//         });   
        
//         currcompanyTags.push({'id': this.tagId, 'tagName': this.tagName})
      
//                this.afStore.collection('companyAccess').doc(fileTagsCompanyAccess[0]['id']).update({
//             fileTags: currcompanyTags
//                });
//         // this.auth.logUserEvent('manageTagDetail-updatecompanypermissions',this.userItem[0].email + ' updated permissions for -' + companyName + ':'+ companyId + ' : '
//         // + 'Removed Tag: ' + this.tagId + ' with name' + this.tagName, this.userItem[0].uid);
//       }

//     });

//   });
  
// });
// }

// removeByTagId(arr, removingTagId){
//   return arr.filter( a => a.id !== removingTagId).map( e => {
//     return { ...e, children: this.removeByTagId(e.children || [], removingTagId)}
//   });
// }
 

async present() {
  this.isLoading = true;
  return await this.loadingCtrl.create({
    // duration: 5000,
    message: 'Loading...',
  spinner: 'dots',
  }).then(a => {
    a.present().then(() => {
      //console.log('presented');
      if (!this.isLoading) {
        a.dismiss().then(() =>
        // console.log('abort presenting')
        console.log('loading complete')
        );
      }
    });
  });
}

async dismissLoading() {
  this.isLoading = false;
  return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
}


async alertremoveFromAll(){
  const alert = await this.alertCtrl.create({
    header: 'Remove :' + this.tagName + ' From All Companies?',
    message: 'Are you sure you want to DELETE the tag from all companies? ',
    buttons: [
      'No',
      {
        text: 'Yes',
        handler: (data: any) => {
          this.removeFromAll();
        }
      }
    ],
  });
  // now present the alert on top of all other content
  await alert.present();
}

async removeFromAll(){
  this.dataSourcecompaniesWithTag.data.forEach(company => 
    {
      this.currCompText = "Removing..."
      this.afs
      .collection('companyAccess', ref => ref
      .where('companyId', '==', company.companyId)   
      )
      .valueChanges()
      .pipe(take(1))
      .subscribe(fileTagsCompanyAccess => {   
            fileTagsCompanyAccess.forEach(filetagaccess => {
              this.currCompText = "Removing from " + filetagaccess['companyName'] + "...";
              //this.deleteCompanyTag(filetagaccess['id'],filetagaccess['companyName'],filetagaccess['companyId'],filetagaccess['fileTags'])             
              var tagToRemove = filetagaccess['fileTags'].find(item => item.id === this.tagId)

  this.afStore.collection('companyAccess').doc(filetagaccess['id']).update({
   fileTags: arrayRemove(tagToRemove )

    // checklists:this.checklistAccess, dashboard:this.dashboardAccess, devices:this.devicesAccess, files:this.fileAccess, companyFiles:this.companyFileAccess, messages:this.messageAccess,orders:this.orderAccess,
    // products:this.productAccess,rmas:this.rmaAccess,updates:this.updatesAccess,videos:this.videoAccess,fileTags:this.selectedTags
    // fileDate:Date.now(), fileName: this.productFileName , uid:this.userItem[0].uid, fileUser: this.userItem[0].email,
    // fileTags:this.selectedTags, eulaRequired:this.productFileEULAReq, isActive:this.productFileActive, version:this.productFileVersion, fileNotes: this.productFileNotes
  });   
  this.currCompText = "Removing..."
  this.auth.logUserEvent('manageTagDetail-updatecompanypermissions',this.userItem[0].email + ' updated permissions for -' + filetagaccess['companyName'] + ':'+ filetagaccess['companyId'] + ' : '
  + 'Removed Tag: ' + this.tagId + ' with name' + this.tagName, this.userItem[0].uid);
  this.currCompText = "Done..." 
      // if (filetagaccess['fileTags'].find(item => item.id.toString() === this.tagId)) {
      //   console.log(filetagaccess['id'] + '-' + filetagaccess['companySysproId'])

       
      });
    
    });      
    this.currCompText = "Done..." 
    }
   
    ) ;
    this.getCompaniesWIthTagList();
}

async alertaddToAll(){
  const alert = await this.alertCtrl.create({
    header: 'Add :' + this.tagName + ' to All Companies?',
    message: 'Are you sure you want to ADD the tag to all companies? ',
    buttons: [
      'No',
      {
        text: 'Yes',
        handler: (data: any) => {
          this.addToAll();
        }
      }
    ],
  });
  // now present the alert on top of all other content
  await alert.present();
}

async addToAll(){

//this.present();
this.currCompText = "Updating Companies..."

this.afs.collection('portalFileTags').doc(this.tagId).update({
  addToAll:this.userItem[0].uid
 }).then(() => {


//Next - get all companies, then add tag to all companies

  this.afs
  .collection('companies', ref => ref.where('companyName', '!=',
    ''))
  .valueChanges()
  .pipe(take(1))
  .subscribe(companies => {
   
      //first remove all current tags with this ID
  this.dataSourcecompaniesWithTag.data.forEach(company => 
    {
      
      this.afs
      .collection('companyAccess', ref => ref
      .where('companyId', '==', company.companyId)   
      )
      .valueChanges()
      .pipe(take(1))
      .subscribe(fileTagsCompanyAccess => {   
        var tagToRemove = fileTagsCompanyAccess[0]['fileTags'].find(item => item.id === this.tagId)
        this.afStore.collection('companyAccess').doc(fileTagsCompanyAccess[0]['id']).update({
          fileTags: arrayRemove(tagToRemove )
         });   

         
  });

        });
    

    companies.forEach(company => 
      //this.availableCompanies.push({'id': company.id, 'companyName': company.companyName}) 
      {
        this.currCompText = "Updating..."
        this.afs
      .collection('companyAccess', ref => ref
      .where('companyId', '==', company['id'])   
      )
      .valueChanges()
      .pipe(take(1))
      .subscribe(fileTagsCompanyAccess => {   
       
        var currselectedTags = [];     
         var currtagListCompany = [];
     var testArray = fileTagsCompanyAccess[0]['fileTags'];
    
        if (testArray) {
          if (fileTagsCompanyAccess[0]['fileTags']?.length > 0 ){
  
            fileTagsCompanyAccess[0]['fileTags'].forEach(tag => 
              {
                if (tag.id != this.tagId) {
                  currselectedTags.push({'id': tag.id, 'tagName': tag.tagName}) 
                }                           
              }
              
              ) ;{  
    
            };
        } 
       }else {
         
      }
  
               currselectedTags.push({'id': this.tagId, 'tagName': this.tagName})
      this.afStore.collection('companyAccess').doc(fileTagsCompanyAccess[0]['id']).update({
        fileTags: currselectedTags
 
       }).then(() => {
        this.auth.logUserEvent('manageTagDetail-updatecompanypermissions',this.userItem[0].email + ' updated tag name and permissions for -' + fileTagsCompanyAccess[0]['companyName'] + ':'+ fileTagsCompanyAccess[0]['companyId'] + ' : '
       + 'Updated TagName: ' + this.tagId + ' with name' + this.tagName, this.userItem[0].uid);
       this.currCompText = "Updating " + fileTagsCompanyAccess[0]['companyName'] + "..."
      })
         
  });


 
    });
    this.currCompText = "Done Updating." 
  } )
  ;
}).then(() => {
  this.currCompText = "Done Updating ";
  this.getCompaniesWIthTagList();
   this.getCompanyMaxTags();
})

  //     this.afs
  // .collection('companyAccess', ref => ref.where('companyId', '==',
  // company['id']))
  // .valueChanges()
  // .pipe(take(1))
  // .subscribe(thiscompAccess => {
     


  // }
  // ) 
   
}

async updateTag(){ 
  
  this.currCompText = "Updating Companies..."
  
  this.afs
  .collection('portalFiles', ref => ref )
  .valueChanges()
  .pipe(take(1))
  .subscribe(portalFileData => {  
    var portalFileTags:Array<any> = [];
    //portalFileData[0]['fileTags']
 portalFileData.forEach(portalFile => {
 
        var portalFiletagToRemove = portalFile['fileTags'].find(item => item.id === this.tagId)
        
        if (portalFiletagToRemove) {
          var portalFileId = portalFile['fileId'];
          console.log(portalFile['fileTags'], portalFile['fileId'])

      this.afStore.collection('portalFiles').doc(portalFileId).update({
        fileTags: arrayRemove(portalFiletagToRemove )
       });   
  //portalFileTags.push({'id': portalFile[0]['fileTags'].id, 'tagName': tag.tagName})

  this.afs
  .collection('portalFiles', ref => ref
  .where('fileId', '==', portalFileId)   
  )
  .valueChanges()
  .pipe(take(1))
  .subscribe(fileTagsPortalFile => {     
    var currselectedPortalFileTags = [];  
 var testArray = fileTagsPortalFile[0]['fileTags'];
    if (testArray) {
      if (fileTagsPortalFile[0]['fileTags']?.length > 0 ){

        fileTagsPortalFile[0]['fileTags'].forEach(tag => 
          {
            if (tag.id != this.tagId) {
              currselectedPortalFileTags.push({'id': tag.id, 'tagName': tag.tagName}) 
            }                           
          }
          
          ) ;{  

        };
    } 
   }else {
  }    
  currselectedPortalFileTags.push({'id': this.tagId, 'tagName': this.tagName})
 
    //currselectedPortalFileTags
         this.afStore.collection('portalFiles').doc(portalFileId).update({
      fileTags: currselectedPortalFileTags   
     }).then(() => {
      this.auth.logUserEvent('manageTagDetail-product-changetagname',this.userItem[0].email + ' tag name changed for -' +this.tagId+ ':'+ this.tagName + ' : '
     + 'Updated Tag: ' + this.tagId + ' with name' + this.tagName, this.userItem[0].uid);
    })
    
});   

}else {
          
}   
 });
 });




      this.afs.collection('portalFileTags').doc(this.tagId).update({
    tagName:this.tagName
  }).then(() => {
    this.tagIdArray = [];
    this.tagIdArray.push({'id': this.tagId, 'tagName': this.tagName})
 
  const maxItem = Number;

 
this.dataSourcecompaniesWithTag.data.forEach(company => 
  {
    this.currCompText = "Updating..."
    this.afs
    .collection('companyAccess', ref => ref
    .where('companyId', '==', company.companyId)   
    )
    .valueChanges()
    .pipe(take(1))
    .subscribe(fileTagsCompanyAccess => {   
      var tagToRemove = fileTagsCompanyAccess[0]['fileTags'].find(item => item.id === this.tagId)
      this.afStore.collection('companyAccess').doc(fileTagsCompanyAccess[0]['id']).update({
        fileTags: arrayRemove(tagToRemove )
       });   

      var currtagListCompany = fileTagsCompanyAccess;
      var currselectedTags = [];
      currtagListCompany = [];
   var testArray = fileTagsCompanyAccess[0]['fileTags'];
  
      if (testArray) {
        if (fileTagsCompanyAccess[0]['fileTags']?.length > 0 ){

          fileTagsCompanyAccess[0]['fileTags'].forEach(tag => 
            {
              if (tag.id != this.tagId) {
                currselectedTags.push({'id': tag.id, 'tagName': tag.tagName}) 
              }                           
            }
            
            ) ;{  
  
          };
      } 
     }else {
      //currselectedTags.push({'id': this.tagId, 'tagName': this.tagName})
    }
      
    currselectedTags.push({'id': this.tagId, 'tagName': this.tagName})
        
      
     
      //currselectedTags
           this.afStore.collection('companyAccess').doc(fileTagsCompanyAccess[0]['id']).update({
        fileTags: currselectedTags
          //arrayUnion(tagToAdd)
         // checklists:this.checklistAccess, dashboard:this.dashboardAccess, devices:this.devicesAccess, files:this.fileAccess, companyFiles:this.companyFileAccess, messages:this.messageAccess,orders:this.orderAccess,
         // products:this.productAccess,rmas:this.rmaAccess,updates:this.updatesAccess,videos:this.videoAccess,fileTags:this.selectedTags
         // fileDate:Date.now(), fileName: this.productFileName , uid:this.userItem[0].uid, fileUser: this.userItem[0].email,
         // fileTags:this.selectedTags, eulaRequired:this.productFileEULAReq, isActive:this.productFileActive, version:this.productFileVersion, fileNotes: this.productFileNotes
       }).then(() => {
        this.auth.logUserEvent('manageTagDetail-updatecompanypermissions',this.userItem[0].email + ' updated tag name and permissions for -' + company.companyName + ':'+ company.companyId + ' : '
       + 'Updated TagName: ' + this.tagId + ' with name' + this.tagName, this.userItem[0].uid);
       this.currCompText = "Updating " +  company.companyName + "..."
       this.currCompText = "Done Updating." 
      })
  
  });      
  this.currCompText = "" 
  }
 
  ) ;

}).then(() => {
  this.currCompText = "Done Updating " 
  this.getCompaniesWIthTagList();
   this.getCompanyMaxTags();
}
 
);
//this.dismissLoading();
}


async alertDeleteTag(){
  const alert = await this.alertCtrl.create({
    header: 'Delete :' + this.tagName + ' completely?',
    message: 'Are you sure you want to delete the tag ' + this.tagName + ' from the entire system? ',
    buttons: [
      'No',
      {
        text: 'Yes',
        handler: (data: any) => {
          this.completelyDelteTag();
        }
      }
    ],
  });
  // now present the alert on top of all other content
  await alert.present();
}

completelyDelteTag(){
  this.present();
  this.removeFromAll().then(()=> {
    this.afStore.collection('portalFileTags').doc(this.tagId).delete().then(()=> {
      this.auth.logUserEvent('manageTagDetail-deleteTagCompletely',this.userItem[0].email + ' tag deleted from system. ' + 
       'TagID: ' + this.tagId + ' with name' + this.tagName, this.userItem[0].uid);
       this.dismissLoading();
       this.dismiss('tagdeleted');
    }) 
    
     }); 
}

async alertdeleteCompanyTag(id:any, companyId:any, companyName:any, fileTags:any){
  const alert = await this.alertCtrl.create({
    header: 'Delete :' + companyName + ' From Tag?',
    message: 'Are you sure you want to delete the company from access to the Tag? ',
    buttons: [
      'No',
      {
        text: 'Yes',
        handler: (data: any) => {
          this.deleteCompanyTag(id,companyName, companyId, fileTags);
        }
      }
    ],
  });
  // now present the alert on top of all other content
  await alert.present();
}

deleteCompanyTag(id:any,companyName:any, companyId:any, fileTags:any){

  // this.afStore.collection("userfiles").doc(id).delete().then(() => {
  //   var basePath = '/portalUserFiles/' + this.userItem[0].uid;
  //   const filePath = `${basePath}/${name}`;
  //   const storageRef = this.storage.ref(filePath);
  //   storageRef.delete();
  //   this.auth.logUserEvent('filepage-userfiledelete', 'file ' + name + ' deleted by ' + this.userItem[0].email + '.',this.userItem[0].uid);
  // });
  
  var tagToRemove = fileTags.find(item => item.id === this.tagId)

  this.afStore.collection('companyAccess').doc(id).update({
   fileTags: arrayRemove(tagToRemove )

    // checklists:this.checklistAccess, dashboard:this.dashboardAccess, devices:this.devicesAccess, files:this.fileAccess, companyFiles:this.companyFileAccess, messages:this.messageAccess,orders:this.orderAccess,
    // products:this.productAccess,rmas:this.rmaAccess,updates:this.updatesAccess,videos:this.videoAccess,fileTags:this.selectedTags
    // fileDate:Date.now(), fileName: this.productFileName , uid:this.userItem[0].uid, fileUser: this.userItem[0].email,
    // fileTags:this.selectedTags, eulaRequired:this.productFileEULAReq, isActive:this.productFileActive, version:this.productFileVersion, fileNotes: this.productFileNotes
  });   
  this.auth.logUserEvent('manageTagDetail-updatecompanypermissions',this.userItem[0].email + ' updated permissions for -' + companyName + ':'+ companyId + ' : '
  + 'Removed Tag: ' + this.tagId + ' with name' + this.tagName, this.userItem[0].uid);
  this.getCompaniesWIthTagList();
  this.showToast('Permissions Updated!','success');

  // this.afStore.collection("companyfiles").doc(id).delete().then(() => {
  //   var basePath = '/portalCompanyFiles/' + this.companyId;
  //   const filePath = `${basePath}/${name}`;
  //   const storageRef = this.storage.ref(filePath);
  //   storageRef.delete();
  //   this.auth.logUserEvent('managecompanydetail-filedelete', 'file ' + name + ' deleted by ' + this.userItem[0].email + '.',this.userItem[0].uid);
  // });
  // this.showToast(name + ' deleted!', 'success')
//dataSourceFiles
}

 // private getCompanyTagAccess(): void {

    // this.afs
    // .collection('portalFileTags', ref => ref
    // )
    // .valueChanges()
    // .subscribe(fileTags => {
    //   this.tagListAll = fileTags;
    //   this.availableTags = [];
    //     this.tagListAll.forEach(tag => this.availableTags.push({'id': tag.id, 'tagName': tag.tagName}) ) ;{

    //     };
    // //  this.tagListAll = fileTags;
    // //   this.tagListAll.forEach(tag => this.allTags.push({'id': tag.id, 'tagName': tag.tagName}) ) ;{
    // //   };
    //   //this.dataSourceTags = new MatTableDataSource<any>(fileTags);

    //   this.afs
    //   .collection('companyAccess', ref => ref
    //   .where('companyId', '==', this.companyId)
    //   )
    //   .valueChanges()
    //   .subscribe(fileTagsCompanyAccess => {
    //     this.tagListCompany = fileTagsCompanyAccess;

    //     this.selectedTags = [];
    //     if (this.tagListCompany[0]['fileTags']){
    //     this.tagListCompany[0]['fileTags'].forEach(tag => this.selectedTags.push({'id': tag.id, 'tagName': tag.tagName}) ) ;{
    //     };
    //   }

    //    this.dataSourceCompanyTags = new MatTableDataSource<any>(this.availableTags);
    //    this.dataSourceCompanyTags.paginator = this.paginatorTags;
    //    this.dataSourceCompanyTags.sort = this.matSortUsers;

    //   });

    // });

   //}

   selectedCompaniesClose(event:any){
    
    //console.log(event);
    //console.log(this.selectedCompaniesUpdate);
    this.selectedCompaniesUpdate.forEach(company => this.selectedCompanies.push({'id':company.id,'companyName':company.companyName}) ) ;
   this.updateSelectedCompanies();
  }

  // getCompanyTagAccess(companyId:any): Promise<any> {  
  //   var retpromise =  Promise<any>;
  //   this.afs
  //   .collection('portalFileTags', ref => ref
  //   )
  //   .valueChanges()
  //   //.pipe(take(1))
  //   .subscribe(fileTags => {   
  //     this.tagListAll = fileTags;    
  //     this.availableTags = [];
  //       this.tagListAll.forEach(tag => this.availableTags.push({'id': tag.id, 'tagName': tag.tagName}) ) ;{        
             
  //       };
  //   //  this.tagListAll = fileTags;    
  //   //   this.tagListAll.forEach(tag => this.allTags.push({'id': tag.id, 'tagName': tag.tagName}) ) ;{        
  //   //   };
  //     //this.dataSourceTags = new MatTableDataSource<any>(fileTags); 
   
  //     var promise = new Promise((resolve,reject)=>{  this.afs
  //     .collection('companyAccess', ref => ref
  //     .where('companyId', '==', companyId)   
  //     )
  //     .valueChanges()
  //     //.pipe(take(1))
  //     .subscribe(fileTagsCompanyAccess => {   
  //       this.tagListCompany = fileTagsCompanyAccess;
  
  //       this.selectedTags = [];
  //       if (this.tagListCompany[0]['fileTags']){
  //       this.tagListCompany[0]['fileTags'].forEach(tag => this.selectedTags.push({'id': tag.id, 'tagName': tag.tagName}) ) ;{        
  //       };
  //     }  
  //      this.dataSourceCompanyTags = new MatTableDataSource<any>(this.availableTags);       
       
  //     }) })
  //     var retpromise = new Promise((resolve,reject)=>{ promise; });
  //     ;
   
  //   });
  //  // return retpromise;
  //  }

   updateSelectedCompanies(){ 
    
    const maxItem = Number;
      //regions: arrayUnion("greater_virginia")
    // this.availableCompanies.push({'id': company.id, 'companyName': company.companyName}) 
   // var tagToRemove = fileTags.find(item => item.id === this.tagId)
//this.getCompanyTagAccess(company.companyId).then(() => {
  this.selectedCompaniesUpdate.forEach(company => 
    {
      
      this.afs
      .collection('companyAccess', ref => ref
      .where('companyId', '==', company.id)   
      )
      .valueChanges()
      .pipe(take(1))
      .subscribe(fileTagsCompanyAccess => {   
        var currtagListCompany = fileTagsCompanyAccess;
        var currselectedTags = [];
        currtagListCompany = [];
     var testArray = fileTagsCompanyAccess[0]['fileTags'];
    
        if (testArray) {
          if (fileTagsCompanyAccess[0]['fileTags']?.length > 0 ){

            fileTagsCompanyAccess[0]['fileTags'].forEach(tag => 
              {
                if (tag.id != this.tagId) {
                  currselectedTags.push({'id': tag.id, 'tagName': tag.tagName}) 
                }                           
              }
              
              ) ;{  
    
            };
        } 
       }else {
        //currselectedTags.push({'id': this.tagId, 'tagName': this.tagName})
      }
        
      currselectedTags.push({'id': this.tagId, 'tagName': this.tagName})
          
        
       
        //currselectedTags
             this.afStore.collection('companyAccess').doc(fileTagsCompanyAccess[0]['id']).update({
          fileTags: currselectedTags
            //arrayUnion(tagToAdd)
           // checklists:this.checklistAccess, dashboard:this.dashboardAccess, devices:this.devicesAccess, files:this.fileAccess, companyFiles:this.companyFileAccess, messages:this.messageAccess,orders:this.orderAccess,
           // products:this.productAccess,rmas:this.rmaAccess,updates:this.updatesAccess,videos:this.videoAccess,fileTags:this.selectedTags
           // fileDate:Date.now(), fileName: this.productFileName , uid:this.userItem[0].uid, fileUser: this.userItem[0].email,
           // fileTags:this.selectedTags, eulaRequired:this.productFileEULAReq, isActive:this.productFileActive, version:this.productFileVersion, fileNotes: this.productFileNotes
         }).then(() => {
          this.auth.logUserEvent('manageTagDetail-updatecompanypermissions',this.userItem[0].email + ' updated permissions for -' + company.companyName + ':'+ company.companyId + ' : '
         + 'Added Tag: ' + this.tagId + ' with name' + this.tagName, this.userItem[0].uid);
        })
        this.getCompaniesWIthTagList();
    });      
     
    }
   
    ) ;

    this.getCompaniesWIthTagList();
  
  }

  UpdateAllCompaniesTags(){

  }

  RemoveTagsFromCompanies(){

  }


  updateTagStatus(e){
    this.pageloadCount = this.pageloadCount + 1;
if (this.pageloadCount > 1) {
  const ischecked: boolean =  e.currentTarget.checked;
  this.afs.collection('portalFileTags').doc(this.tagId).update({
    isActive:ischecked
  }).then(() => {
    this.auth.logUserEvent('manageTagDetail-tagStatus',this.userItem[0].email + ' updated tag status for -' + this.tagName + ':'+ this.tagId + ' : '
   + 'Marked IsActive: ' + ischecked, this.userItem[0].uid);
  });
  this.showToast('Tag Status Updated!','success');
}

  }

  updateAllAccess(e){
    this.pageloadCountDefaultAccess = this.pageloadCountDefaultAccess + 1;
if (this.pageloadCountDefaultAccess > 1) {
  const ischecked: boolean =  e.currentTarget.checked;
  this.afs.collection('portalFileTags').doc(this.tagId).update({
    defaultAllAccess:ischecked
  }).then(() => {
    this.auth.logUserEvent('manageTagDetail-defaultAllAccess',this.userItem[0].email + ' updated all company access status for -' + this.tagName + ':'+ this.tagId + ' : '
   + 'Marked AllAccess: ' + ischecked, this.userItem[0].uid);
  });
  this.showToast('Tag Default Access Updated!','success');
}

  }
 

  showToast(message:string, color:string) {
    this.myToast = this.toastCtrl.create({
      message: message,
      color: color,
      duration: 4000
    }).then((toastData) => {
      ////console.log(toastData);
      toastData.present();
    });
  }
  HideToast() {
    this.myToast = this.toastCtrl.dismiss();
  }


  dismiss(data?: any) {
    // using the injected ModalController this page
    // can "dismiss" itself and pass back data
    this.modalCtrl.dismiss(this.tagId);
    //this.refreshFromQF();
//this.dismissLoading();
  }

}
