import { Injectable } from "@angular/core";
import { Storage } from '@capacitor/storage';
import { Router } from '@angular/router';
import { INTRO_KEY } from 'src/app/guards/intro.guard';
import { AuthenticationService } from "./authentication.service";
import { AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import { ToastController  } from '@ionic/angular'
import { LocationStrategy } from "@angular/common";
const MINUTES_UNITL_AUTO_LOGOUT = 10 // in mins
const CHECK_INTERVAL = 3000 // in ms
const STORE_KEY =  'lastAction';

@Injectable({
  providedIn: 'root'
})
export class AutologoutService {
  private myToast: any;
  val: any;
  autoLogoutInterval:600000;
  appConfigData:any;
  public getLastAction() {
     return parseInt(localStorage.getItem(STORE_KEY));
   }
  public setLastAction(lastAction: number) {
     localStorage.setItem(STORE_KEY, lastAction.toString());
   }
 
   constructor(private router: Router, public auth:AuthenticationService,public afStore: AngularFirestore,private locationStrategy: LocationStrategy,public toastCtrl: ToastController,) {
     this.check();
     this.initListener();
     this.initInterval();
     this.getAppConfig();
     localStorage.setItem(STORE_KEY,Date.now().toString());
    // console.log(this.autoLogoutInterval);
   }

   getAppConfig(){
    this.afStore
      .collection('appConfig', ref => ref    
      )
      .valueChanges()
      //.pipe(take(1))
      .subscribe(data => {
              this.appConfigData = data;            
              this.autoLogoutInterval= this.appConfigData[0]['autoLogoutInterval'];
      });
  }

  checkCompanyActive(){     
    if (this.router.url === '/login') {

    } else if (this.router.url === '/logout') {

    }  else if (this.router.url === '/register') {

    } else if (this.router.url === '/field-service-request') {
    
    } else if (this.router.url === '/reset-password') {

    } else if (this.router.url === '/verify-email') {

    } else if (this.router.url === '/order-tracker') {

    }
    else {
      const  userItem = JSON.parse(localStorage.getItem('userItem'));
      if (userItem == "undefined" || userItem == null) {
      } else {
               this.afStore
           .collection('companies', ref => ref
           .where('id', '==', userItem[0]['companyId'])
           )
           .valueChanges()
           //.pipe(take(1))
           .subscribe(companyData => {
            var isActive = companyData[0]['isActive'];
             if (isActive == true){                      
                
             }
              else {
               //localStorage.setItem('compActive', String(false)); 
               //localStorage.setItem('userItem', JSON.stringify(userItem));
               this.showToast('Company InActive, You will be logged out...','danger');
               this.auth.logUserEvent('userkicked',userItem[0]['email'] + ' user kicked out, company inactive : ' + Date.now(),userItem[0]['uid']);
               localStorage.clear(); //clear app storage
               setTimeout(() => {
                //this.router.navigate(['login']);
                this.router.navigateByUrl('/logout', { replaceUrl:true });
                setTimeout(() => {          
                  window.location.reload();
                  
              }, 500);
            }, 500);  
             }            
         })
       }
    }
     
  }
 
   initListener() {
     document.body.addEventListener('click', () => this.reset());
     document.body.addEventListener('mouseover',()=> this.reset());
     document.body.addEventListener('mouseout',() => this.reset());
     document.body.addEventListener('keydown',() => this.reset());
     document.body.addEventListener('keyup',() => this.reset());
     document.body.addEventListener('keypress',() => this.reset());
      window.addEventListener("storage",() => this.storageEvt());
 
   }
 
   reset() {
 
    // console.log('date got by using events',Date.now());
     this.setLastAction(Date.now());
    
    
    // console.log('store key',localStorage.getItem(STORE_KEY));
 
   }
 
   initInterval() {
     setInterval(() => {
       this.check();
     }, CHECK_INTERVAL);
   }
 
   check() {
     const now = Date.now();
     const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 3000;
     const diff = timeleft - now;
     //console.log('difference',diff)
     const isTimeout = diff < 0;
 
     if (isTimeout)  {
      //console.log('CURRENTROUTE');
      //console.log(this.router.url);
      if (this.router.url === '/login' || this.router.url === '/order-tracker' || this.router.url === '/field-service-request') {
        //console.log('Currently on Login Page');
      } else {
       // console.log('Not On Login Page');
        //console.log(this.router.url);     
        //this.router.navigate(['./login']);
        this.auth.SignOut();
        //window.location.reload();
        //this.router.navigateByUrl('/login', { replaceUrl:true });      
    //     setTimeout(() => {          
    //       this.router.navigateByUrl('/logout', { replaceUrl:true });   
    //      //window.location.reload();
    //  }, 500);
      }
       
     } else {
      this.checkCompanyActive();
     }
   }
   storageEvt(){
   console.log("storage");
   this.val = localStorage.getItem(STORE_KEY);
 }

 showToast(message:string, color:string) {
  this.myToast = this.toastCtrl.create({
    message: message,
    color: color,
    duration: 4000
  }).then((toastData) => {
    ////console.log(toastData);
    toastData.present();
  });
}
HideToast() {   
  this.myToast = this.toastCtrl.dismiss();
}
 }