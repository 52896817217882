import { Component, ElementRef, NgZone, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { RouterOutlet, Router, ActivationStart } from '@angular/router';
import { IonList, IonRouterOutlet, LoadingController } from '@ionic/angular';
import { Config, ModalController, NavParams, NavController, IonDatetime, ToastController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireList,AngularFireObject } from '@angular/fire/compat/database';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AuthenticationService } from "../../services/authentication.service";
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'; 
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as JSZip from 'jszip';  
import * as FileSaver from 'file-saver';  
import { checklist } from 'src/app/interfaces/checklist';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { platform } from 'os';
import { finalize } from 'rxjs/operators';


export class FileUpload {
  key: string;
  name: string;
  url: string;
  file: File;
  constructor(file: File) {
    this.file = file;
  }
}

@Component({
  selector: 'app-user-img-handler',
  templateUrl: './user-img-handler.page.html',
  styleUrls: ['./user-img-handler.page.scss'],
})
export class UserImgHandlerPage implements OnInit {
  selectedUser:any;
  selectedUserID:any;
  selectedCheckList:any;
  percentage = 0;
percentageBar = 0;
currentFileUpload?: FileUpload;
selectedFiles?: FileList;
public uid: any;
userName: any; 
userItem: any;
private myToast: any;

constructor(private afs: AngularFirestore, private afStorage: AngularFireStorage,
  private storage: AngularFireStorage,
  public modalCtrl: ModalController,
  public toastCtrl: ToastController,
  public auth: AuthenticationService,) { }

  ngOnInit() {
    this.userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  upload(): void {
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      this.selectedFiles = undefined;

      if (file) {
        this.currentFileUpload = new FileUpload(file);
        this.pushFileToStorage(this.currentFileUpload).subscribe(
          percentage => {
            this.percentage = Math.round(percentage ? percentage : 0);
            this.percentageBar = (this.percentage * 0.01);
          },
          error => {
            //console.log(error);
          }
        );
      }
    }
  }

  pushFileToStorage(fileUpload: FileUpload): Observable<number> {
    var basePath = '/portalUserFiles';
    const filePath = `${basePath}/${this.selectedUserID}/${this.selectedUserID + '.jpg'}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        storageRef.getDownloadURL().subscribe(downloadURL => {
          fileUpload.url = downloadURL;
          fileUpload.name = fileUpload.file.name;
          this.saveFileData(fileUpload,downloadURL);
          this.auth.logUserEvent('manageuser-profilepic', 'selecteduser:' + this.selectedUserID + ' - ' + downloadURL  + ' uploaded by ' + this.userItem[0].email + '.',this.selectedUserID );
          this.percentageBar = 0;
          this.percentage = 0;
          // if(this.sendEmailWithFile){this.sendUserEmail(this.userEmail,fileUpload.file.name + ' added to your portal files.',
          // fileUpload.file.name + ' has been uploaded to your portal files by:' + this.userItem[0].email + '<br>' + '<a href="https://portal-5edd4.web.app">Login to the portal to retrieve the file</a>'
          // )}
         
        });
      })
    ).subscribe();
    return uploadTask.percentageChanges();
  }

  saveFileData(fileUpload:FileUpload, url:any){
    this.afs.collection('users').doc(this.selectedUserID).update({
      photoURL: fileUpload.url          
    });   
    this.showToast(this.selectedUser + ' profile pic updated!', 'success')
    setTimeout(() => {
      this.dismiss();
   }, 1000);
  }


  
showToast(message:string, color:string) {
  this.myToast = this.toastCtrl.create({
    message: message,
    color: color,
    duration: 4000
  }).then((toastData) => {
    ////console.log(toastData);
    toastData.present();
  });
}
HideToast() {   
  this.myToast = this.toastCtrl.dismiss();
}

dismiss(data?: any) {
  // using the injected ModalController this page
  // can "dismiss" itself and pass back data
  this.modalCtrl.dismiss(this.selectedCheckList);
  //this.refreshFromQF();

}

}
