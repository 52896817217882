
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { IonTabs,IonSegment, IonTabButton, ToastController, IonInput,ModalController, IonRouterOutlet, AlertController, IonDatetime } from '@ionic/angular';
import { RouterOutlet, Router, ActivationStart,NavigationEnd } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserFiles } from '../../interfaces/user-files';
import { Observable, Subscription } from 'rxjs';
import { compileFunction } from 'vm';
import { finalize } from 'rxjs/operators';
import { ManageFilePage } from '../manage-file/manage-file.page';
import { DatePipe, UpperCasePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { FileSizePipe } from '../../filesize.pipe';
import { switchMap,map, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http'; 

export class FileUpload {
  key: string;
  name: string;
  url: string;
  file: File;
  constructor(file: File) {
    this.file = file;
  }
}

@Component({
  selector: 'app-manage-downloads',
  templateUrl: './manage-downloads.page.html',
  styleUrls: ['./manage-downloads.page.scss'],
  providers: [DatePipe,FileSizePipe],
})
export class ManageDownloadsPage implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('filepaginator') filepaginator: MatPaginator;
  @ViewChild('downloadsPaginator') downloadsPaginator: MatPaginator;
  @ViewChild('userspaginator') userspaginator: MatPaginator;
  @ViewChild('sort') sortMain: MatSort;
  @ViewChild('matSortFiles') matSortFiles: MatSort;
  @ViewChild('matSortDownloads') matSortDownloads: MatSort;
  @ViewChild('matSortUsers') matSortUsers: MatSort;
  
  displayedFileColumns = ['fileUrl','fileName', 'fileSize', 'fileDate',  'fileUser', 'fileLastDownload', 'fileExpiration'];
  displayedFileColumnsInActive = ['fileUrl','fileName', 'fileSize', 'fileDate',  'fileUser', 'fileLastDownload', 'fileExpiration'];
  dataSourceFiles: MatTableDataSource<UserFiles>;
  userName: any; 
  userItem: any;
  userId: any; 
  companyId: string;
  dataSource: MatTableDataSource<any[]>;
  // main section for company files
  dataSourceInActiveFiles: MatTableDataSource<UserFiles>;
  refCompany: AngularFirestoreDocument;
  dataSourceCompany: MatTableDataSource<any[]>;
  urlCompany: any;
  // storageRefCompany = firebase.storage().ref();
  ref2Company: AngularFireStorageReference;
  taskCompany: AngularFireUploadTask;
  uploadStateCompany: Observable<string>;
  uploadProgressCompany: Observable<number>;
  downloadURLCompany: Observable<string>;

  fileExpires:any;
  expiryDate:string;  
  DATE_TIME_FORMAT = 'MM-DD-YYYY'; 
  percentage = 0;
  percentageUser = 0;
  percentageUserBar = 0;
  percentageCompany = 0;
  percentageCompanyBar = 0;
  percentagePublic = 0;
  percentagePublicBar = 0;
  currentFileUpload?: FileUpload;
  selectedFiles?: FileList;
  selectedFilesPublic?: FileList;
  currentFileUploadUser?: FileUpload;
  currentFileUploadCompany?: FileUpload;
  currentFileUploadPublic?: FileUpload;
  selectedUserFiles?: FileList;
  sendEmailWithFile:boolean = true;
  viewMyFiles:boolean = true;

  private myToast: any;
  public newFileUpload: FormGroup; 
  customPickerOptions: any;
  fileTypeArray:Array<any> = ['image/png','image/jpeg','application/exe','application/x-zip-compressed','application/pdf','application/zip','application/vnd.openxmlformats-officedocument.presentationml.presentation','x-msdownload', 'application/x-zip-compressed'];
 

  constructor(private afs: AngularFirestore, private afStorage: AngularFireStorage,
    private _liveAnnouncer: LiveAnnouncer,
    private httpClient: HttpClient, 
    private router: Router,
    public toastCtrl: ToastController,
    public modalCtrl: ModalController,  
    private routerOutlet: IonRouterOutlet, 
    private fileSizePipe: FileSizePipe,
    //@Optional() private readonly routerOutlet?: IonRouterOutlet,
    private datePipe: DatePipe,
    formBuilder: FormBuilder,
    public afStore: AngularFirestore,
    public alertCtrl: AlertController,
    private storage: AngularFireStorage,
    public auth: AuthenticationService) {

      this.newFileUpload = formBuilder.group({
        fileExpires: [''],     
        expirationDate: ['']        
      }),
       this.customPickerOptions = {
      buttons: [{
        text: 'Save',
        handler: (data: any) => {
          this.setdates();
        },
      }, {
        text: 'Log',
        handler: () => {
          //console.log('Clicked Log. Do not Dismiss.');
          return false;
        }
      }]
    }

     }

  ngOnInit() {
    this.userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
    //console.log(this.userItem);
    this.userName = this.userItem[0].email;
    //console.log(this.userName);
    this.userId  = this.userItem[0].uid;
    //console.log(this.userId);
    this.companyId = this.userItem[0].companyId;
    //console.log(this.companyId);
    this.toggleMyFiles();
    // this.getPublicFiles();
    // this.getInactiveFiles();
    //this.expiryDate = new Date().toISOString();

  }

  ionViewWillEnter(){

    let _now: moment.Moment;
    _now = moment(new Date(), this.DATE_TIME_FORMAT);
    this.expiryDate = _now.format('MM/DD/YYYY'); _now.date;
    this.newFileUpload.patchValue({expiryDate: this.expiryDate});
  //   let _now: moment.Moment;
  // _now = moment(new Date(), this.DATE_TIME_FORMAT);
  // this.defaultDateFrom = _now.format('MM/DD/YYYY'); _now.date;

  }

  toggleMyFiles(){
    if (this.viewMyFiles) {
this.getMyPublicFiles();
this.getMyInactiveFiles();
    } else {
this.getPublicFiles();
this.getInactiveFiles();
    }
  }

  calculateFileSize(fileSize:any){
    return this.fileSizePipe.transform(fileSize, 'MB')
  }

  private getPublicFiles(): void {
    this.afs.collection('public-downloads', ref => ref.where('isActive', '==', 
    true).orderBy('fileDate','desc')).valueChanges()
    //.pipe(take(1))
    .subscribe(publicDownloads => {
      this.dataSourceFiles = new MatTableDataSource<any>(publicDownloads);
      this.dataSourceFiles.paginator = this.downloadsPaginator;
      this.dataSourceFiles.sort = this.matSortDownloads;
    });
   // //console.log(this.afs.collection.name);
  }  

  private getMyPublicFiles():void {
    //viewMyFiles 
    this.afs.collection('public-downloads', ref => ref.where('isActive', '==', 
    true).where('fileUser','==',this.userName ).orderBy('fileDate','desc')).valueChanges()
    //.pipe(take(1))
    .subscribe(publicDownloads => {
      this.dataSourceFiles = new MatTableDataSource<any>(publicDownloads);
      this.dataSourceFiles.paginator = this.downloadsPaginator;
      this.dataSourceFiles.sort = this.matSortDownloads;
    });
  }

  private getInactiveFiles(): void {
       
    this.afs
    .collection('public-downloads', ref => ref
    .where('isActive', '==', false))
    .valueChanges()
    //.pipe(take(1))
    .subscribe(publicDownloadsInActive => {
      this.dataSourceInActiveFiles = new MatTableDataSource<any>(publicDownloadsInActive);
      this.dataSourceInActiveFiles.paginator = this.paginator;
      // this.dataSourceFiles.sort = this.applyFilter('');
    });
   // //console.log(this.afs.collection.name);
  }  

  private getMyInactiveFiles(): void {
       
    this.afs
    .collection('public-downloads', ref => ref
    .where('isActive', '==', false).where('fileUser','==',this.userName ))
    .valueChanges()
    //.pipe(take(1))
    .subscribe(publicDownloadsInActive => {
      this.dataSourceInActiveFiles = new MatTableDataSource<any>(publicDownloadsInActive);
      this.dataSourceInActiveFiles.paginator = this.paginator;
      // this.dataSourceFiles.sort = this.applyFilter('');
    });
   // //console.log(this.afs.collection.name);
  }  

  viewFileAsDownload(fileId:any,fileName:any) {
    const downloadPath = `${'downloads'}/${fileId}`;
    this.router.navigateByUrl(downloadPath);
  }

  
  async manageFile(fileId:any,fileName:any) {
    //this.auth.logUserEvent("managecompanydetail",companyId,this.userItem[0].uid);
    ////console.log(companyId);
    const modal = await this.modalCtrl.create({
      component: ManageFilePage,
      id: 'productdetail',
      canDismiss : true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: { fileId: fileId, fileName:fileName},
      cssClass: 'product-detail-modal',
    });
    await modal.present();
    
    const { data } = await modal.onWillDismiss();
    if (data) {
      //this.refreshData(this.defaultWC);
      if ( data[0]['updated'] === 1 ){  //this means the updated was passed
        //this.adminSearch();
      } else {
    
              }  
          }  
      }

      getDownloadUrl(fileId:any): void{
        var downloadUrl;
        var fileName;
       this.afs
        .collection('public-downloads', ref => ref
        .where('id', '==', fileId))
        .valueChanges()
        .pipe(take(1))
        .subscribe(file => {  
          downloadUrl = file[0]['fileDownloadUrl']
          fileName = file[0]['fileName']
          this.getFileFromApi(downloadUrl).pipe(take(1))
          .subscribe((response) => {
              const downloadLink = document.createElement('a');
              downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
      
              const contentDisposition = response.headers.get('content-disposition');
              //const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
              downloadLink.download = fileName;
              downloadLink.click();
          });  
        });
        
      }
      
      getFileFromApi(downloadUrl: string): Observable<HttpResponse<Blob>> {
        return this.httpClient.get<Blob>(downloadUrl, { observe: 'response', responseType: 'blob' as 'json'});
      }


      selectFilePublic(event: any): void {
        this.selectedFilesPublic = event.target.files;
      }

      uploadPublicFile(): void {
        if (this.selectedFilesPublic) {
          const file: File | null = this.selectedFilesPublic.item(0);
          this.selectedFilesPublic = undefined;
          this.showToast('Upload Started...', 'warning');
          if (file) {
            this.currentFileUploadPublic = new FileUpload(file);
            this.pushFileToStoragePublic(this.currentFileUploadPublic).subscribe(
              percentage => {
                this.percentagePublic= Math.round(percentage ? percentage : 0);
                this.percentagePublicBar = (this.percentagePublic * 0.01);
              },
              error => {
                //console.log(error);
              }
            );
          }
        }
      }
    
      pushFileToStoragePublic(fileUpload: FileUpload): Observable<number> {
      var basePath = '/portalPublicDownloads/';
      const filePath = `${basePath}/${fileUpload.file.name}`;
      const storageRef = this.storage.ref(filePath);
      const uploadTask = this.storage.upload(filePath, fileUpload.file);
      uploadTask.snapshotChanges().pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe(downloadURL => {           
            fileUpload.url = downloadURL;
            fileUpload.name = fileUpload.file.name;
            this.saveFileDataPublic(fileUpload,filePath);
            this.auth.logUserEvent('file-public-fileupload', fileUpload.file.name + ' uploaded by ' + this.userItem[0].email + '.',this.companyId); //figure out company vs user log
            this.percentagePublic = 0;
            this.percentagePublicBar = 0;
            this.showToast('Public File Uploaded!', 'success');
          });
        })
      ).subscribe();
      return uploadTask.percentageChanges();
    }
    
    saveFileDataPublic(fileUpload:FileUpload,filepath:any){
      this.afStore.collection("public-downloads").add({
        fileDate:Date.now(), fileName: fileUpload.file.name, fileType: fileUpload.file.type,fileDownloadUrl: fileUpload.url, fileUrl: filepath, uid:this.userItem[0].uid, fileUser: this.userItem[0].email, companyid: this.companyId,
        fileExpires: this.fileExpires ?? false,fileExpirationDate: this.expiryDate ?? '', isActive:true, fileSize:fileUpload.file.size
        //logtype: logtype,message:message,datetime:Date.now(),uid:uid
    })
    .then(docRef => {
      this.afStore.collection('public-downloads').doc(docRef.id).update({
        id: docRef.id      
      });
        ////console.log("Document written with ID: ", docRef.id);
       // //console.log("You can now also access this. as expected: ", this.foo)
    })
    .catch(error => console.error("Error adding document: ", error))
    
    }

    
    async alertMarkInactive(fileId:any, fileName:any){
      
      const alert = await this.alertCtrl.create({
        header: 'Set File :' + fileName + ' inActive?',
        message: 'Are you sure you want to mark this file inActive?',
        buttons: [
          'No',
          {
            text: 'Yes',
            handler: (data: any) => {
              this.deactivateFile(fileId,fileName);
            }
          }
        ],
      });
      // now present the alert on top of all other content
      await alert.present();
    }
    
    deactivateFile(id:any,name:any){
      this.afStore.collection('public-downloads').doc(id).update({
       isActive:false
      });   
      this.auth.logUserEvent('managepublic-download-decativatefile',this.userItem[0].email + ' updated file -' + name + ' :' + id + ' file marked inActive' + '.',this.userItem[0].uid);
      this.showToast('File Marked InActive','success');
    }

    async alertdeleteFile(fileId:any, fileName:any){
      
      const alert = await this.alertCtrl.create({
        header: 'Completely Delete File :' + fileName + ' and all history?',
        message: 'Are you sure you want to delete this file and the file history?',
        buttons: [
          'No',
          {
            text: 'Yes',
            handler: (data: any) => {
              this.deleteFile(fileId,fileName);
            }
          }
        ],
      });
      // now present the alert on top of all other content
      await alert.present();
    }
    
    deleteFile(id:any,name:any){
      this.afStore.collection('public-downloads').doc(id).delete();
      this.auth.logUserEvent('managepublic-download-deletefile',this.userItem[0].email + ' deleted file -' + name + ' :' + id + ' file marked inActive' + '.',this.userItem[0].uid);
      this.showToast('File Permanently Deleted! ','success');
    }

    copyMessage(val: string){
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.showToast(val + ' copied to clipboard!', 'success')
    }

     /** Announce the change in sort state for assistive technology. */
     announceSortChange(sortState: Sort) {
      if (sortState.direction) {
        this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
      } else {
        this._liveAnnouncer.announce('Sorting cleared');
      }
    }

    setdates(){   
      var expirartionDate = this.newFileUpload.get('expirationDate').value ; 
      this.expiryDate = this.datePipe.transform(expirartionDate,"MM/dd/YYYY"); 
      ////console.log(this.dateFrom);
      ////console.log(this.dateTo);
    }

    applyFilterFiles(filterValue: any) {
      filterValue = filterValue.value.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSourceFiles.filter = filterValue;
    }

    applyFilterInActiveFiles(filterValue: any) {
      filterValue = filterValue.value.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSourceInActiveFiles.filter = filterValue;
    }

    showToast(message:string, color:string) {
      this.myToast = this.toastCtrl.create({
        message: message,
        color: color,
        duration: 4000
      }).then((toastData) => {
        ////console.log(toastData);
        toastData.present();
      });
    }
    HideToast() {
      this.myToast = this.toastCtrl.dismiss();
    }
}
