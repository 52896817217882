import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { IonTabs,IonSegment, IonTabButton, ToastController, IonInput,ModalController, IonRouterOutlet, AlertController } from '@ionic/angular'
import { SettingsService } from 'src/app/services/settings.service';
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserFiles } from '../../interfaces/user-files';
import { PortalFiles } from 'src/app/interfaces/portal-files';
import { Observable, Subscription } from 'rxjs';
import { compileFunction } from 'vm';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { switchMap,map, take } from 'rxjs/operators';
import { HttpClient, HttpResponse } from "@angular/common/http";

import { DatePipe, UpperCasePipe } from '@angular/common';

@Component({
  selector: 'app-manage-file',
  templateUrl: './manage-file.page.html',
  styleUrls: ['./manage-file.page.scss'],  
  providers: [DatePipe],
})
export class ManageFilePage implements OnInit {
fileId:any;
fileName:any;
fileType:any;

fileUrl:any;

displayedDownloadColumns = ['datetime','ipAddress','userEmail','version','message','logtype'];
private myToast: any;
public companyId: any;
ipInfo:any;
  public uid: any;
  userName: any; 
  userItem: any;
  userId: any; 
  userEmpId: any;
  userCompanyId:any;
  userEmail:any;
  userPhotoUrl:any;
  userdisplayName:any;
  userCompanyName:any;
  userActive:any;
  expiryDate:any;
  public newFileUpload: FormGroup; 
  dataSourceDownloadLog: MatTableDataSource<any>;
  fileExpires:boolean;
  fileActive:boolean;
  @ViewChild('matSortDLLog') matSortDLLog: MatSort;
  @ViewChild('downloadspaginator') downloadspaginator: MatPaginator;
  customPickerOptions: any;

  constructor(private afs: AngularFirestore, private afStorage: AngularFireStorage,
    public modalCtrl: ModalController,  
    private datePipe: DatePipe,
    private http: HttpClient,
    private _liveAnnouncer: LiveAnnouncer,
    public alertCtrl: AlertController,
    private routerOutlet: IonRouterOutlet,
    public toastCtrl: ToastController,    
    formBuilder: FormBuilder,
    //@Optional() private readonly routerOutlet?: IonRouterOutlet,
    private storage: AngularFireStorage,
    public afStore: AngularFirestore,
    public auth: AuthenticationService) {
      this.newFileUpload = formBuilder.group({
        fileExpires: [''],     
        fileActive: [''],     
        expirationDate: ['']        
      });

      this.customPickerOptions = {
        buttons: [{
          text: 'Save',
          handler: (data: any) => {
            this.setdates();
          },
        }, {
          text: 'Log',
          handler: () => {
            //console.log('Clicked Log. Do not Dismiss.');
            return false;
          }
        }]
      }
     }

  ngOnInit() {
    this.userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
    this.getFileInfo();
    this.getDownloadData();
    this.getRemoteIP()
    .subscribe(data => {
      this.ipInfo = data['ip'];  
      //console.log(this.download$);
    }); 
  }

  getRemoteIP() :Observable<any[]> {
    //return this.http.put<string>(`${environment.url}}/confirm`, job);
    const url = `https://api.ipify.org?format=json`;    
   // return this.http.get<WTLColumns[]>(url);
   return this.http.get<any[]>(url);
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getFileInfo(){
    this.afs
    .collection('public-downloads', ref => ref
    .where('id', '==', this.fileId)
    )
    .valueChanges()
    //.pipe(take(1))
    .subscribe(downloadData => { 
      this.fileType = downloadData[0]['fileType'];
      this.fileActive = downloadData[0]['isActive'];
      this.fileExpires = downloadData[0]['fileExpires'];
      this.fileUrl = downloadData[0]['fileDownloadUrl'];
      this.expiryDate = downloadData[0]['fileExpirationDate'];     
      
      this.newFileUpload.patchValue({expiryDate: this.expiryDate, fileActive:this.fileActive, fileExpires:this.fileExpires });

    });
  }

  getDownloadData(){

  this.afs.collection('public-downloads-log', ref => ref.where('fileId', '==', 
  this.fileId).orderBy('datetime','desc')).valueChanges()
  
  //.pipe(take(1))
  .subscribe(downloadData => { 
    this.dataSourceDownloadLog= new MatTableDataSource<any>(downloadData);
    this.dataSourceDownloadLog.paginator = this.downloadspaginator;
    this.dataSourceDownloadLog.sort = this.matSortDLLog;
  });
  
}

applyFilterDownloadLog(filterValue: any) {
  filterValue = filterValue.value.trim(); // Remove whitespace
  filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
  this.dataSourceDownloadLog.filter = filterValue;
}

  updateCurrentFile(){
    this.afStore.collection('public-downloads').doc(this.fileId).update({
      fileExpires: this.newFileUpload.value.fileExpires,isActive:this.newFileUpload.value.fileActive,fileExpirationDate:this.newFileUpload.value.expirationDate
    }).then(() => {
      this.auth.logPublicDownloadEvent('updateFile',this.userItem[0].email + 
      ' updated file info -' +  this.fileName + ' :' +  this.fileId + ' expirationDate:' + this.expiryDate + ' expires:' + this.newFileUpload.value.fileExpires + ' isActive: ' + this.newFileUpload.value.fileActive,
      this.userItem[0].uid,this.userItem[0].email,this.fileName,this.fileId,'ver',this.ipInfo);
      this.showToast('File Updated!','success')
     })
      
  }

  
  setdates(){   
    var expirartionDate = this.newFileUpload.get('expirationDate').value ; 
    this.expiryDate = this.datePipe.transform(expirartionDate,"MM/dd/YYYY"); 
    ////console.log(this.dateFrom);
    ////console.log(this.dateTo);
  }

  alertdeleteFile(){

  }

  showToast(message:string, color:string) {
    this.myToast = this.toastCtrl.create({
      message: message,
      color: color,
      duration: 4000
    }).then((toastData) => {
      //console.log(toastData);
      toastData.present();
    });
  }
  HideToast() {   
    this.myToast = this.toastCtrl.dismiss();
  }
  
  
  dismiss(data?: any) {
    // using the injected ModalController this page
    // can "dismiss" itself and pass back data
    this.modalCtrl.dismiss(this.uid);
    //this.refreshFromQF();
  
  }

}
