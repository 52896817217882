import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { IonTabs,IonSegment, IonTabButton, ToastController, IonInput,ModalController, IonRouterOutlet, AlertController } from '@ionic/angular'
import { SettingsService } from 'src/app/services/settings.service';
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserFiles } from '../../interfaces/user-files';
import { Observable, Subscription } from 'rxjs';
import { compileFunction } from 'vm';
import { finalize } from 'rxjs/operators';
import { ManageCompaniesDetailPage } from '../manage-companies-detail/manage-companies-detail.page';
import { switchMap,map, take } from 'rxjs/operators';
import { UserImgHandlerPage } from '../user-img-handler/user-img-handler.page';

import { ManageRegDetailPage } from '../manage-reg-detail/manage-reg-detail.page';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { getFunctions,Functions, httpsCallable } from "@angular/fire/functions";

export class FileUpload {
  key: string;
  name: string;
  url: string;
  file: File;
  constructor(file: File) {
    this.file = file;
  }
}

@Component({
  selector: 'app-manage-users-detail',
  templateUrl: './manage-users-detail.page.html',
  styleUrls: ['./manage-users-detail.page.scss'],
})



export class ManageUsersDetailPage implements OnInit {
public uid: any;
userName: any; 
userItem: any;
userId: any; 
userEmpId: any;
userCompanyId:any;
userEmail:any;
userPhotoUrl:any;
userdisplayName:any;
userCompanyName:any;
userActive:any;
userIsAdmin:any;
companyLogoURL:any;
dateCreated:any;
appPermissions:any
private myToast: any;
repDisplayName:any;
repPhotoUrl:any;
assignedRep:any;
dataSourceUsersCombo: MatTableDataSource<any>;
dataSourceUsers: MatTableDataSource<any>;
dataSourceCompanies: MatTableDataSource<any>;
dataSourceUserLog: MatTableDataSource<any>;
dataSourceFiles: MatTableDataSource<UserFiles>;
@ViewChild('companySelect', {static: false}) inputEl: IonInput;
@ViewChild('paginator') paginator: MatPaginator;
@ViewChild('filepaginator') filepaginator: MatPaginator;
@ViewChild('approvalspaginator') approvalspaginator: MatPaginator;
@ViewChild('sort') sortMain: MatSort;
@ViewChild('matSortFiles') matSortFiles: MatSort;
companyList:any;
companyId:any;
roleName:any;
roleList:any;
repList:any;
displayedFileColumns = ['fileDate', 'fileName', 'fileUser'];
displayedUserLogColumns = ['datetime', 'logtype', 'message'];
percentage = 0;
percentageBar = 0;
currentFileUpload?: FileUpload;
selectedFiles?: FileList;
sendEmailWithFile:boolean = true;
pageloadCountUser:number = 0;
pageloadCountAdmin:number = 0;
registrationId:any;
constructor(private afs: AngularFirestore, private afStorage: AngularFireStorage,
  private httpClient: HttpClient,
  public modalCtrl: ModalController,  
  private _liveAnnouncer: LiveAnnouncer,
  public alertCtrl: AlertController,
  public toastCtrl: ToastController,
  public routerOutlet: IonRouterOutlet,
  private storage: AngularFireStorage,
  public afStore: AngularFirestore,  
  private functions: Functions,    
  public auth: AuthenticationService) { }

  ngOnInit() {
    this.userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
    //console.log(this.userItem);  

    this.getUsers();
    this.getCompanies();
    this.getUserFiles();
    this.getUserLog();
    this.getRoles();
    this.getReps();
    this.getRegId();
  }

  ionViewDidEnter(){
    //this.pageloadCount = this.pageloadCount + 1;
  }

  getUsers(): void{
    this.afs
    .collection('companies', ref => ref    
    )
    .valueChanges()
    .pipe(take(1))
    .subscribe(companies => {
      this.dataSourceCompanies = new MatTableDataSource<any>(companies);          
      this.afs
      .collection('users', ref => ref.where('uid', '==', 
       this.uid).orderBy('companyId','asc')).valueChanges()
       .pipe(take(1))
       .subscribe(users => {
        this.dataSourceUsers = new MatTableDataSource<any>(users);
                // this.dataSourceFiles.sort = this.applyFilter('');

    //     const mergeById = (users, companies) =>
    //     users.map(companyId => ({
    //     ...companies.find((id) => (companies.id === users.companyId) && companyId),
    //     ...companyId
    // }));
    const mergeById = (array1, array2) =>
    array1.map(itm => ({
      ...array2.find((item) => (item.id === itm.companyId) && item),
      ...itm
    }));

    let result = mergeById(users, companies)

        //this.usersMain = this.mergeById(companies, users) ;// mergeById; //portalfiles.map((fileId, i) => Object.assign({}, fileId, portalfilesEULA[i]));
        //console.log(result);

        this.dataSourceUsersCombo = new MatTableDataSource<any>(result);      
        this.userEmail = result[0].email;
        this.userName = result[0].email;
        this.userdisplayName = result[0].displayName;
        this.userCompanyId = result[0].companyId;
        this.userPhotoUrl = result[0].photoURL;
        this.userCompanyName = result[0].companyName;
        this.companyLogoURL = result[0].logoURL;
        this.userActive = result[0].isActive;
        this.userIsAdmin = result[0].isAdmin;
        this.roleName = result[0].role;
        this.dateCreated = result[0].dateCreated;
        this.assignedRep = result[0].assignedRep;
        this.afs
        .collection('userReps', repRef => repRef.where('repId', '==', 
        this.assignedRep))
        .valueChanges()
        //.pipe(take(1))
        .subscribe(repInfo => {
          //get the companies first
          this.repDisplayName = repInfo[0]['repDisplayName'];
          this.repPhotoUrl = repInfo[0]['repPhotoUrl'];
          //console.log(this.companyList);
        });
        //this.dateCreated = result[0].dateCreated.toDate() === undefined ? "" : result[0].dateCreated.toDate(); 
      });
    });
  }

  getCompanies(){
    this.afs
    .collection('companies', compRef => compRef.orderBy('companySysproId'))
    .valueChanges()
    .pipe(take(1))
    .subscribe(companyinfo => {
      //get the companies first
      let compInfo:any;
      compInfo = companyinfo;
      this.companyList = compInfo
      //console.log(this.companyList);
    });
  
  }

  
  getRoles(){
    this.afs
    .collection('roles', roleRef => roleRef.orderBy('roleName'))
    .valueChanges()
    .pipe(take(1))
    .subscribe(roleinfo => {
      //get the companies first      
      this.roleList = roleinfo
      //console.log(this.roleList);
    });
  
  }

  getReps(){
    this.afs
    .collection('userReps', roleRef => roleRef.orderBy('repDisplayName'))
    .valueChanges()
    .pipe(take(1))
    .subscribe(repInfo => {
      //get the reps first      
      this.repList = repInfo
      //console.log(this.repList);
    });
  
  }

  getRegId(){
    this.afs
    .collection('registrations', userRef => userRef.where('uid', '==', 
    this.uid))
    .valueChanges()
    .pipe(take(1))
    .subscribe(reginfo => {
      //get the reps first      
      this.registrationId = reginfo[0]['regId'];
      //console.log(this.repList);
    });
  
  }
  

  getUserLog(){
    this.afs
    .collection('user-log', ref => ref.where('uid', '==', 
     this.uid).orderBy('datetime','desc')).valueChanges()
     .pipe(take(1))
     .subscribe(logdata => {
      this.dataSourceUserLog = new MatTableDataSource<any>(logdata);
      this.dataSourceUserLog.paginator = this.paginator;
      this.dataSourceUserLog.sort = this.sortMain;
    });  
  }

  private getUserFiles(): void {
       
    this.afs
    .collection('userfiles', ref => ref
    .where('uid', '==', this.uid).orderBy('fileDate','desc'))
    .valueChanges()
    //.pipe(take(1))
    .subscribe(userfiles => {
      this.dataSourceFiles = new MatTableDataSource<any>(userfiles);
      this.dataSourceFiles.paginator = this.filepaginator;
      this.dataSourceFiles.sort = this.matSortFiles; 
      //console.log(this.dataSourceFiles);
      // this.dataSourceFiles.sort = this.applyFilter('');
    });
   // //console.log(this.afs.collection.name);
  }

  async alertdeleteFile(id:any, name:any){
    const alert = await this.alertCtrl.create({
      header: 'Delete File :' + name + ' ?',
      message: 'Are you sure you want to delete the file?',
      buttons: [
        'No',
        {
          text: 'Yes',
          handler: (data: any) => {
            this.deleteFile(id,name);
          }
        }
      ],
    });
    // now present the alert on top of all other content
    await alert.present();
  }

  deleteFile(id:any,name:any){
    this.afStore.collection("userfiles").doc(id).delete().then(() => {
      var basePath = '/portalUserFiles/' + this.uid;
      const filePath = `${basePath}/${name}`;
      const storageRef = this.storage.ref(filePath);
      storageRef.delete();

      this.auth.logUserEvent('manageuserdetail-filedelete', 'file ' + name + ' deleted by ' + this.userItem[0].email + '.',this.uid);
      this.showToast(name + ' deleted!', 'success');
    });
  }
  

  setSelectedCompany(event: any){ 
var compId = event['value']['id'];
      this.afStore.collection('users').doc(this.uid).update({
        companyId: compId          
      });   
      this.auth.logUserEvent('manageuserdetail-company', this.uid + ' changed to company: ' + compId + '.',this.uid);
      this.getUsers();
      this.showToast('Company Changed to:' + event['value']['companyName'] + '!', 'success');
  }

  setSelectedRole(event: any){ 
    var roleSelect = event['value']['roleName'];
          this.afStore.collection('users').doc(this.uid).update({
            role: roleSelect          
          });   
          this.auth.logUserEvent('manageuserdetail-roles', this.uid + ' role changed to: ' + roleSelect + '.',this.uid);
          this.getUsers();
          this.showToast('Role Changed to:' + event['value']['roleName'] + '!', 'success');
      }

  setSelectedRep(event: any){ 
    var repSelect = event['value']['repId'];
          this.afStore.collection('users').doc(this.uid).update({
            assignedRep: repSelect          
          });   
          this.auth.logUserEvent('manageuserdetail-rep', this.uid + ' assigned rep changed to: ' + repSelect + '-' + event['value']['repDisplayName'] + '.',this.uid);
          this.getUsers();
          this.showToast('Rep Changed to:' + event['value']['repDisplayName'] + '!', 'success');
      }      

  setUserStatus(e){   
    this.pageloadCountUser = this.pageloadCountUser + 1;
    if (this.pageloadCountUser > 1) {
      const ischecked: boolean =  e.currentTarget.checked; 
    this.afStore.collection('users').doc(this.uid).update({
      isActive: ischecked        
    });   
    this.showToast('Active Status Updated!', 'success');
    this.auth.logUserEvent('manageuserdetail-status', this.uid + ' active status changed to: ' + this.userActive + '.',this.uid);
  }
  }

  setUserAdminStatus(e){   
    this.pageloadCountAdmin = this.pageloadCountAdmin + 1;
    if (this.pageloadCountAdmin > 2) {
      const ischecked: boolean =  e.currentTarget.checked; 
      this.afStore.collection('users').doc(this.uid).update({
        isAdmin: ischecked        
      });   
      this.showToast('Admin Status Updated!', 'success');
      this.auth.logUserEvent('manageuserdetail-isAdmin', this.uid + ' admin status changed to: ' + this.userIsAdmin + '.',this.uid);
    } 
   
  }

  async showImgHandler() {
    //console.log(selectedImage);
    const modal = await this.modalCtrl.create({
      component: UserImgHandlerPage,
      canDismiss : true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: { selectedUser: this.userName, selectedUserID:this.uid},
      cssClass: 'checklistview-modal',
    });
    await modal.present();
    
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.getUsers();
      if ( data[0]['isSearch'] === 1 ){  
      } else {
    
              }  
          } else {
            this.getUsers();
          }  
      }

      getDownloadUrl(fileId:any): void{
        var downloadUrl;
        var fileName;
       this.afs
        .collection('userfiles', ref => ref
        .where('id', '==', fileId))
        .valueChanges()
        .pipe(take(1))
        .subscribe(file => {  
         // downloadUrl = file[0]['fileDownloadUrl']
          fileName = file[0]['fileName']
          const getSigFile = httpsCallable(this.functions, 'generateSignedUrl');

          let createdUserId = '';
          let response = false;
          //expire in 20minutes
          const expirationDate = new Date(
            new Date().getTime() + .33 * 60 * 60 * 1000
          );

          getSigFile({bucketName:'gs://portal-5edd4.appspot.com/portalUserFiles/' + this.uid + '/', fileName: fileName, expirationDate:expirationDate })
.then(({data: url}) => {
  console.log(url);
 //window.open(String(url),"_blank");
 //window.open(String(url), 'Download'); 
 //this.downloadProduct(String(url));
 
 const e = document.createElement('a');
 e.href = url.toString();
 e.target = "_blank";
 e.download = url.toString().substr(url.toString().lastIndexOf('/') + 1);
 document.body.appendChild(e);
 e.click();
      this.afStore.collection('userfiles').doc(fileId).update({
          lastDownloaded: Date.now(),wasDownloaded:true,lastDownloadUser:this.userItem[0].email    
        }); 
        this.afStore.collection("userfiledownloads").add({
          fileId:fileId, downloadDate:Date.now(), fileName: fileName, uid:this.userItem[0].uid, fileUser: this.userItem[0].email, companyid: this.userItem[0].companyId        
      })
      .then(docRef => {
        this.afStore.collection('userfiledownloads').doc(docRef.id).update({
          id: docRef.id      
        });       
        this.auth.logUserEvent('userfiledownloads', 'file ' + fileName + ' downloaded by ' + this.userItem[0].email + '.',this.userItem[0].uid);
      })
      .catch(error => console.error("Error adding document: ", error))      
       }); 
      });

        //   this.getFileFromApi(downloadUrl).pipe(take(1))
        //   .subscribe((response) => {
        //       const downloadLink = document.createElement('a');
        //       downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
      
        //       const contentDisposition = response.headers.get('content-disposition');
        //       //const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        //       downloadLink.download = fileName;
        //       downloadLink.click();
        //       this.afStore.collection('userfiles').doc(fileId).update({
        //         lastDownloaded: Date.now(),wasDownloaded:true,lastDownloadUser:this.userItem[0].email    
        //       }); 
        //       this.afStore.collection("userfiledownloads").add({
        //         fileId:fileId, downloadDate:Date.now(), fileName: fileName, uid:this.userItem[0].uid, fileUser: this.userItem[0].email, companyid: this.companyId          
        //       })
        //       .then(docRef => {
        //         this.afStore.collection('userfiledownloads').doc(docRef.id).update({
        //           id: docRef.id      
        //         });         
        //       })
        //       .catch(error => console.error("Error adding document: ", error)) 
        //   });  
        // });
        
      }
      
      getFileFromApi(downloadUrl: string): Observable<HttpResponse<Blob>> {
        return this.httpClient.get<Blob>(downloadUrl, { observe: 'response', responseType: 'blob' as 'json'});
      }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  upload(): void {
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      this.selectedFiles = undefined;

      if (file) {
        this.currentFileUpload = new FileUpload(file);
        this.pushFileToStorage(this.currentFileUpload).subscribe(
          percentage => {
            this.percentage = Math.round(percentage ? percentage : 0);
            this.percentageBar = (this.percentage * 0.01);
          },
          error => {
            //console.log(error);
          }
        );
      }
    }
  }

pushFileToStorage(fileUpload: FileUpload): Observable<number> {
  var basePath = '/portalUserFiles/' + this.uid;
  const filePath = `${basePath}/${fileUpload.file.name}`;
  const storageRef = this.storage.ref(filePath);
  const uploadTask = this.storage.upload(filePath, fileUpload.file);
  uploadTask.snapshotChanges().pipe(
    finalize(() => {
      storageRef.getDownloadURL().subscribe(downloadURL => {
        fileUpload.url = downloadURL;
        fileUpload.name = fileUpload.file.name;
        this.saveFileData(fileUpload, filePath);
        this.auth.logUserEvent('manageuserdetail-fileupload', fileUpload.file.name + ' uploaded by ' + this.userItem[0].email + '.',this.uid);
        this.percentageBar = 0;
        this.percentage = 0;
        if(this.sendEmailWithFile){this.sendUserEmail(this.userEmail,fileUpload.file.name + ' added to your portal files.',
        fileUpload.file.name + ' has been uploaded to your portal files by:' + this.userItem[0].email + '<br>' + '<a href="https://portal-5edd4.web.app">Login to the portal to retrieve the file</a>'
        )}
        this.showToast(fileUpload.file.name + ' uploaded!', 'success');
        this.auth.addPortalMessage(this.uid,this.userItem[0].email,fileUpload.file.name + ' has been uploaded to your portal files by:' + this.userItem[0].email ,this.uid);
        this.getUserFiles();
      });
    })
  ).subscribe();
  return uploadTask.percentageChanges();
}

saveFileData(fileUpload:FileUpload, filepath:any){
  this.afStore.collection("userfiles").add({
    fileDate:Date.now(), fileName: fileUpload.file.name, fileType: fileUpload.file.type, fileDownloadUrl:fileUpload.url, fileUrl:  filepath, uid:this.uid, fileUser: this.userItem[0].email,
    wasDownloaded:false,lastDownloadUser:''
    //logtype: logtype,message:message,datetime:Date.now(),uid:uid
})
.then(docRef => {
  this.afStore.collection('userfiles').doc(docRef.id).update({
    id: docRef.id      
  });
    ////console.log("Document written with ID: ", docRef.id);
   // //console.log("You can now also access this. as expected: ", this.foo)
})
.catch(error => console.error("Error adding document: ", error))

}

sendUserEmail(to:any,subject:any,body:any) {
  this.afStore.collection("mail").add({
    to: [to],
    message: {
      subject: subject,
      text: body,
      html: body
    }
  })
  .then(docRef => {
  
  
  })
  .catch(error => console.error("Error adding document: ", error))    
  this.showToast(subject + '-' + ' EMAIL SENT','success') ;
    
}


  applyFilterFiles(filterValue: any) {
    filterValue = filterValue.value.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSourceFiles.filter = filterValue;
  }

  applyFilterLog(filterValue: any) {
    filterValue = filterValue.value.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSourceUserLog.filter = filterValue;
  }

   /** Announce the change in sort state for assistive technology. */
   announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  

  createUser(email:any, emailVerified:any, phone:any, companyId:any, fullName:any, title:any){
    //const uid = 'LfmqW5SSNkUb9K8fxtKY'; // this.userItem[0].uid;
    //const logRef = this.afStore.doc('user-log/');
    this.afStore.collection("users").add({
      email: email, emailVerified:emailVerified, displayName:fullName,phone:phone,companyId:companyId,title:title,dateCreated:Date.now(),isActive:true,isAdmin:false,photoUrl:'',regId:''
  })
  .then(docRef => {
      //console.log("Document written with ID: ", docRef.id);
     // //console.log("You can now also access this. as expected: ", this.foo)     
     this.afStore.collection('regId').doc(docRef.id).update({
      id: docRef.id      
    });
  })
  .catch(error => console.error("Error adding document: ", error))
    //this.userId  = this.userItem[0].uid;
    // set() for destructive updates
    //logRef.set({ logtype: logtype,message:message,datetime:Date.now(),uid:uid});

  }

  async showRegDetail(){
    //this.auth.logUserEvent("manageregdetail",regid,this.userItem[0].uid);
   // //console.log(uid);
    const modal = await this.modalCtrl.create({
      component: ManageRegDetailPage,
      id: 'manageregdetail',
      canDismiss : true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: { regid: this.registrationId},
      cssClass: 'user-detail-modal',
    });
    await modal.present();
    
    const { data } = await modal.onWillDismiss();
    if (data) {
      //this.refreshData(this.defaultWC);
      if ( data[0]['isSearch'] === 1 ){  //this means the fromjobsearch was passed
        //this.adminSearch();
      } else {
    
              }  
          }  
  }

  async showCompanyDetail() {
    //this.auth.logUserEvent("managecompanydetail",companyId,this.userItem[0].uid);
   
    const modal = await this.modalCtrl.create({
      component: ManageCompaniesDetailPage,
      id: 'checklistview',
      canDismiss : true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: { companyId: this.userCompanyId},
      cssClass: 'company-detail-modal',
    });
    await modal.present();
    
    const { data } = await modal.onWillDismiss();
    if (data) {
      //this.refreshData(this.defaultWC);
      if ( data[0]['isSearch'] === 1 ){  //this means the fromjobsearch was passed
        //this.adminSearch();
      } else {
    
              }  
          }  
      }

      sendPasswordResetEmail(){
      this.auth.PasswordRecover(this.userEmail);
      this.afStore.collection("mail").add({        
        to: [this.userEmail],
        message: {
          subject: 'Password Reset Sent For Customer Portal.',
          text: 'Name: ' + this.userName + '<br>' + "Email: " + this.userEmail + '<br>' + "Sent By: " + this.userItem[0].email + '<br>' + '<a href="https://portal-5edd4.web.app">https://portal-5edd4.web.app</a>',
          html: 'Name: ' + this.userName + '<br>' + "Email: " + this.userEmail + '<br>' + "Sent By: " + this.userItem[0].email + '<br>' + '<a href="https://portal-5edd4.web.app">https://portal-5edd4.web.app</a>'
        }
      });
      
      this.showToast('Password Reset Email Sent To: ' + this.userEmail + '!', 'success')


      }

      sendVerificationEmail() {
        //this.auth.SendVerificationMail();
        this.afStore.collection("mail").add({        
          to: [this.userEmail],
          message: {
            subject: 'Verification Email For Customer Portal.',
            text: 'Name: ' + this.userName + '<br>' + "Email: " + this.userEmail + '<br>' + "Sent By: " + this.userItem[0].email + '<br>' + 'Login to the portal then click resend verification email.' + '<br>' + '<a href="https://portal-5edd4.web.app/login">https://portal-5edd4.web.app/login</a>',
            html: 'Name: ' + this.userName + '<br>' + "Email: " + this.userEmail + '<br>' + "Sent By: " + this.userItem[0].email + '<br>' + 'Login to the portal then click resend verification email.' + '<br>' + '<a href="https://portal-5edd4.web.app/login">https://portal-5edd4.web.app/login</a>'
          }
        }); 
        this.showToast('Verification Email Sent To: ' + this.userEmail + '!', 'success')

      }


  showToast(message:string, color:string) {
    this.myToast = this.toastCtrl.create({
      message: message,
      color: color,
      duration: 4000
    }).then((toastData) => {
      ////console.log(toastData);
      toastData.present();
    });
  }
  HideToast() {   
    this.myToast = this.toastCtrl.dismiss();
  }

  dismiss(data?: any) {
    // using the injected ModalController this page
    // can "dismiss" itself and pass back data
    this.modalCtrl.dismiss(this.uid);
    //this.refreshFromQF();
  
  }

}
