import { Component, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { INTRO_KEY } from 'src/app/guards/intro.guard';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocationStrategy } from '@angular/common';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.page.html',
  styleUrls: ['./logout.page.scss'],
})
export class LogoutPage implements OnInit {

  constructor(public router: Router, public auth:AuthenticationService,private locationStrategy: LocationStrategy,) 
  {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
   }

  ngOnInit() {
    var userItem: any;
    userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
    if (userItem == "undefined" || userItem == null) {

    } else {this.auth.logUserEvent('login',userItem[0].email + ' auto logout: ' + Date.now(),userItem[0].uid);}
    
    localStorage.clear(); //clear app storage
       // Storage.set({key: INTRO_KEY, value: 'true'}); //reset intro key to true
  }

  gotoLogin(){
    this.router.navigateByUrl('/login', { replaceUrl:true });   
  //   setTimeout(() => {          
  //     window.location.reload();
  // }, 500);
  }

}
