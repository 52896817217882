import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { RouterOutlet, Router, ActivationStart, ActivatedRoute } from '@angular/router';
import { IonTabs,IonSegment, IonTabButton, ToastController, IonInput,ModalController, IonRouterOutlet, AlertController } from '@ionic/angular'
import { SettingsService } from 'src/app/services/settings.service';
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserFiles } from '../../interfaces/user-files';
import { PortalFiles } from 'src/app/interfaces/portal-files';
import { Observable, Subscription } from 'rxjs';
import { DOCUMENT, formatDate } from '@angular/common'
import { compileFunction } from 'vm';
import { finalize } from 'rxjs/operators';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { catchError, tap, map } from "rxjs/operators";
import { FileSizePipe } from '../../filesize.pipe';
import { GuidedTour,Orientation } from 'ngx-guided-tour';
import { GuidedTourService } from 'ngx-guided-tour';
import { Download } from 'src/app/shared/download';
import { DownloadService } from 'src/app/shared/download.service';
import { switchMap, take } from 'rxjs/operators';
//import {publicIp, publicIpv4, publicIpv6} from 'public-ip';
export interface IpResponseDTO{
  ip : string
}

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.page.html',
  styleUrls: ['./downloads.page.scss'],
  providers: [
    FileSizePipe
  ]
})
export class DownloadsPage implements OnInit {
  download$: Observable<Download>

  private myToast: any;
  fileId:any;
  fileName:any;
  fileType:any;
  fileActive:any;
  fileUrl:any;
  fileExpires:any;
  fileExpirationDate:any;
  fileUser:any;
  fileExpired:Boolean;
  fileDate:any;
  fileSize:any;
  fileTypeArray:Array<any> = ['image/png','image/jpeg','application/exe','application/pdf','application/zip','x-msdownload'];
  ipInfo:any;
  public dashboardTour: GuidedTour = {
    tourId: 'downloads-tour',
    useOrb: true,
    steps: [
        {
            title: 'Welcome to the Guided Tour Demo',
            selector: '.downloadBtn',
            content: 'Step 1',
            orientation: Orientation.Bottom
        },
        {
            title: 'General page step',
            content: 'We have the concept of general page steps so that a you can introuce a user to a page or non specific instructions',
        },
        {
            title: 'Positioning',
            selector: '.tour-middle-content',
            content: 'Step position can be set so that steps are always in view. This step is on the left.',
            orientation: Orientation.Left
        },
        {
            title: 'Positioning 2',
            selector: '.tour-middle-content',
            content: 'This step is on the right.',
            orientation: Orientation.Right
        },
        {
            title: 'Scroll to content',
            selector: '.tour-scroll',
            content: 'Automatically scroll to elements so they are in view',
            orientation: Orientation.Top
        }
    ]
};

userName: any; 
userItem: any;
userId: any; 
companyId: string;
isSearch:Boolean;
  constructor(private afs: AngularFirestore, private afStorage: AngularFireStorage,
    private guidedTourService: GuidedTourService,
    public modalCtrl: ModalController,  
    private _liveAnnouncer: LiveAnnouncer,
    private http: HttpClient,
    private httpClient: HttpClient, 
    private fileSizePipe: FileSizePipe,
    public alertCtrl: AlertController,
    private route: ActivatedRoute,
    public router: Router,
    public routerOutlet: IonRouterOutlet,
    public toastCtrl: ToastController,
    private downloads: DownloadService,
              @Inject(DOCUMENT) private document: Document,
    //@Optional() private readonly routerOutlet?: IonRouterOutlet,
    private storage: AngularFireStorage,
    public afStore: AngularFirestore,
    public auth: AuthenticationService) {
      setTimeout(() => {
       // this.guidedTourService.startTour(this.dashboardTour);
    }, 1000);
     }

  ngOnInit() {
    this.userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
    this.isSearch = false;
    this.getRemoteIP()
    .subscribe(data => {
      this.ipInfo = data['ip'];  
      //console.log(this.download$);
    });         
  }

  ionViewWillEnter() {
    
    if (this.route.snapshot.paramMap.get('fileId') == null){
      this.fileId = null;
    } else {
      this.fileId = this.route.snapshot.paramMap.get('fileId');
      this.getFileInfo();  
    }    
    
  }


  getFileInfo(){
    this.afs
    .collection('public-downloads', ref => ref
    .where('id', '==', this.fileId)
    )
    .valueChanges()
    //.pipe(take(1))
    .subscribe(downloadData => { 
      if (downloadData.length > 0) {
        this.fileType = downloadData[0]['fileType'];
        this.fileName = downloadData[0]['fileName'];
        this.fileActive = downloadData[0]['isActive'];
        this.fileDate = downloadData[0]['fileDate'];
        this.fileUser = downloadData[0]['fileUser'];
        this.fileUrl = downloadData[0]['fileDownloadUrl'];
        this.fileExpires = downloadData[0]['fileExpires'];
        this.fileSize = this.fileSizePipe.transform(downloadData[0]['fileSize'], 'MB')
        this.fileExpirationDate = downloadData[0]['fileExpirationDate'];
  
        var date1 = formatDate(new Date(),'yyyy-MM-dd','en_US');
        var date2 = formatDate(this.fileExpirationDate,'yyyy-MM-dd','en_US');
     if (this.fileExpires) {   
        if(date1>date2){
          this.fileExpired = true;
         }else{
          this.fileExpired = false;
         }; 
        };
        if (this.fileActive) {
  
        } else {
          this.fileExpired = true;
        }
      } else {
        this.fileExpired = true;
      }
     
    });
    this.isSearch = true;
  }

  getFileInfoSearch(fileId:any){
    this.fileId = fileId;
    this.afs
    .collection('public-downloads', ref => ref
    .where('id', '==', fileId)
    )
    .valueChanges()
    //.pipe(take(1))
    .subscribe(downloadData => { 
      this.fileType = downloadData[0]['fileType'];
      this.fileName = downloadData[0]['fileName'];
      this.fileActive = downloadData[0]['isActive'];
      this.fileDate = downloadData[0]['fileDate'];
      this.fileUser = downloadData[0]['fileUser'];
      this.fileUrl = downloadData[0]['fileUrl'];
      this.fileExpires = downloadData[0]['fileExpires'];
      this.fileSize = this.fileSizePipe.transform(downloadData[0]['fileSize'], 'MB')
      this.fileExpirationDate = downloadData[0]['fileExpirationDate'];
      var date1 = formatDate(new Date(),'yyyy-MM-dd','en_US');
      var date2 = formatDate(this.fileExpirationDate,'yyyy-MM-dd','en_US');
      if (this.fileExpires) {   
        if(date1>date2){
          this.fileExpired = true;
         }else{
          this.fileExpired = false;
         }; 
        };
     
    });
  }

  public GetIp() : Observable<any> {
    return this.http.get<any>("https://api.ipify.org?format=json");  
  }

  updateLastDownload(){
    this.afStore.collection('public-downloads').doc(this.fileId).update({
      lastDownload:this.ipInfo,lastDownloadDate:Date.now()      
    });
  }

  
 logDownload(){
       
  }

 download(name: string, url: string) {
  this.showToast('File: ' + this.fileName + ' downloading...','warning');
    
    if(!this.userItem) {
      this.auth.logPublicDownloadEvent('downloadFIle','not-logged-in' 
      + ' downloaded file -' +  this.fileName+ ' :' +  this.fileId ,
      'not-logged-in','not-logged-in',this.fileName,this.fileId,'ver',this.ipInfo);
    } else {
      this.auth.logPublicDownloadEvent('downloadFIle',this.userItem[0].email 
      + ' downloaded file -' +  this.fileName+ ' :' +  this.fileId ,
      this.userItem[0].uid,this.userItem[0].email,this.fileName,this.fileId,'ver',this.ipInfo);
    }
    this.getDownloadUrl(this.fileId);
    this.updateLastDownload();
  }
  
  getDownloadUrl(fileId:any): void{
    var downloadUrl;
    var fileName;
   this.afs
    .collection('public-downloads', ref => ref
    .where('id', '==', fileId))
    .valueChanges()
    .pipe(take(1))
    .subscribe(file => {  
      downloadUrl = file[0]['fileDownloadUrl']
      fileName = file[0]['fileName']
      this.getFileFromApi(downloadUrl).pipe(take(1))
      .subscribe((response) => {
        this.download$ = this.downloads.download(this.fileUrl, this.fileName); 
          // const downloadLink = document.createElement('a');
          // downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
  
          // const contentDisposition = response.headers.get('content-disposition');
          // //const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          // downloadLink.download = fileName;
          // downloadLink.click();
      });  
    });
    this.download$ = null;
  }
  
  getFileFromApi(downloadUrl: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(downloadUrl, { observe: 'response', responseType: 'blob' as 'json'});
  }

  getRemoteIP() :Observable<any[]> {
    //return this.http.put<string>(`${environment.url}}/confirm`, job);
    const url = `https://api.ipify.org?format=json`;    
   // return this.http.get<WTLColumns[]>(url);
   return this.http.get<any[]>(url);
  }

  showToast(message:string, color:string) {
    this.myToast = this.toastCtrl.create({
      message: message,
      color: color,
      duration: 4000
    }).then((toastData) => {
      //console.log(toastData);
      toastData.present();
    });
  }
  HideToast() {
    this.myToast = this.toastCtrl.dismiss();
  }
}
