import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-user-info-control',
  templateUrl: './user-info-control.page.html',
  styleUrls: ['./user-info-control.page.scss'],
})
export class UserInfoControlPage implements OnInit {
  userName: any; 
  userDisplayName: any; 
  companyId:any;
  userItem: any;
  userData:any;
  lastLoginAt:any;
  userId: any; 
  userEmpId: any;
  userCompanyId:any;
  userPhotoUrl:any;
  constructor(public authService: AuthenticationService,) { }

  ngOnInit() {
    this.userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
    this.userData = JSON.parse(localStorage.getItem('user'));
    if (this.userItem === null) {

    } else if (this.userItem.length <= 0) {           

    } else {
      
      //console.log(this.userItem);
      this.userName = this.userItem[0].email;
      //console.log(this.userName);
      this.userDisplayName = this.userItem[0].displayName;
      this.userCompanyId = this.userItem[0].companyId
      //console.log(this.userCompanyId);
      this.userPhotoUrl = this.userItem[0].photoURL
      this.lastLoginAt = this.userData.lastLoginAt

    };
  }

}
