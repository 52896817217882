import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-accounthold',
  templateUrl: './accounthold.page.html',
  styleUrls: ['./accounthold.page.scss'],
})
export class AccountholdPage implements OnInit {

  constructor(
    public router: Router) { }

  ngOnInit() {
  }

  loginPage(){
    localStorage.clear(); //clear app storage
    this.router.navigateByUrl('/login', { replaceUrl:true });
    setTimeout(() => {          
      window.location.reload();
  }, 500); 
  }
}
