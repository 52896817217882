import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DownloadsPage } from './downloads.page';

const routes: Routes = [
  {
    path: 'downloads',
    component: DownloadsPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DownloadsPageRoutingModule {}
