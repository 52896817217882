import { Component, OnInit,AfterViewInit, ViewChild } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import { SettingsService } from 'src/app/services/settings.service';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { IonTabs,IonSegment, IonTabButton, ToastController, IonInput,ModalController, IonRouterOutlet, AlertController } from '@ionic/angular'
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GuidedTour,Orientation } from 'ngx-guided-tour';
import { GuidedTourService } from 'ngx-guided-tour';
import { switchMap,map, take } from 'rxjs/operators';
import { RouterOutlet, Router, ActivationStart, ActivatedRoute,NavigationEnd } from '@angular/router';
import { UserImgHandlerPage } from '../user-img-handler/user-img-handler.page';
import { LocationStrategy } from '@angular/common';

import { OrderDetailPage } from '../order-detail/order-detail.page';
import {MatSort, Sort} from '@angular/material/sort';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit, AfterViewInit {
  selectedTheme: String;

  displayedOrderColumns = ['orderDate', 'so', 'trackingNo', 'itemNo','customerPO','orderStatus', 'shipmentDate'];
  dataSourceOrders: MatTableDataSource<any>;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('approvalspaginator') approvalspaginator: MatPaginator;
  @ViewChild('sort') sortMain: MatSort;
  @ViewChild('sortapprovals') sortApprovals: MatSort;
  userName: any; 
  userItem: any;
  userData:any;
  userId: any; 
  userEmpId: any;
  userCompanyId:any;
  userEmail:any;
  userPhotoUrl:any;
  userdisplayName:any;
  public isAdmin:boolean = false;
  companyId: string;
  companyName:string;
  companyPhotoURL:string;
  companyPhotoURLShow:Boolean;
  ordersAccess:Boolean;
  deviceAccess:Boolean;
  productAccess:Boolean;
  checklistAccess:Boolean;
  fileAccess:Boolean;
  rmaAccess:Boolean;
  updatesAccess:Boolean;
  messageAccess:Boolean;
  dashboardAccess:Boolean;
  videoAccess:Boolean;
  hasSalesRep:Boolean;
  repDisplayName:any;
  repPhotoUrl:any;
  repEmail:any;
  private myToast: any;
  messagesCount:any;
  messagesCountColor:any;
  messagesCountProgress:any;
  fileCounts:any;
  fileCountProgress:any;
  fileCountColor:any;
  userFileInfo:Array<any> = [];
  pageId:any = '1NvSrvHlfBkG1ywZaYwD';
  roleControls:any[] = ['Main']
  public Main:boolean = false;
  public receiveEmails:boolean = true;
  public receiveEmailsLabel:any = "Toggle to Disable Recieving Email";
  public dashboardTour: GuidedTour = {
    tourId: 'dashboard-tour',
    useOrb: true,
    steps: [
        {
            title: 'Welcome to the My Company Customer Portal',
            selector: '.demo-title',
            content: 'This guide will show you around.',
            orientation: Orientation.Bottom
        },
        {
            title: 'Quick Card Access',
            selector: '.demo-quickcards',
            content: 'These are the quick function cards that take you to the area of the portal that you need to visit and also contain a briefing of information.',
            orientation: Orientation.Center
        },
        {
            title: 'Recently Shipped Orders',
            selector: '.demo-orders',
            content: 'This area contains the 10 most recently shipped orders. You can click the Shipped Orders card above to get to all orders filtered by shipped.',
            orientation: Orientation.Center
        },
        {
            title: 'Main Menu',
            selector: '.demo-mainmenu',
            content: 'This is the main menu. Select it to view different pages of items.',
            orientation: Orientation.BottomLeft
        },
        {
            title: 'User Profile Area',
            selector: '.demo-usermenu',
            content: 'Quick Access to your user account and profile.',
            orientation: Orientation.BottomRight
        }
    ]
};

  constructor(
    public router: Router,
    private locationStrategy: LocationStrategy,
  private guidedTourService: GuidedTourService,
  public authService: AuthenticationService,
  private afs: AngularFirestore, private afStorage: AngularFireStorage,
  private storage: AngularFireStorage,    
  public navCtrl: NavController, 
  private settings: SettingsService,
  public modalCtrl: ModalController,    
  public routerOutlet: IonRouterOutlet,  
  public toastCtrl: ToastController,  
  private _liveAnnouncer: LiveAnnouncer,
  
  ) {
    this.settings.getActiveTheme().subscribe(val => this.selectedTheme = val);
    setTimeout(() => {
      //this.guidedTourService.startTour(this.dashboardTour);
  }, 1000);
  history.pushState(null, null, location.href);
  this.locationStrategy.onPopState(() => {
    history.pushState(null, null, location.href);
  })
  this.router.events.subscribe((e) => {
    if (e instanceof NavigationEnd) {    
      this.isAdmin = this.authService.isAdminUser;
      this.roleControls.forEach(role =>{
        var hasAccess =  this.getItemAccess(role);   
      }) 
    }
  });
  
  }
 
  //   toggleAppTheme() {
  //   if (this.selectedTheme === 'dark-theme') {
  //     this.settings.setActiveTheme('light-theme');
  //   } else {
  //     this.settings.setActiveTheme('dark-theme');
  //   }
  // }

  ngOnInit() {
    this.fileCountColor = "success";
    this.messagesCountColor = "success";
    this.companyPhotoURLShow = false;
    this.userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
    this.userName = this.userItem[0].email;
    //console.log(this.userItem);
    this.companyId = this.userItem[0].companyId;
    this.userCompanyId = this.userItem[0].companyId
    //console.log(this.userCompanyId);
    
    this.userEmail = this.userItem[0].email;
    this.userdisplayName = this.userItem[0].displayName;
    this.userData = JSON.parse(localStorage.getItem('user'));
   
    this.getCompanyInfo();
    //console.log(this.companyId);
    this.ordersAccess = this.getCompanyAccess('orders');
    this.deviceAccess = this.getCompanyAccess('devices');
    this.productAccess = this.getCompanyAccess('products');
    this.checklistAccess = this.getCompanyAccess('checklists');
    this.fileAccess = this.getCompanyAccess('files');
    this.videoAccess = this.getCompanyAccess('videos');
    this.rmaAccess = this.getCompanyAccess('rmas');
    this.messageAccess = this.getCompanyAccess('messages');
      
    this.getCompanyOrders();
    this.getUserInfo();
    this.getFileCountInfo();
    this.getMessages();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      ////console.log(this.tabs);
  }, 500);
  }

  toggleEmail(){
if (this.receiveEmails) {
this.receiveEmailsLabel = "Toggle to Disable Recieving Email";
} else {
  this.receiveEmailsLabel = "Toggle to Enable Recieving Email";
}
this.afs.collection('users').doc(this.userItem[0].uid).update({
  receiveEmails: this.receiveEmails
});   
this.authService.logUserEvent('manageuser-receiveEmails', 'selecteduser:' + this.userItem[0].uid + ' - ' + 'receiveEmails changed to:' + this.receiveEmails + '.',this.userItem[0].uid);
//this.showToast('Email Option Updated!', 'success');
  }



  async getItemAccess(friendlyName:any):Promise<boolean>{
    var access:boolean;    
    var itemAccess = await this.authService.getItemRoleAccess(this.pageId,friendlyName);
    access = itemAccess[1];
    if (friendlyName == 'Main' && access == true) {this.Main = true};   
    return access
    }

  getUserInfo(){
    this.afs
    .collection('users', ref => ref
    .where('uid', '==', this.userItem[0].uid)
    )
    .valueChanges()
    //.pipe(take(1))
    .subscribe(userInfo => { 
      this.userPhotoUrl = userInfo[0]['photoURL'];
      
      this.afs
        .collection('userReps', repRef => repRef.where('repId', '==', 
        userInfo[0]['assignedRep']))
        .valueChanges()
        //.pipe(take(1))
        .subscribe(repInfo => {
          if (repInfo.length > 0) {
            this.hasSalesRep = true;
            
            this.repDisplayName = repInfo[0]['repDisplayName'];
            this.repPhotoUrl = repInfo[0]['repPhotoUrl'];
            this.repEmail = repInfo[0]['repEmail'];
          } else {
            this.hasSalesRep = true;
          }
          //get the companies first
         
          //console.log(this.companyList);
        });
    });
   
  }

  getMessages(){
    this.afs
    .collection('messages', messageRef => messageRef .where('to', '==', this.userItem[0].uid)
    .where('read', '==', false)
    .orderBy('date','desc'))
    .valueChanges()
    //.pipe(take(1))
    .subscribe(messageInfo => {
      var totalMessageCount = messageInfo.length;
      if (totalMessageCount == 0) {
        this.messagesCountColor = "success";
        this.messagesCount  = "No Unread Messages!"
        this.messagesCountProgress = 1;
      } else {
        this.messagesCountColor = "danger";
        this.messagesCount  = "(" + totalMessageCount + " Message(s) Need Attention!)"
        this.messagesCountProgress = 1;

      }
     
      //this.messagesCount = messageInfo.length;
      
    });
  
  }

  getFileCountInfo(){
    this.fileCounts = "";
    this.fileCountProgress = "";
    this.afs
    .collection('userfiles', ref => ref
    .where('uid', '==', this.userItem[0].uid)
    .where('fileUser', '!=', this.userItem[0].email)
    )
    .valueChanges()
    .subscribe(userFileInfo => {  
      var totalFileCount = userFileInfo.length;
      if (totalFileCount == 0) {
        this.fileCountColor = "success";
        this.fileCounts = "My Files (up to date)."
        this.fileCountProgress = 1;
      } else {

     
      var fileInfoArray = [];
      this.userFileInfo = userFileInfo;
      this.userFileInfo.forEach(file=> {fileInfoArray.push(file.id)})
    
      this.afs
      .collection('userfiles', ref => ref
      .where('id', 'in', fileInfoArray)                  
      .where('wasDownloaded', '!=', true)     
      )
      .valueChanges()
      .subscribe(userFileDownloads => {  
        if (userFileDownloads.length<=0) {
          this.fileCountColor = "success";
          this.fileCounts = "My Files (up to date)."
          this.fileCountProgress = 1;
        } else {
          var needTodownloadFileCount = userFileDownloads.length;
          var countPercent = needTodownloadFileCount/totalFileCount;
          this.fileCountColor = "danger";
          this.fileCounts = "My Files (" + needTodownloadFileCount + ' File(s) need attention!)'
          this.fileCountProgress = needTodownloadFileCount/totalFileCount;
        }        
              
      });

    }
    });
  }

  getCompanyInfo(){
    this.afs
    .collection('companies', ref => ref
    .where('id', '==', this.companyId)
    )
    .valueChanges()
    //.pipe(take(1))
    .subscribe(CompanyAccess => {  
      this.companyPhotoURL = CompanyAccess[0]['logoURL'];
      this.companyName = CompanyAccess[0]['companyName'];
      if (CompanyAccess[0]['logoURL'] !='') {
        this.companyPhotoURLShow = true;
      }
      //this.hasSalesRep = CompanyAccess[0]['salesRep'];
    });
    

  }

  getCompanyAccess(element:any): Boolean{
    var hasAccess:Boolean = false;

    this.afs
    .collection('companyAccess', ref => ref
    .where('companyId', '==', this.companyId)
    )
    .valueChanges()
    //.pipe(take(1))
    .subscribe(CompanyAccess => {   
     
      var updatesPage = CompanyAccess[0]['updates'];
      var checklistsPage = CompanyAccess[0]['checklists'];
      var ordersPage = CompanyAccess[0]['orders'];
      var devicesPage = CompanyAccess[0]['devices'];
      var dashboardPage = CompanyAccess[0]['dashboard'];
      var productsPage = CompanyAccess[0]['products'];
      var messagesPage = CompanyAccess[0]['messages'];
      var filesPage = CompanyAccess[0]['files'];
      var rmasPage = CompanyAccess[0]['rmas'];
      var videosPage = CompanyAccess[0]['videos'];

      if (element.toLowerCase() == 'updates' && updatesPage == true) {
        this.updatesAccess = true;
      }
      if (element.toLowerCase() == 'checklists' && checklistsPage == true) {
        hasAccess = true;
        this.checklistAccess = true;
      }
      if (element.toLowerCase() == 'orders' && ordersPage == true) {
        hasAccess = true;
        this.ordersAccess = true;
      }
      if (element.toLowerCase() == 'devices' && devicesPage == true) {
        hasAccess = true;
        this.deviceAccess = true;
      }
      if (element.toLowerCase() == 'dashboard' && dashboardPage == true) {
        hasAccess = true;
        this.dashboardAccess = true;
      }
      if (element.toLowerCase() == 'products' && productsPage == true) {
        hasAccess = true;
        this.productAccess = true;
      }
      if (element.toLowerCase() == 'messages' && messagesPage == true) {
        hasAccess = true;
        this.messageAccess = true;
      }
      if (element.toLowerCase() == 'files' && filesPage == true) {
        hasAccess = true;
        this.fileAccess = true;
      }
      if (element.toLowerCase() == 'rmas' && rmasPage == true) {
        hasAccess = true;
        this.rmaAccess = true;
      }
      if (element.toLowerCase() == 'videos' && videosPage == true) {
        hasAccess = true;
        this.videoAccess = true;
      }

  });  
  return hasAccess;
  }

  
  async showOrderDetail(selectedOrder:any) {
    this.authService.logUserEvent("showSelectedOrderAdmin",selectedOrder,this.userItem[0].uid);
    //console.log(selectedOrder);
    const modal = await this.modalCtrl.create({
      component: OrderDetailPage,
      id: 'adminorderdetail',
      canDismiss : true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: { selectedOrder: selectedOrder},
      cssClass: 'orderdetail-modal',
    });
    await modal.present();
    
    const { data } = await modal.onWillDismiss();
    if (data) {
      //this.refreshData(this.defaultWC);
      if ( data[0]['isSearch'] === 1 ){  //this means the fromjobsearch was passed
        //this.adminSearch();
      } else {
    
              }  
          }  
      }
      private getCompanyOrders(): void {
    
    this.afs
    .collection('companies', ref => ref
    .where('id', '==', this.companyId))
    .valueChanges()
    //.pipe(take(1))
    .subscribe(companyinfo => {
      let compInfo:any;
      compInfo = companyinfo;
      //console.log(compInfo[0].companySysproId);
      this.afs.collection('orders', ref => ref.where('companySysproId', '==', 
      compInfo[0].companySysproId).where('orderStatus', '==', 'SHIPPED')
      .orderBy('orderStatusInt','asc')).valueChanges()
      //.pipe(take(1))
      .subscribe(companyOrders => {
          this.dataSourceOrders = new MatTableDataSource<any>(companyOrders);
          this.dataSourceOrders.paginator = this.paginator;
          this.dataSourceOrders.sort = this.sortMain;
        //console.log(this.dataSourceOrders);
          //console.log(companyOrders);
          // this.dataSourceFiles.sort = this.applyFilter('');
        });
      // this.dataSourceCompanyFiles = new MatTableDataSource<any>(companyfiles);
      // this.dataSourceCompanyFiles.paginator = this.paginator;
      // // this.dataSourceFiles.sort = this.applyFilter('');
    });

   

  }

  
  applyFilterOrders(filterValue: any) {
    filterValue = filterValue.value.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSourceOrders.filter = filterValue;
  }

  /** Announce the change in sort state for assistive technology. */
announceSortChange(sortState: Sort) {
  if (sortState.direction) {
    this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
  } else {
    this._liveAnnouncer.announce('Sorting cleared');
  }
  }

  updateDisplayName(){
    this.afs.collection('users').doc(this.userItem[0].uid).update({
      displayName: this.userdisplayName
    });   
    this.authService.logUserEvent('manageuser-displayName', 'selecteduser:' + this.userItem[0].uid + ' - ' + 'displayName changed to:' + this.userdisplayName + '.',this.userItem[0].uid);
    this.showToast('Display Name Updated!', 'success')
  }

   async showImgHandler() {
    //console.log(selectedImage);
    const modal = await this.modalCtrl.create({
      component: UserImgHandlerPage,
      canDismiss : true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: { selectedUser: this.userName, selectedUserID:this.userItem[0].uid},
      cssClass: 'checklistview-modal',
    });
    await modal.present();
    
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.getUserInfo();
      if ( data[0]['isSearch'] === 1 ){  
      } else {
    
              }  
          } else {
            this.getUserInfo();
          }  
      }

      showToast(message:string, color:string) {
        this.myToast = this.toastCtrl.create({
          message: message,
          color: color,
          duration: 4000
        }).then((toastData) => {
          ////console.log(toastData);
          toastData.present();
        });
      }
      HideToast() {   
        this.myToast = this.toastCtrl.dismiss();
      }
      
}
