import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticationService } from "../../services/authentication.service";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { IonTabs,IonSegment, IonTabButton, ToastController, IonInput,ModalController, IonRouterOutlet, AlertController } from '@ionic/angular'
import { SettingsService } from 'src/app/services/settings.service';
import { NavController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserFiles } from '../../interfaces/user-files';
import { Observable, Subscription } from 'rxjs';
import { compileFunction } from 'vm';
import { finalize } from 'rxjs/operators';
import { ManageUsersDetailPage } from '../manage-users-detail/manage-users-detail.page';
import { Chart, ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import {default as Annotation} from 'chartjs-plugin-annotation';
import { switchMap,map, take } from 'rxjs/operators';
import { CompanyImgHandlerPage } from '../company-img-handler/company-img-handler.page';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { getFunctions,Functions, httpsCallable } from "@angular/fire/functions";

export class FileUpload {
  key: string;
  name: string;
  url: string;
  file: File;
  constructor(file: File) {
    this.file = file;
  }
}

@Component({
  selector: 'app-manage-companies-detail',
  templateUrl: './manage-companies-detail.page.html',
  styleUrls: ['./manage-companies-detail.page.scss'],
})
export class ManageCompaniesDetailPage implements OnInit {
public companyId: any;
public uid: any;
userName: any; 
userItem: any;
userId: any; 
userEmpId: any;
userCompanyId:any;
userEmail:any;
userPhotoUrl:any;
userdisplayName:any;
userCompanyName:any;
userActive:any;
companyLogoURL:any;
companyName:any;
companySysproId:any;
companyWebsite:any;
companySalesRep:any;
companyServiceRep:any;
companyIsActive:any;
dateCreated:any;
appPermissions:any


dataSourceUsersCombo: MatTableDataSource<any>;
dataSourceUsers: MatTableDataSource<any>;
dataSourceCompanies: MatTableDataSource<any>;
dataSourceUserLog: MatTableDataSource<any>;
dataSourceFiles: MatTableDataSource<UserFiles>;
dataSourceCompanyTags: MatTableDataSource<any>;

@ViewChild('companySelect', {static: false}) inputEl: IonInput;
@ViewChild('paginator') paginator: MatPaginator;
@ViewChild('filepaginator') filepaginator: MatPaginator;
@ViewChild('userspaginator') userspaginator: MatPaginator;
@ViewChild('paginatorTags') paginatorTags: MatPaginator;
@ViewChild('sort') sortMain: MatSort;
@ViewChild('matSortFiles') matSortFiles: MatSort;
@ViewChild('matSortUsers') matSortUsers: MatSort;
@ViewChild('matSortTags') matSortTags: MatSort;
companyList:any;

roleName:any;
roleList:any;
displayedFileColumns = ['fileDate', 'fileName', 'fileUser', ];
displayedUserLogColumns = ['datetime', 'logtype', 'message'];
displayedUserColumns = ['displayName', 'isActive', 'email', 'emailVerified', 'companyId', 'isAdmin', 'dateCreated'];
displayedCompanyTagColumns = ['id', 'tagName'];

percentage = 0;
percentageBar:number = 0.40;
currentFileUpload?: FileUpload;
selectedFiles?: FileList;
sendEmailWithFile:boolean = true;
dataSourceCompanyFiles: MatTableDataSource<UserFiles>;
companyInfo:any;

dataSourceTags: MatTableDataSource<any>;
allTags:Array<any> = [];
companyTags:Array<any> = [];
availableTags:Array<any> = [];
public selectedTags:Array<any> = [];
private tagListSearch:Array<any> = [];
tagList:any;
tagListAll:any;
tagListCompany:any;
fileTagsCompanyAccess:any;

pageloadCountCompany:number = 0;
checklistAccess:boolean = false;
dashboardAccess:boolean = false;
devicesAccess:boolean = false;
fileAccess:boolean = false;
companyFileAccess:boolean = false;
messageAccess:boolean = false;
orderAccess:boolean = false;
productAccess:boolean = false;
fieldServiceRequestAccess:boolean = false;
rmaAccess:boolean = false;
updatesAccess:boolean = false;
videoAccess:boolean = false;
timeKeeperAccess:boolean = false;
timeKeeperWTLAccess:boolean = false;
companyAccessId:any;
companyAccessMain:any;
companyDevices:any;
private myToast: any;
deviceId:any;
deviceName:any;
deviceStatus:any;
deviceType:any;
deviceRemoteIP:any;
deviceSerialNo:any;


constructor(private afs: AngularFirestore, private afStorage: AngularFireStorage,
  private httpClient: HttpClient,
  public modalCtrl: ModalController,  
  private _liveAnnouncer: LiveAnnouncer,
  public alertCtrl: AlertController,
  private routerOutlet: IonRouterOutlet,
  public toastCtrl: ToastController,
  //@Optional() private readonly routerOutlet?: IonRouterOutlet,
  private storage: AngularFireStorage,
  public afStore: AngularFirestore,
  public auth: AuthenticationService,  
  private functions: Functions,    
  
  ) 
  {Chart.register(Annotation) }


  ngOnInit() {
    this.userItem = JSON.parse(localStorage.getItem('userItem'));// localStorage.getItem('username');
    this.getCompanyInfo();
this.getCompanyUsers();
this.getCompanyFiles();
this.getCompanyAccess();
this.getCompanyTagAccess();
this. getCompanyDevices();

  }


  private getCompanyInfo(): void {
       
    this.afs
    .collection('companies', ref => ref
    .where('id', '==', this.companyId))
    .valueChanges()
    //.pipe(take(1))
    .subscribe(companyInfo => {
      this.companyInfo = companyInfo;      
      this.companyLogoURL = this.companyInfo[0].logoURL;
      this.companyName = this.companyInfo[0].companyName;
      this.companySysproId = this.companyInfo[0].companySysproId;
      this.companyWebsite  = this.companyInfo[0].companyWebsite;
      this.companyIsActive = this.companyInfo[0].isActive;
      this.companySalesRep = this.companyInfo[0].salesRep;
      this.companyServiceRep = this.companyInfo[0].serviceRep;

      // this.dataSourceFiles.sort = this.applyFilter('');
    });
   // console.log(this.afs.collection.name);
  }

  
  private getCompanyDevices(): void {
    
    this.afs
    .collection('companyDevices', ref => ref
    .where('companyId', '==', this.companyId).orderBy('addedDate','asc'))
    .valueChanges()
    //.pipe(take(1))
    .subscribe(companyDevices => {
      this.companyDevices = companyDevices
    });

   

  }

  private getCompanyFiles(): void {
       
    this.afs
    .collection('companyfiles', ref => ref
    .where('companyid', '==', this.companyId))
    .valueChanges()
    //.pipe(take(1))
    .subscribe(companyfiles => {
      this.dataSourceCompanyFiles = new MatTableDataSource<any>(companyfiles);
      this.dataSourceCompanyFiles.paginator = this.paginator;
      // this.dataSourceFiles.sort = this.applyFilter('');
    });
   // console.log(this.afs.collection.name);
  }

  getCompanyUsers() {
    this.afs
    .collection('users', usercompRef => usercompRef.orderBy('displayName').where('companyId', '==', this.companyId))
    .valueChanges()
    //.pipe(take(1))
    .subscribe(usercompinfo => {    
      this.dataSourceUsers = new MatTableDataSource<any>(usercompinfo);
      this.dataSourceUsers.paginator = this.userspaginator; 
      this.dataSourceUsers.sort = this.matSortUsers;
    });
  }

  async alertResetCompanyPerms(){
    const alert = await this.alertCtrl.create({
      header: 'RESET Company Permissions For :' + this.companyName + ' ?',
      message: 'Are you sure you want to Reset permissions to default?',
      buttons: [
        'No',
        {
          text: 'Yes',
          handler: (data: any) => {
            this.resetCompanyPermsDefault();
          }
        }
      ],
    });
    // now present the alert on top of all other content
    await alert.present();
  }

  resetCompanyPermsDefault(){
   
    this.afStore.collection("companyAccess").doc(this.companyAccessId).delete().then(() => {

    });

        this.afStore.collection("companyAccess").add({
          checklists:false, companyId:this.companyId, companySysproId:this.companySysproId,dashboard:true,devices:false,
          //fileTags:''
          files:true,messages:true,orders:false,products:true,rmas:false,updates:true,videos:false
          //fileDate:Date.now(), fileName: fileUpload.file.name, fileType: fileUpload.file.type, fileUrl: fileUpload.url, uid:this.userItem[0].uid, fileUser: this.userItem[0].email, companyid: this.companyId
          //logtype: logtype,message:message,datetime:Date.now(),uid:uid
      })
      .then(docRef => {
        this.afStore.collection('companyAccess').doc(docRef.id).update({
          id: docRef.id      
        });
          //console.log("Document written with ID: ", docRef.id);
          this.getCompanyAccess();
          this.getCompanyTagAccess();
      })
      .catch(error => console.error("Error adding document: ", error))     
  }

  async alertdeleteFile(id:any, name:any){
    const alert = await this.alertCtrl.create({
      header: 'Delete File :' + name + ' ?',
      message: 'Are you sure you want to delete the file?',
      buttons: [
        'No',
        {
          text: 'Yes',
          handler: (data: any) => {
            this.deleteFile(id,name);
          }
        }
      ],
    });
    // now present the alert on top of all other content
    await alert.present();
  }

  deleteFile(id:any,name:any){
    this.afStore.collection("companyfiles").doc(id).delete().then(() => {
      var basePath = '/portalCompanyFiles/' + this.companyId;
      const filePath = `${basePath}/${name}`;
      const storageRef = this.storage.ref(filePath);
      storageRef.delete();
      this.auth.logUserEvent('managecompanydetail-filedelete', 'file ' + name + ' deleted by ' + this.userItem[0].email + '.',this.userItem[0].uid);
    });
    this.showToast(name + ' deleted!', 'success')
//dataSourceFiles
  }

  getDownloadUrlCompany(fileId:any): void{
    var downloadUrl;
    var fileName;
   this.afs
    .collection('companyfiles', ref => ref
    .where('id', '==', fileId))
    .valueChanges()
    .pipe(take(1))
    .subscribe(file => {  
     // downloadUrl = file[0]['fileDownloadUrl']
      fileName = file[0]['fileName']
      const getSigFile = httpsCallable(this.functions, 'generateSignedUrl');

      let createdUserId = '';
      let response = false;
      //expire in 20minutes
      const expirationDate = new Date(
        new Date().getTime() + .33 * 60 * 60 * 1000
      );

      getSigFile({bucketName:'gs://portal-5edd4.appspot.com/portalCompanyFiles/' + this.companyId + '/', fileName: fileName, expirationDate:expirationDate })
.then(({data: url}) => {
console.log(url);
//window.open(String(url),"_blank");
//window.open(String(url), 'Download'); 
//this.downloadProduct(String(url));

const e = document.createElement('a');
e.href = url.toString();
e.target = "_blank";
e.download = url.toString().substr(url.toString().lastIndexOf('/') + 1);
document.body.appendChild(e);
e.click();
this.afStore.collection('companyfiles').doc(fileId).update({
        lastDownloaded: Date.now(),wasDownloaded:true,lastDownloadUser:this.userItem[0].email    
      });
      this.afStore.collection("companyfiledownloads").add({
        fileId:fileId, downloadDate:Date.now(), fileName: fileName, uid:this.userItem[0].uid, fileUser: this.userItem[0].email, companyid: this.companyId
          //logtype: logtype,message:message,datetime:Date.now(),uid:uid
      })
      .then(docRef => {
        this.afStore.collection('companyfiledownloads').doc(docRef.id).update({
          id: docRef.id      
        });         
      })
      .catch(error => console.error("Error adding document: ", error))    
  });   
      // this.getFileFromApi(downloadUrl).pipe(take(1))
      // .subscribe((response) => {
      //     const downloadLink = document.createElement('a');
      //     downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
  
      //     const contentDisposition = response.headers.get('content-disposition');
      //     //const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      //     downloadLink.download = fileName;
      //     downloadLink.click();

      //     this.afStore.collection('companyfiles').doc(fileId).update({
      //       lastDownloaded: Date.now(),wasDownloaded:true,lastDownloadUser:this.userItem[0].email    
      //     });
      //     this.afStore.collection("companyfiledownloads").add({
      //       fileId:fileId, downloadDate:Date.now(), fileName: fileName, uid:this.userItem[0].uid, fileUser: this.userItem[0].email, companyid: this.companyId
      //         //logtype: logtype,message:message,datetime:Date.now(),uid:uid
      //     })
      //     .then(docRef => {
      //       this.afStore.collection('companyfiledownloads').doc(docRef.id).update({
      //         id: docRef.id      
      //       });         
      //     })
      //     .catch(error => console.error("Error adding document: ", error))    
      // });  
    });
    
  }

  getFileFromApi(downloadUrl: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(downloadUrl, { observe: 'response', responseType: 'blob' as 'json'});
  }
  

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  upload(): void {
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      this.selectedFiles = undefined;

      if (file) {
        this.currentFileUpload = new FileUpload(file);
        this.pushFileToStorage(this.currentFileUpload).subscribe(
          percentage => {
            this.percentage = Math.round(percentage ? percentage : 0);
            this.percentageBar = (this.percentage * .01);
          },
          error => {
           // console.log(error);
          }
        );
      }
    }
  }

pushFileToStorage(fileUpload: FileUpload): Observable<number> {
  var basePath = '/portalCompanyFiles/' + this.companyId;
  const filePath = `${basePath}/${fileUpload.file.name}`;
  const storageRef = this.storage.ref(filePath);
  const uploadTask = this.storage.upload(filePath, fileUpload.file);
  uploadTask.snapshotChanges().pipe(
    finalize(() => {
      storageRef.getDownloadURL().subscribe(downloadURL => {
        fileUpload.url = downloadURL;
        fileUpload.name = fileUpload.file.name;
        this.saveFileData(fileUpload,filePath);
        this.auth.logUserEvent('managecompanydetail-fileupload', fileUpload.file.name + ' uploaded by ' + this.userItem[0].email + '.',this.companyId); //figure out company vs user log
        this.percentageBar = .0;
        this.percentage = 0;
      });
    })
  ).subscribe();
  return uploadTask.percentageChanges();
}

saveFileData(fileUpload:FileUpload, filepath:any){
  this.afStore.collection("companyfiles").add({
    fileDate:Date.now(), fileName: fileUpload.file.name, fileType: fileUpload.file.type, fileDownloadUrl:fileUpload.url, fileUrl:  filepath, uid:this.userItem[0].uid, fileUser: this.userItem[0].email, companyid: this.companyId,
    wasDownloaded:false,lastDownloadUser:''
    //logtype: logtype,message:message,datetime:Date.now(),uid:uid
})
.then(docRef => {
  this.afStore.collection('companyfiles').doc(docRef.id).update({
    id: docRef.id      
  });
    //console.log("Document written with ID: ", docRef.id);
   // console.log("You can now also access this. as expected: ", this.foo)
})
.catch(error => console.error(this.showToast("Error adding document: " + error, 'danger')))
this.showToast(fileUpload.file.name + '  uploaded!', 'success')
}

private getCompanyAccess(): void {
  this.afs
    .collection('companyAccess', ref => ref
    .where('companyId', '==', this.companyId)   
    )
    .valueChanges()
    //.pipe(take(1))
    .subscribe(compAccess => {  
      this.companyAccessMain = compAccess;
    this.checklistAccess = this.companyAccessMain[0]['checklists'];
    this.dashboardAccess = this.companyAccessMain[0]['dashboard'];
    this.devicesAccess = this.companyAccessMain[0]['devices'];
    this.fileAccess = this.companyAccessMain[0]['files'];
    this.companyFileAccess = this.companyAccessMain[0]['companyFiles'];
    this.messageAccess = this.companyAccessMain[0]['messages'];
    this.orderAccess = this.companyAccessMain[0]['orders'];
    this.productAccess = this.companyAccessMain[0]['products'];
    this.rmaAccess = this.companyAccessMain[0]['rmas'];
    this.updatesAccess = this.companyAccessMain[0]['updates'];
    this.videoAccess = this.companyAccessMain[0]['videos'];

    this.timeKeeperAccess = this.companyAccessMain[0]['timeKeeperAccess'];

    this.timeKeeperWTLAccess = this.companyAccessMain[0]['timeKeeperWTLAccess'];
    this.companyAccessId = this.companyAccessMain[0]['id'];
    this.fieldServiceRequestAccess = this.companyAccessMain[0]['fieldServiceRequestAccess'];
    
  });
}

private getCompanyTagAccess(): void {  

  this.afs
  .collection('portalFileTags', ref => ref
  )
  .valueChanges()
  //.pipe(take(1))
  .subscribe(fileTags => {   
    this.tagListAll = fileTags;    
    this.availableTags = [];
      this.tagListAll.forEach(tag => this.availableTags.push({'id': tag.id, 'tagName': tag.tagName}) ) ;{        
           
      };
  //  this.tagListAll = fileTags;    
  //   this.tagListAll.forEach(tag => this.allTags.push({'id': tag.id, 'tagName': tag.tagName}) ) ;{        
  //   };
    //this.dataSourceTags = new MatTableDataSource<any>(fileTags); 
 
    this.afs
    .collection('companyAccess', ref => ref
    .where('companyId', '==', this.companyId)   
    )
    .valueChanges()
    //.pipe(take(1))
    .subscribe(fileTagsCompanyAccess => {   
      this.tagListCompany = fileTagsCompanyAccess;

      this.selectedTags = [];
      if (this.tagListCompany[0]['fileTags']){
      this.tagListCompany[0]['fileTags'].forEach(tag => this.selectedTags.push({'id': tag.id, 'tagName': tag.tagName}) ) ;{        
      };
    }

     this.dataSourceCompanyTags = new MatTableDataSource<any>(this.availableTags);
     this.dataSourceCompanyTags.paginator = this.paginatorTags; 
     this.dataSourceCompanyTags.sort = this.matSortUsers;
 
    });
 
  });
 
 }

 selectedTagsClose(event:any){
 // console.log(event);
 // console.log(this.selectedTags);

}

updateCompanyPermissions(){

  this.afStore.collection('companyAccess').doc(this.companyAccessId).update({
    checklists:this.checklistAccess, dashboard:this.dashboardAccess, devices:this.devicesAccess, files:this.fileAccess, companyFiles:this.companyFileAccess, messages:this.messageAccess,orders:this.orderAccess,
    products:this.productAccess,rmas:this.rmaAccess,updates:this.updatesAccess,videos:this.videoAccess,fileTags:this.selectedTags,fieldServiceRequestAccess:this.fieldServiceRequestAccess,
    timeKeeperAccess: this.timeKeeperAccess,timeKeeperWTLAccess: this.timeKeeperWTLAccess 
    // fileDate:Date.now(), fileName: this.productFileName , uid:this.userItem[0].uid, fileUser: this.userItem[0].email,
    // fileTags:this.selectedTags, eulaRequired:this.productFileEULAReq, isActive:this.productFileActive, version:this.productFileVersion, fileNotes: this.productFileNotes
  });   
  this.auth.logUserEvent('managecompanydetail-updatecompanypermissions',this.userItem[0].email + ' updated permissions for -' + this.companyId + ' : '
  + 'checklistAccess: ' + this.checklistAccess + ', dashboardAccess: ' + this.dashboardAccess + ', devicesAccess: ' + this.devicesAccess + ', fileAccess: ' + this.fileAccess + 
  ', messageAccess: ' + this.messageAccess + ', orderAccess: ' + this.orderAccess + ', productAccess: ' + this.productAccess + ', rmaAccess: ' + this.rmaAccess + ', updatesAccess: ' + this.updatesAccess +
  ', videoAccess: ' + this.videoAccess + ', timeKeeperAccess: ' + this.timeKeeperAccess + ', timeKeeperWTLAccess: ' + this.timeKeeperWTLAccess + ', fieldServiceRequestAccess: ' + this.fieldServiceRequestAccess +  ', fileTags: ' + this.selectedTags +   ',  Modified By: ' + this.userItem[0].email  + '.',this.userItem[0].uid);
  this.showToast('Permissions Updated!','success');


}

// updateCompanyTags() {

//   this.afs
//    .collection('companyAccess', ref => ref
//    .where('companyId', '==', this.companyId)   
//    )
//    .valueChanges()
//    .subscribe(fileTagsCompanyAccess => {   
//     this.fileTagsCompanyAccess = fileTagsCompanyAccess;

//     if (this.fileTagsCompanyAccess.length <=0 ) {

//       this.afStore.collection("companyAccess").add({
//         icon: icon,isActive:isActive,reqCpAdmin:reqCpAdmin,reqlogin:reqlogin,title:title,url:url
//     }).then(docRef => {
//       this.afStore.collection('companyAccess').doc(docRef.id).update({
//         id: docRef.id      
//       });

//     } else {
//       this.fileTagsCompanyAccess.id
//     }

//    });

//   this.afStore.collection('companyAccess').doc(this.fileId).update({
//     fileDate:Date.now(), fileName: this.productFileName , uid:this.userItem[0].uid, fileUser: this.userItem[0].email,
//     fileTags:this.selectedTags, eulaRequired:this.productFileEULAReq, isActive:this.productFileActive, version:this.productFileVersion, fileNotes: this.productFileNotes
//   });   
//   this.auth.logUserEvent('manageportalfile-updatecurrentrev',this.userItem[0].email + ' updated file -' + this.productFileName + ' :' + this.fileId + ' file updated to new rev : ' + this.productFileVersion  + '.',this.userItem[0].uid);
//   this.showToast('File Updated','Success');
// }

// updateCurrentVersion(){
//   this.afStore.collection('portalFiles').doc(this.fileId).update({
//     fileDate:Date.now(), fileName: this.productFileName , uid:this.userItem[0].uid, fileUser: this.userItem[0].email,
//     fileTags:this.selectedTags, eulaRequired:this.productFileEULAReq, isActive:this.productFileActive, version:this.productFileVersion, fileNotes: this.productFileNotes
//   });   
//   this.auth.logUserEvent('manageportalfile-updatecurrentrev',this.userItem[0].email + ' updated file -' + this.productFileName + ' :' + this.fileId + ' file updated to new rev : ' + this.productFileVersion  + '.',this.userItem[0].uid);
//   this.showToast('File Updated','Success');
// }

  applyFilterFiles(filterValue: any) {
    filterValue = filterValue.value.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSourceFiles.filter = filterValue;
  }

  applyFilterUsers(filterValue: any) {
    filterValue = filterValue.value.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSourceUsers.filter = filterValue;
  }

  applyFilterCompanies(filterValue: any) {
    filterValue = filterValue.value.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSourceCompanies.filter = filterValue;
  }

      /** Announce the change in sort state for assistive technology. */
      announceSortChange(sortState: Sort) {
        if (sortState.direction) {
          this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        } else {
          this._liveAnnouncer.announce('Sorting cleared');
        }
      }
  
  async showUserDetail(uid:any) {
    //this.auth.logUserEvent("manageuserdetail",uid,this.userItem[0].uid);
    //console.log(uid);
    const modal = await this.modalCtrl.create({
      component: ManageUsersDetailPage,
      id: 'manageuserdetail',
      canDismiss : true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: { uid: uid},
      cssClass: 'user-detail-modal',
    });
    await modal.present();
    
    const { data } = await modal.onWillDismiss();
    if (data) {
      //this.refreshData(this.defaultWC);
      if ( data[0]['isSearch'] === 1 ){  //this means the fromjobsearch was passed
        //this.adminSearch();
      } else {
    
              }  
          }  
      }


      addDevice(){
        this.afStore.collection("companyDevices").add({
          addedBy:this.userItem[0].email, addedDate:Date.now(),companyId:this.companyId,deviceName:this.deviceName,deviceStatus:'on',deviceType:this.deviceType,
          lat:'40.36782', long:'-79-74849', remoteIP: this.deviceRemoteIP, serialNo: this.deviceSerialNo        
      })
      .then(docRef => {
        this.afStore.collection('companyDevices').doc(docRef.id).update({
          deviceId: docRef.id      
        });
          //console.log("Document written with ID: ", docRef.id);
         // console.log("You can now also access this. as expected: ", this.foo)
      })
      .catch(error => console.error(this.showToast("Error adding device: " + error, 'danger')))
      this.showToast(this.deviceName + ' added!', 'success')
      }
   
      async  alertRemoveDevice(deviceId:any,deviceName:any,deviceIp:any){
        const alert = await this.alertCtrl.create({
          header: 'Delete Device :' + deviceName + ' ?',
          message: 'Are you sure you want to delete the device?',
          buttons: [
            'No',
            {
              text: 'Yes',
              handler: (data: any) => {
                this.removeDevice(deviceId,deviceName,deviceIp);
              }
            }
          ],
        });
        // now present the alert on top of all other content
        await alert.present();
      }

      removeDevice(deviceId:any,deviceName:any,deviceIp:any){
        this.afStore.collection("companyDevices").doc(deviceId).delete().then(() => {
          this.auth.logUserEvent('managecompanydetail-devicedelete', 'device ' + deviceName + ' IP:' + deviceIp + ' ID: ' + deviceId + ' deleted by ' + this.userItem[0].email + '.',this.userItem[0].uid);
        });
      }


      public lineChartData: ChartConfiguration['data'] = {
        datasets: [
          {
            data: [ 65, 59, 80, 81, 56, 55, 40 ],
            label: 'A',
            backgroundColor: 'rgba(148,159,177,0.2)',
            borderColor: 'rgba(148,159,177,1)',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)',
            fill: 'origin',
          },
          {
            data: [ 28, 48, 40, 19, 86, 27, 90 ],
            label: 'B',
            backgroundColor: 'rgba(77,83,96,0.2)',
            borderColor: 'rgba(77,83,96,1)',
            pointBackgroundColor: 'rgba(77,83,96,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(77,83,96,1)',
            fill: 'origin',
          },
          {
            data: [ 180, 480, 770, 90, 1000, 270, 400 ],
            label: 'C',
            yAxisID: 'y-axis-1',
            backgroundColor: 'rgba(255,0,0,0.3)',
            borderColor: 'red',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)',
            fill: 'origin',
          }
        ],
        labels: [ 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun' ]
      };

      public lineChartOptions: ChartConfiguration['options'] = {
        elements: {
          line: {
            tension: 0.5
          }
        },
        scales: {
          // We use this empty structure as a placeholder for dynamic theming.
          x: {},
          'y-axis-0':
            {
              position: 'left',
            },
          'y-axis-1': {
            position: 'right',
            grid: {
              color: 'rgba(255,0,0,0.3)',
            },
            ticks: {
              color: 'red'
            }
          }
        },
    
        plugins: {
          legend: { display: true },
          annotation: {
            annotations: [
              {
                type: 'line',
                scaleID: 'x',
                value: '--w',
                borderColor: 'orange',
                borderWidth: 2,
                label: {
                  display: true,
                  position: 'center',
                  color: 'orange',
                  content: 'CurrData',
                  font: {
                    weight: 'bold'
                  }
                }
              },
            ],
          }
        }
      };
    
      public lineChartType: ChartType = 'line';
      
      public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
       // console.log(event, active);
      }
    
      public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
       // console.log(event, active);
      }
    
      // public hideOne(): void {
      //   const isHidden = this.chart?.isDatasetHidden(1);
      //   this.chart?.hideDataset(1, !isHidden);
      // }

      async showImgHandler() {
        //console.log(selectedImage);
        const modal = await this.modalCtrl.create({
          component: CompanyImgHandlerPage,
          canDismiss : true,
          presentingElement: this.routerOutlet.nativeEl,
          componentProps: { selectedCompany: this.companyName, selectedCompanyID:this.companyId},
          cssClass: 'checklistview-modal',
        });
        await modal.present();
        
        const { data } = await modal.onWillDismiss();
        if (data) {
          this.getCompanyInfo();
          if ( data[0]['isSearch'] === 1 ){  
          } else {
        
                  }  
              } else {
                this.getCompanyInfo();
              }  
          }

      updateSysproId(){
        this.afs.collection('companies').doc(this.companyId).update({
          companySysproId: this.companySysproId          
        });   
        this.auth.logUserEvent('managecompany-logopic', 'selectedcompany:' + this.companyId + ' - ' + ' SYSPRO ID changed to:' + this.companySysproId + ' by ' + this.userItem[0].email + '.',this.userItem[0].uid );
        this.showToast(this.companyName + ' SYSPRO ID updated!', 'success')       
      }

      updateWebsite(){          
        this.afs.collection('companies').doc(this.companyId).update({
          companyWebsite: this.companyWebsite          
        });   
        this.auth.logUserEvent('managecompany-logopic', 'selectedcompany:' + this.companyId + ' - ' + ' Website changed to:' + this.companyWebsite + ' by ' + this.userItem[0].email + '.',this.userItem[0].uid );
        this.showToast(this.companyName + ' Website updated!', 'success')        
      }

      updateCompanyStatus(e){   
        this.pageloadCountCompany = this.pageloadCountCompany + 1;
        if (this.pageloadCountCompany > 1) {
          const ischecked: boolean =  e.currentTarget.checked; 
        this.afStore.collection('companies').doc(this.companyId).update({
          isActive: ischecked        
        });   
        this.showToast('Company Active Status Updated!', 'success');
        this.auth.logUserEvent('managecompany-status', this.companyId + ' active status changed to: ' + this.companyIsActive + '.',this.userItem[0].uid);
      }
      }

      removeLogo(){
        this.afs.collection('companies').doc(this.companyId).update({
          logoURL: ''          
        });   
        this.auth.logUserEvent('managecompany-logopic', 'selectedcompany:' + this.companyId + ' - ' + 'logo removed uploaded by ' + this.userItem[0].email + '.',this.userItem[0].uid );
        this.showToast(this.companyName + ' logo pic removed!', 'success')
        setTimeout(() => {
          //this.dismiss();
       }, 1000);
      }

      showToast(message:string, color:string) {
        this.myToast = this.toastCtrl.create({
          message: message,
          color: color,
          duration: 4000
        }).then((toastData) => {
          //console.log(toastData);
          toastData.present();
        });
      }
      HideToast() {   
        this.myToast = this.toastCtrl.dismiss();
      }

      dismiss(data?: any) {
        // using the injected ModalController this page
        // can "dismiss" itself and pass back data
        this.modalCtrl.dismiss(this.uid);
        //this.refreshFromQF();
      
      }

}
